import React, { useEffect, useState } from "react";
import { Container, makeStyles, Portal } from "@material-ui/core";
import MainLayout from "../components/MainLayout";
import PersonalizedSection from "../components/PersonalizedSection";
import HomePageProductSection from "../components/HomePageProductSection";
import LoggedInCarousel from "../components/LoggedInCarousel";
import LoggedInFeatures from "../components/LoggedInFeatures";
import { useStores } from "../models";
import { Redirect } from "react-router-dom";
import InformingDialog from "../components/InformingDialog";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: {
		background: "linear-gradient(190deg, transparent 50%, #9A7F9599 100%)",
		minHeight: "60vh"
	},
	innerRoot: {
		borderRadius: 24,
		width: "80%",
		background: "linear-gradient(180deg, #CEDFE1 0%, transparent 50%)"
	}
});

function LoggedInHomePage () {

	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	const isUserOnboarded = rootStore.auth.isOnboarded;


	const api = rootStore.environment.api;
	const branchId = rootStore.auth.branchId;
	const classes = useStyles();

	const [books, setBooks] = useState({ data: [], count: 0 });
	const [articles, setArticles] = useState({ data: [], count: 0 });
	const [slider, setSlider] = useState(null);
	const [homePageFeatures, setHomePageFeatures] = useState(null);
	const [branchProducts, setBranchProducts] = useState([]);
	const [opportunities, setOpportunities] = useState([]);

	const [isLoading, setIsLoading] = useState(false);


	useEffect(async () => {
		setIsLoading(true);

		const response = await api.homePage.index(branchId);
		const responseData = response.data;
		const articleData = { data: responseData?.products_article ?? [], count: responseData?.article_count ?? 0 };
		const bookData = { data: responseData?.products_book ?? [], count: responseData?.book_count ?? 0 };

		setOpportunities(responseData?.opportunities ?? []);
		setBranchProducts(responseData?.branch_products ?? []);
		setHomePageFeatures(responseData.homePageObjects ?? []);
		setSlider(responseData?.sliders ?? []);

		setArticles(articleData);
		setBooks(bookData);
		setIsLoading(false);
	}, []);
	if (!isUserOnboarded) return <Redirect to="/onboarding"/>;
	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Container maxWidth={false} disableGutters className={classes.root}>
				{!!slider && <LoggedInCarousel slider={slider}/>}
				{!!homePageFeatures && <LoggedInFeatures homePageFeatures={homePageFeatures}/>}
				{<Container maxWidth="xl" disableGutters className={classes.innerRoot}>
					<PersonalizedSection
						opportunitiesTitle={contentStore.getContentValueLang("pages.home.opportunities.title")}
						branchProductsTitle={contentStore.getContentValueLang("pages.home.branchProducts.title")}
						opportunities={opportunities} branchProducts={branchProducts}/>
					{books.count > 0 &&
					<HomePageProductSection
						seeAllText={contentStore.getContentValueLang("pages.home.showAllButton.text")}
						title={contentStore.getContentValueLang("pages.home.books.title")} items={books.data} count={books.count}
						query={{ filter: { type: "book" } }}/>}
					{articles.count > 0 &&
					<HomePageProductSection
						seeAllText={contentStore.getContentValueLang("pages.home.showAllButton.text")}
						title={contentStore.getContentValueLang("pages.home.articles.title")} items={articles.data}
						count={articles.count}
						query={{ filter: { type: "book" } }}/>}
				</Container>}
				{/*<LoggedInVideos/>*/}
			</Container>
		</MainLayout>
	);
}

export default observer(LoggedInHomePage);
