import {localStorageVariables} from "../config/env";


const jwtTokenHandler = (function(){
	let _service;
	function _getService() {
		if(!_service) {
			_service = this;
		}
		return _service
	}
	function _setToken(newToken) {
		localStorage.setItem(localStorageVariables.jwt, newToken);
	}
	function _getToken() {
		return localStorage.getItem(localStorageVariables.jwt);
	}
	function _clearToken() {
		localStorage.removeItem(localStorageVariables.jwt);
	}
	return {
		getService : _getService,
		setToken : _setToken,
		getToken: _getToken,
		clearToken : _clearToken
	}
})();
export default jwtTokenHandler;
