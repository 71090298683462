import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecretLink from "./SecretLink";
import { asset } from "../config/initialize";
import ResponsiveCarousel from "./ResponsiveCarousel";

const useStyles = makeStyles({
	dots: {
		margin: "24px 0"
	},
	image: {
		width: "100%",
		maxHeight: 700
	}
});

function LoggedInCarousel (props) {
	const classes = useStyles();
	const { slider } = props;
	return (
		<ResponsiveCarousel responsivePattern={responsive} infinite>
			{slider.map((item) => (
				<SecretLink to={item.link} showPointer key={item.id}>
					<img src={asset(item.photo)} alt="" key={item.id} className={classes.image}/>
				</SecretLink>
			))}
		</ResponsiveCarousel>
	);
}

export default LoggedInCarousel;
const responsive = {
	mobile: {
		breakpoint: { max: 4000, min: 0 },
		items: 1
	}
};
