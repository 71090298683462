import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Container, Typography} from "@material-ui/core";
import Layout from '../components/MainLayout'
import FranchiseBlurredSection from "../components/FranchiseBlurredSection";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FranchiseFeature from "../components/FranchiseFeature";
import FranchiseRegisterNow from "../components/FranchiseRegisterNow";

const useStyles = makeStyles({
    root:{
        background:"#EEEEEE"
    },
    sectionOne:{
        textAlign:"center",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        padding:"100px 0",
        "& > *":{
            font:"normal normal bold 36px/48px Gilroy"
        }
    },
    sectionOneText:{
        maxWidth:700,
        padding:"0px 10%"
    }
})

export default function Franchise(){
    const classes = useStyles()
    return(
        <Layout>
            <Container maxWidth="xl" disableGutters className={classes.root}>
                <FranchiseBlurredSection/>

                <Box className={classes.sectionOne}>
                    <Typography className={classes.sectionOneText}>Dijitıp yazılarınızı okurlarla buluşturuyor. Bu ayrıcalığı keşfetmek için kaydırın.</Typography>
                    <ArrowDownwardIcon/>
                </Box>

                <FranchiseFeature/>

                <FranchiseRegisterNow/>

            </Container>
        </Layout>
    )
}