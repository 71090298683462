import React, {useState} from 'react'
import {Checkbox, Paper, Container, FormControlLabel} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {getQuery, setQuery} from "../helpers/routing";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: "10px",
        boxShadow: "3px 3px 6px #00000029",
        padding: "20px",
    },
    filterHeader: {
        marginLeft: "10px",
        marginBottom: "10px",
        font: "normal normal normal 20px/24px Calibri"
    },
    filter: {
        display: "flex",
        alignItems: "center",
        font: "normal normal 300 16px/19px Calibri",
    },
    filterSection: {
        paddingBottom: "20px"
    },
    filterName: {
        font: "normal normal 300 16px/19px Calibri"
    },
    amount: {
        font: "normal normal 300 13px/15px Calibri",
        marginLeft: "auto"
    },
    checkbox: {
        padding: "0",
        margin: "10px 0",
        marginRight: "10px",
    },
    filterButton: {
        textTransform: "none",
        background: "linear-gradient(114deg, #1E2436 0%, #554686 100%)",
        color: "white",
        marginTop: "40px",
        font: "normal normal normal 17px/26px Gilroy",
        height: "50px",
        borderRadius: 8
    },
    formControl: {
        width: "100%",
        "& .MuiFormControlLabel-label": {
            width: "100%"
        }
    }
}))

function LibraryFilters(props) {
    const {categories, applyFilter, toggleFilter, isCategoryFiltered} = props
    const classes = useStyles()
	const rootStore = useStores()
	const contentStore = rootStore.contentStore
    return (
        <Container disableGutters>

            <Paper className={classes.paper}>
                <Box className={classes.filterSection}>
                    <Typography className={classes.filterHeader}>{contentStore.getContentValueLang("pages.library.select")}</Typography>
                    {categories.map((category) =>
                        <Box key={category.id}>
                            <FormControlLabel className={classes.formControl}
                                              control={<Checkbox checked={isCategoryFiltered(category.id)}
                                                                 onClick={() => toggleFilter(category.id)}
                                                                 className={classes.checkbox}/>} label={
                                <Box className={classes.filter}>
                                    <Typography className={classes.filterName}>{category.name}</Typography>
                                    <Typography className={classes.amount}>{category.amount}</Typography>
                                </Box>
                            }/>
                        </Box>)}
                </Box>
            </Paper>
            <Button fullWidth className={classes.filterButton} onClick={applyFilter}>{contentStore.getContentValueLang("pages.searchPage.useFilter")}</Button>
        </Container>

    )
}

export default observer(LibraryFilters)


