import React, { useEffect, useState } from "react";
import Layout from "../components/UserInfoLayout";
import Typography from "@material-ui/core/Typography";
import { Box, List, ListItem, makeStyles, Portal } from "@material-ui/core";
import CouponCard from "../components/CouponCard";
import MainLayout from "../components/MainLayout";
import AddCouponCard from "../components/AddCouponCard";
import { useStores } from "../models";
import InformingDialog from "../components/InformingDialog";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles({
	pageHeading: {
		color: "#2C2C2C",
		margin: "30px auto",
		font: " normal normal bold 28px/34px Montserrat"
	},
	cardBox: {
		marginBottom: "100px"
	},
	listHeading: {
		margin: "20px 0",
		font: "normal normal bold 24px/29px Calibri,Roboto"
	},
	list: {
		gap: "50px"
	}
});


const Coupon = () => {
	const classes = useStyles();

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;

	const [consumedCoupons, setConsumedCoupons] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	async function getCoupons () {
		setIsLoading(true);
		const response = await api.coupons.userCoupons(rootStore.auth.userId);
		const coupons = response.data;
		setConsumedCoupons(coupons);
		setIsLoading(false);
	}

	useEffect(async () => {
		await getCoupons();
	}, []);

	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Layout active="/coupon">
				<Box className={classes.cardBox}>
					<Typography className={classes.pageHeading} align="center">
						{contentStore.getContentValueLang("pages.coupons.couponInformations")}
					</Typography>
					<AddCouponCard refreshCoupons={getCoupons}/>
				</Box>

				<Typography variant={"h5"} className={classes.listHeading}>{contentStore.getContentValueLang("pages.coupons.oldCoupons")}</Typography>
				<List className={classes.list}>
					{consumedCoupons.map((coupon, index) => <ListItem disableGutters
																														key={index}><CouponCard {...coupon}/></ListItem>)}
				</List>
			</Layout>
		</MainLayout>
	);
};

export default observer(Coupon);
