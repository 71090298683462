import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { FormLabel, makeStyles } from "@material-ui/core";
import TextField from "./UserInfoTextField";
import * as Yup from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";
import { useYupValidationResolver } from "../helpers/hooks";

const validationSchema = Yup.object({
	currentPassword: Yup.string("")
		.required("Lütfen geçerli şifrenizi giriniz"),
	newPassword: Yup.string()
		.required("Lütfen bir şifre giriniz")
		.matches(/^(?=.*?[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Lütfen en az bir küçük harf, bir büyük harf, bir rakam ve en az 8 karakter içeren bir şifre giriniz."),
	newPasswordConfirm: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], "Şifreler eşleşmiyor")
		.required("Lütfen şifrenizi doğrulayın.")

});

const useStyles = makeStyles({
	root: {
		border: "1px solid #B8B8B8",
		borderRadius: "7px",
		boxShadow: "3px 3px 6px #00000029",
		width: "50%",
		padding: "5%",
		"@media (max-width:959px)": {
			width: "100%"
		}

	},
	gridHeading: {
		marginBottom: "30px",
		font: "normal normal normal 30px/24px Calibri,Roboto"
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		gap: "8px"

	},
	inputLabel: {
		color: "#1E2436",
		font: "normal normal normal 20px/24px Calibri,Roboto"
	},
	button: {
		height: "60px",
		textTransform: "none",
		border: "1px solid #1E2436",
		margin: "8px 0",
		font: "normal normal normal 24px/24px Calibri,Roboto",
		background: "#22263B",
		color: "white",
		"&:hover": {
			background: "#22263B"
		}

	}


});


const PasswordCard = observer(() => {
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);
	const [errors, setErrors] = useState({});

	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;

	const resolver = useYupValidationResolver(validationSchema);
	const { register, handleSubmit } = useForm({ resolver });

	async function onSubmit (data) {
		const userPassword = rootStore.auth.password;
		if (userPassword === data.currentPassword) {
			if (data.newPassword === data.newPasswordConfirm) {
				const response = await rootStore.auth.updatePassword(data.newPassword);
			}
		}
	}

	function onError (errors) {
		setErrors(errors);
	}

	return (

		<Card className={classes.root}>
			<form onSubmit={handleSubmit(onSubmit, onError)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography className={classes.gridHeading}
												variant="h5">{contentStore.getContentValueLang("pages.userInformations.changePasswordTitle")}</Typography>
					</Grid>


					<Grid item className={classes.gridItem} xs={12}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.oldPassword")}</FormLabel>
						<TextField variant="outlined" type={showCurrentPassword ? "text" : "password"}
											 name="currentPassword"
											 error={!!errors?.currentPassword} helperText={errors?.currentPassword?.message ?? ""}
											 {...register("currentPassword")}
											 InputProps={{
												 endAdornment: (
													 <InputAdornment position="end">
														 <IconButton onClick={() => setShowCurrentPassword(pre => !pre)}>
															 {showCurrentPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
														 </IconButton>
													 </InputAdornment>
												 )
											 }}/>
					</Grid>

					<Grid item className={classes.gridItem} xs={12}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.newPassword")}</FormLabel>
						<TextField variant="outlined" type={showNewPassword ? "text" : "password"} name="newPassword"
											 error={!!errors?.newPassword} helperText={errors?.newPassword?.message ?? ""}
											 {...register("newPassword")}
											 InputProps={{
												 endAdornment: (
													 <InputAdornment position="end">
														 <IconButton onClick={() => setShowNewPassword(pre => !pre)}>
															 {showNewPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}

														 </IconButton>
													 </InputAdornment>
												 )
											 }}/>
					</Grid>

					<Grid item className={classes.gridItem} xs={12}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.confirmNewPassword")}</FormLabel>
						<TextField variant="outlined" type={showNewPasswordConfirm ? "text" : "password"}
											 name="newPasswordConfirm"
											 error={!!errors?.newPasswordConfirm} helperText={errors?.newPasswordConfirm?.message ?? ""}
											 {...register("newPasswordConfirm")}
											 InputProps={{
												 endAdornment: (
													 <InputAdornment position="end">
														 <IconButton onClick={() => setShowNewPasswordConfirm(pre => !pre)}>
															 {showNewPasswordConfirm ? <VisibilityIcon/> : <VisibilityOffIcon/>}

														 </IconButton>
													 </InputAdornment>
												 )
											 }}/>
					</Grid>


					<Grid item xs={12}>
						<Button className={classes.button} size="large" type="submit" variant="outlined"
										fullWidth>{contentStore.getContentValueLang("pages.userInformations.button")}</Button>
					</Grid>

				</Grid>
			</form>
		</Card>
	);
});

export default PasswordCard;
