import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";

/**
 * Cart model
 */
export const CartModel = types.model({
    itemIds: types.optional(types.array(types.string), [])
})
    .extend(withEnvironment)
    .actions(self => ({
        sync: flow(function* () {
            const apiResponse = yield self.environment.api.cart.getCart()
            const items = apiResponse.data?.items ?? []
            const ids = items.map((cartItem: any) => cartItem.product_id)
            self.itemIds = ids
        })
    }))
    .actions(self => ({
        addItem: flow(function* (productId) {
            try {
                const apiResponse = yield self.environment.api.cart.modifyItem(productId)
                yield self.sync()
            } catch (error) {
                console.log(error)
            }
        }),
        deleteItem: flow(function* (productId) {
            try {
                const apiResponse = yield self.environment.api.cart.modifyItem(productId, 0)
                yield self.sync()
            } catch (error) {
                console.log(error)
            }
        }),
        hasItem: function (productId: string) {
            const isItemInItems = self.itemIds.includes(productId)
            return isItemInItems
        },
        afterCreate() {
            self.sync()
        }
    }))


type CartType = Instance<typeof CartModel>

export interface Cart extends CartType {
}

type CartSnapshotType = SnapshotOut<typeof CartModel>

export interface CartSnapshot extends CartSnapshotType {
}

export const createCartDefaultModel = () => types.optional(CartModel, {})
