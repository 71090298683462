import { Button, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStores } from "../../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	text1: {
		font: "normal normal 500 60px/80px Gilroy",
		textAlign: "center"
	},
	text2: {
		font: "normal normal 600 38px/51px Gilroy",
		maxWidth: 800,
		textAlign: "center",
		margin: 20
	},
	button: {
		background: "linear-gradient(114deg, rgba(30, 36, 54, 1) 0%, rgba(85, 70, 134, 1) 100%)",
		color: "white",
		font: "normal normal bold 24px/24px Calibri",
		padding: "12px 5%",
		borderRadius: 8,
		textTransform: "none"
	}
});


const Onboarding1 = ({ indexHandler }) => {
	const classes = useStyles();
	const rootStore = useStores()
	const contentStore = rootStore.contentStore

	return (
		<>
			<Typography className={classes.text1}>{contentStore.getContentValueLang("pages.onboarding.step1.title")}</Typography>
			<Typography className={classes.text2}>{contentStore.getContentValueLang("pages.onboarding.step1.description")}</Typography>
			<Button className={classes.button} onClick={indexHandler}>{contentStore.getContentValueLang("pages.onboarding.step1.button")}</Button>
		</>
	);
};

export default observer(Onboarding1);
