import { Button, Card, CardContent, Dialog, FormLabel, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "./UserInfoTextField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import PhoneInput from "./PhoneInput";
import { useYupValidationResolver } from "../helpers/hooks";
import { useStores } from "../models";
import { preparePhoneNumber } from "../helpers/phoneNumberHelper";
import { observer } from "mobx-react-lite";

const validationSchema = Yup.object({
	addressName: Yup.string()
		.required("Lütfen adres başlığı giriniz"),
	fullName: Yup.string()
		.required("Lütfen adınızı giriniz"),
	phoneNumber: Yup.string()
		.matches(/\d{2}\s\d{3}\s\d{3}\s\d{2}\s\d{2}/g, "Lütfen geçerli bir telefon numarası giriniz")
		.required("Lütfen geçerli bir telefon numarası giriniz"),
	address: Yup.string()
		.required("Lütfen adresinizi giriniz")
});


const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: "7px"
	},
	cardHeader: {
		background: "#DEDEDE",
		borderBottom: "1px solid #BEBEBE",
		padding: "20px 40px",
		display: "flex",
		justifyContent: "space-between"

	},
	cardHeaderHeading: {
		font: "normal normal normal 24px/24px Roboto"
	},
	closeIcon: {
		margin: "-12px"
	},
	cardBody: {
		padding: "5%"
	},
	card: {
		overflow: "visible",
		border: "1px solid #B8B8B8",
		borderRadius: "7px",
		boxShadow: "3px 3px 6px #00000029"

	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		gap: "8px"
	},
	inputLabel: {
		color: "#1E2436",
		font: "normal normal normal 20px/24px Roboto"
	},
	phoneField: {
		paddingLeft: "62px"
	},
	select: {
		height: "54px",
		width: "54px",
		zIndex: "10",
		marginLeft: "1px",
		marginTop: "33px",
		position: "absolute",
		background: "linear-gradient(117deg, #1E2436 0%, #554686 100%)",
		color: "white",
		borderRadius: "3px",
		font: "normal normal normal 16px/32px Roboto",
		textAlign: "center",
		"& fieldset": {
			border: "none"
		}
	},
	selectIcon: {
		fill: "white",
		transform: "scale(0.7)"
	},
	button: {
		height: "60px",
		textTransform: "none",
		border: "1px solid #1E2436",
		margin: "8px 0",
		font: "normal normal normal 24px/24px Roboto",
		background: "#22263B",
		color: "white",
		"&:hover": {
			background: "#22263B"
		}

	}

}));

const AddAddressModal = (props) => {
	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;

	const { open, handleClose, userId, editingAddress = false, isEditingAddress = false } = props;
	const classes = useStyles();
	const handleChange = (e) => {
		e.target.value = e.target.value.replace(/\D*/g, "");
	};

	useEffect(() => {
		setValue("addressName", isEditingAddress ? editingAddress.title : "");
		setValue("fullName", isEditingAddress ? editingAddress.fullName : "");
		setValue("phoneNumber", preparePhoneNumber(isEditingAddress ? editingAddress.phoneNumber : ""));
		setValue("address", isEditingAddress ? editingAddress.address : "");
	}, [isEditingAddress]);

	const resolver = useYupValidationResolver(validationSchema);
	const { register, handleSubmit, control, setValue } = useForm({ resolver });
	const [errors, setErrors] = useState({});

	async function onSubmit (data) {
		const address = {
			title: data.addressName,
			fullName: data.fullName,
			phoneNumber: data.phoneNumber,
			address: data.address,
			type: "bill"
		};
		const response = isEditingAddress ? await api.userAddress.update(userId, editingAddress.id, address) : await api.userAddress.store(userId, address);
		handleClose();
	}

	function onError (errors) {
		setErrors(errors);
	}

	return (

		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{ className: classes.root }}
			scroll={"body"}
		>
			<Card className={classes.card}>
				<CardContent className={classes.cardHeader}>
					<Typography variant="h5"
											className={classes.cardHeaderHeading}>{contentStore.getContentValueLang(isEditingAddress ? "pages.addresses.editAddress" : "pages.addresses.addNewAddress")}</Typography>
					<IconButton className={classes.closeIcon} onClick={handleClose}><CloseIcon/></IconButton>
				</CardContent>
				<CardContent className={classes.cardBody}>
					<form onSubmit={handleSubmit(onSubmit, onError)}>
						<Grid container spacing={3}>
							<Grid item xs={12} className={classes.gridItem}>
								<FormLabel className={classes.inputLabel}>{contentStore.getContentValueLang("pages.addresses.addressTitle")}</FormLabel>
								<TextField variant="outlined" name="addressName" error={!!errors?.addressName}
													 helperText={errors?.addressName?.message ?? ""} {...register("addressName")}/>
							</Grid>

							<Grid item className={classes.gridItem} xs={12}>
								<FormLabel className={classes.inputLabel}>{contentStore.getContentValueLang("pages.addresses.name")}</FormLabel>
								<TextField variant="outlined" name="fullName" error={!!errors?.fullName}
													 helperText={errors?.fullName?.message ?? ""} {...register("fullName")}/>
							</Grid>


							<Grid item className={classes.gridItem} xs={12}>
								<FormLabel className={classes.inputLabel}>{contentStore.getContentValueLang("pages.addresses.phoneNumber")}</FormLabel>
								<PhoneInput control={control} error={!!errors?.phoneNumber}
														helperText={errors?.phoneNumber?.message ?? ""}/>
							</Grid>


							<Grid item className={classes.gridItem} xs={12}>
								<FormLabel className={classes.inputLabel}>{contentStore.getContentValueLang("pages.addresses.address")}</FormLabel>
								<TextField
									variant="outlined"
									rows={5} name="address"
									multiline
									error={!!errors?.address} helperText={errors?.address?.message ?? ""}
									{...register("address")}/>
							</Grid>
							<Grid item xs={12}>
								<Button className={classes.button} type="submit" size="large" variant="outlined"
												fullWidth>{contentStore.getContentValueLang("pages.addresses.button")}</Button>
							</Grid>

						</Grid>
					</form>
				</CardContent>
			</Card>
		</Dialog>

	);
};

export default observer(AddAddressModal);
