import React from "react";
import MainLayout from "../components/MainLayout";
import Layout from "../components/UserInfoLayout";
import UserCard from "../components/UserCard";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import SecretLink from "../components/SecretLink";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";


const useStyles = makeStyles({
	root: {
		paddingTop: "30px"
	},
	pageTitle: {
		font: "normal normal bold 24px/34px Montserrat, Roboto"
	},
	contentBox: {
		borderBottom: "1px solid #AFAFAF",
		margin: "30px 0 10px 0",
		display: "flex"
	},
	active: {
		position: "absolute",
		bottom: 0,
		left: 0,
		height: "2px",
		background: "#1E2436",
		width: "100%",
		marginBottom: "-1.5px",
		borderRadius: "3px"
	},
	sectionLink: {
		width: "fit-content",
		position: "relative",
		padding: "10px 30px",
		color: "#1E2436",
		font: "normal normal 300 18px/24px Calibri",
		textDecoration: "none",
		"&.active": {
			font: "normal normal normal 24px/24px Calibri"
		}
	},
	page: {
		margin: "100px auto",
		display: "flex",
		justifyContent: "center"
	}
});

const UserInformation = () => {

	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;

	return (
		<MainLayout>
			<Layout subActive="/my-account" active="/my-account">
				<Container className={classes.root}>
					<Typography variant="h5"
											className={classes.pageTitle}>{contentStore.getContentValueLang("pages.userInformations.userInformations")}</Typography>
					<Box className={classes.contentBox}>
						<SecretLink showPointer to="/my-account" className={clsx(classes.sectionLink, "active")}>
							{contentStore.getContentValueLang("pages.userInformations.membershipInformations")}
							<Box className={classes.active}/>
						</SecretLink>

						<SecretLink showPointer to="/my-account/password" className={classes.sectionLink}>
							{contentStore.getContentValueLang("pages.userInformations.changePassword")}

						</SecretLink>
					</Box>


					<Box className={classes.page}>
						<UserCard/>
					</Box>

				</Container>
			</Layout>
		</MainLayout>
	);
};

export default observer(UserInformation);
