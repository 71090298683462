import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";
import ResponsiveCarousel from "./ResponsiveCarousel";
import SecretLink from "./SecretLink";
import { asset } from "../config/initialize";

const useStyles = makeStyles(
	{
		root: {
			margin: "3%",
			width: "94%",
			borderTop: "1px solid #CCCCCC",
			textAlign: "center"
		},
		heading: {
			font: "normal normal bold 20px/22px Calibri",
			margin: 16
		},
		box: {
			padding: 10
		},
		img: {
			width: 150,
			height: 200,
			margin: "0 auto"
		},
		label: {
			font: "normal normal normal 22px/27px Calibri",
			margin: 10
		},
		price: {
			font: "normal normal normal 19px/23px Calibri"
		}
	}
);

function YourSpecial () {
	const classes = useStyles();

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const branchId = rootStore.auth.branchId;
	const api = rootStore.environment.api;

	const [opportunities, setOpportunities] = useState([]);
	useEffect(async () => {
		const response = await api.branches.branchProducts(branchId);
		setOpportunities(response.data);
	}, []);
	return (
		<Container maxWidth="xl" disableGutters className={classes.root}>
			<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.productPage.suggestions")}</Typography>

			<ResponsiveCarousel responsivePattern={responsive} infinite
			>
				{opportunities.map((opportunity) =>
					<Box key={opportunity.id}>
						<SecretLink to={"/product-detail/" + opportunity.id} showPointer key={opportunity.id}>
							<Box key={opportunity.id} className={classes.box}>
								<img src={asset(opportunity.image ?? "")} className={classes.img}/>
								<Typography className={classes.label}>{opportunity.name}</Typography>
								<Typography className={classes.price}>{opportunity.price}</Typography>
							</Box>
						</SecretLink>
					</Box>
				)}

			</ResponsiveCarousel>

		</Container>
	);
}

export default observer(YourSpecial);
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};
