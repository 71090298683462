import {ApiBase} from "../api"
import {makeQuery} from "../../../helpers/routing";


export class ProductsApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index(query: any) {
        const params = makeQuery(query, {arrayFormat: "comma"})
        let response = await this.api.apisauce.get(
            "/products?" + params,
        )

        return response
    }

    async show(productId: string) {
        let response = await this.api.apisauce.get(
            "/products/" + productId,
        )
        return response

    }

    async sendRating(userId: string, productId: string, rating: number) {
        const response = await this.api.apisauce.post(
            "/users/" + userId + "/products/" + productId,
            {
                rating
            },
        )
        return response
    }

    async count(type: string) {
        const response = await this.api.apisauce.get(
            "/products/type/count?type=" + type
        )
        return response
    }
}
