import Home from "../pages/HomePage";
import Library from "../pages/Library";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Search from "../pages/Search";
import Payment from "../pages/Payment";
import Coupon from "../pages/Coupon";
import Basket from "../pages/Basket";
import AuthorRegister from "../pages/AuthorRegister";
import Address from "../pages/Address";
import UserPassword from "../pages/UserPassword";
import UserInformation from "../pages/UserInformation";
import ProductDetail from "../pages/ProductDetail";
import Orders from "../pages/Orders";
import OrderDetail from "../pages/OrderDetail";
import Franchise from "../pages/Franchise";
import PaymentSuccess from "../pages/PaymentSuccess";
import CouponDetail from "../pages/CouponDetail";
import Onboarding from "../pages/Onboarding/Onboarding";

export const routes = [
	{
		path: "/login",
		name: "login",
		component: Login,
		protected: false,
		exact: true
	},
	{
		path: "/",
		name: "home",
		component: Home,
		protected: false,
		exact: true
	},
	{
		path: "/library",
		name: "library",
		title: {},
		component: Library,
		protected: true,
		exact: true
	},
	{
		path: "/signup",
		name: "signup",
		title: {},
		component: Register,
		protected: false,
		exact: true
	},
	{
		path: "/search",
		name: "search",
		title: {},
		component: Search,
		protected: true,
		exact: true
	},
	{
		path: "/payment",
		name: "payment",
		title: {},
		component: Payment,
		protected: true,
		exact: true
	},
	{
		path: "/coupon",
		name: "coupon",
		title: {},
		component: Coupon,
		protected: true,
		exact: true
	},
	{
		path: "/basket",
		name: "basket",
		title: {},
		component: Basket,
		protected: true,
		exact: true
	},
	{
		path: "/author-register",
		name: "author-register",
		title: {},
		component: AuthorRegister,
		protected: true,
		exact: true
	},
	{
		path: "/my-account/password",
		name: "user-password",
		title: {},
		component: UserPassword,
		protected: true,
		exact: true
	},
	{
		path: "/my-account",
		name: "my-account",
		title: {},
		component: UserInformation,
		protected: true,
		exact: true
	},
	{
		path: "/product-detail/:id",
		name: "product-detail",
		title: {},
		component: ProductDetail,
		protected: true,
		exact: true
	},
	{
		path: "/orders",
		name: "orders",
		title: {},
		component: Orders,
		protected: true,
		exact: true
	},
	{
		path: "/order-detail/:id",
		name: "order-detail",
		title: {},
		component: OrderDetail,
		protected: true,
		exact: true
	},
	{
		path: "/franchise",
		name: "franchise",
		title: {},
		component: Franchise,
		protected: true,
		exact: true
	},
	{
		path: "/address",
		name: "address",
		title: {},
		component: Address,
		protected: true,
		exact: true
	},
	{
		path: "/payment-success",
		name: "payment-success",
		title: {},
		component: PaymentSuccess,
		protected: true,
		exact: true
	},
	{
		path: "/coupon-detail/:id",
		name: "coupon-detail",
		title: {},
		component: CouponDetail,
		protected: true,
		exact: true
	},
	{
		path: "/onboarding",
		name: "onboarding",
		title: {},
		component: Onboarding,
		protected: true,
		exact: true
	}

];

export const redirects = [
	{
		name: "404",
		from: "*",
		to: "/"
	}
];

export default routes;
