import Container from "@material-ui/core/Container";
import React from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Filters from "./Filters";
import UpperFooter from "./UpperFooter";
import { Box } from "@material-ui/core";


const Layout = ({ children }) => {

	return (
		<Container maxWidth={false} style={{ background: "#EEEEEE" }} disableGutters>
			<Header/>
			<Filters/>
			<Box minHeight="60vh">
				{children}
			</Box>
			<UpperFooter/>
			<Footer/>
		</Container>
	);
};

export default Layout;
