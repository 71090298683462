import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import Layout from "../components/UserInfoLayout";
import AddAddressModal from "../components/AddAddressModal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddressCard from "../components/AddressCard";
import { makeStyles, Portal } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Container from "@material-ui/core/Container";
import { useStores } from "../models";
import InformingDialog from "../components/InformingDialog";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles({
	root: {
		margin: 0,
		width: "90%",
		"@media (max-width:1280px)": {
			width: "100%"
		}
	},
	box: {
		display: "flex",
		justifyContent: "space-between",
		margin: "40px 0",
		"@media (max-width:599px)": {
			flexDirection: "column",
			gap: "30px"
		}
	},
	addButton: {
		border: "#1E243673 solid 2px",
		textTransform: "capitalize",
		color: "#1E243673",
		padding: "5px 40px"
	},
	addressList: {
		gap: "50px"
	},
	header: {
		font: "normal normal bold 28px/34px Montserrat"
	}

});


const Address = observer(() => {
	const [addressModalOpen, setAddressModalOpen] = useState(false);

	const rootStore = useStores();
	const api = rootStore.environment.api;
	const contentStore = rootStore.contentStore

	const [addresses, setAddresses] = useState([]);
	const [editingAddress, setEditingAddress] = useState({});
	const [isEditingAddress, setIsEditingAddress] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	function handleAddressModalClose () {
		setAddressModalOpen(false);
		getAddresses();
	}

	function handleNewAddress () {
		setIsEditingAddress(false);
		setAddressModalOpen(true);
	}

	function handleEditAddress (address) {
		setIsEditingAddress(true);
		setEditingAddress(address);
		setAddressModalOpen(true);

	}

	async function getAddresses () {
		const response = await api.userAddress.index(rootStore.auth.userId);
		setAddresses(response.data);
	}

	useEffect(async () => {
		setIsLoading(true);
		await getAddresses();
		setIsLoading(false);
	}, []);

	const classes = useStyles();
	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Layout active="/address">
				<Container disableGutters className={classes.root}>
					<Box className={classes.box}>
						<Typography className={classes.header}>{contentStore.getContentValueLang("pages.addresses.pageTitle")}</Typography>

						<AddAddressModal
							open={addressModalOpen}
							handleClose={handleAddressModalClose}
							userId={rootStore.auth.userId}
							editingAddress={editingAddress}
							isEditingAddress={isEditingAddress}/>
						<Button className={classes.addButton} onClick={handleNewAddress}
										variant="outlined"><AddIcon/>{contentStore.getContentValueLang("pages.addresses.addNewAddress")}</Button>
					</Box>
					<List disablePadding className={classes.addressList}>
						{addresses.map((address, index) =>
							<ListItem disableGutters key={index}>
								<AddressCard
									refreshAddresses={getAddresses}
									address={address}
									editAddress={handleEditAddress}/>
							</ListItem>
						)}
					</List>
				</Container>
			</Layout>
		</MainLayout>
	);
});

export default Address;
