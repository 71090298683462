import { Grid, makeStyles, Typography, Box } from '@material-ui/core'
import React from 'react'
import clsx from "clsx";

const useStyles = makeStyles(theme=>({
    root:{
        height:"120px",
        borderTop:"rgba(193, 193, 193, .5) solid 1px",
        alignItems:"center",
        display:"flex",
        justifyContent:"space-between",
        padding:"0 5%",
    },
    copyrightGrid:{
        zIndex:10,
        "@media (max-width:599px)": {
            textAlign: "center"
        },

    },
    copyright:{

        font:"normal normal normal 32px/60px Gilroy",
    }
    ,
    footerLinkBox:{
        display:"flex",
        gap:"24px",
        justifyContent:"flex-end",
        "@media (max-width:599px)":{
            justifyContent:"center",
        }
    },
    footerLink:{
        color:"#1E2436",
        textDecoration:"none",
        font:"normal normal normal 20px/60px Gilroy",
        "@media (max-width:600px)":{
            fontSize:16
        }

    },
    borderNone:{
        border:"none"
    }
}))
const RegisterFooter = ({copyrightClassName}) => {
    const classes = useStyles()
    return (
        <Grid container  className={classes.root}>
            <Grid item xs={12} sm={3} className={classes.copyrightGrid}>
                <Typography className={clsx(classes.copyright,copyrightClassName)}>Copyright</Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.footerLinkBox}>
                <a className={classes.footerLink}>Terms</a>
                <a className={classes.footerLink}>Privacy</a>
                <a className={classes.footerLink}>Security</a>
                <a className={classes.footerLink}>Get in Touch</a>
            </Grid>
        </Grid>
    )
}

export default RegisterFooter
