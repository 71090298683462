import React from "react";
import { Box, Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Pinterest } from "@material-ui/icons";
import SecretLink from "./SecretLink";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: {},
	goTopButton: {
		background: "#2B3747",
		color: "white",
		padding: 16,
		borderRadius: 0,
		"&:hover": {
			background: "#2B3747"
		}
	},
	gridBox: {
		padding: 24
	},
	gridMain: {
		background: "linear-gradient(110deg, #554686 0%, #427177 50%, #DBCBD8 100%)",
		color: "white",
		padding: "0 10%"
	},
	heading: {
		font: "normal normal bold 24px/20px Calibri",
		margin: "10px 0",
		"@media (max-width:599px)":
			{
				fontSize: 12
			},
		"@media (max-width:959px)":
			{
				fontSize: 16
			}
	},
	bodyText: {
		display: "flex",
		alignItems: "center",
		margin: "4px 0",
		font: "normal normal normal 20px/20px Calibri",
		"@media (max-width:599px)":
			{
				fontSize: 8
			},
		"@media (max-width:959px)":
			{
				fontSize: 12
			}
	}

});


function UpperFooter () {
const rootStore = useStores()
	const contentStore = rootStore.contentStore
	const classes = useStyles();

	return (
		<Container maxWidth={false} disableGutters>
			<Button fullWidth className={classes.goTopButton}
							onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>{contentStore.getContentValueLang("pages.footer.goBackToTop.title")}</Button>
			<Box className={classes.gridBox}>
				<Grid container className={classes.gridMain} spacing={6}>
					<Grid item xs={6} md={3}>
						<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.footer.contactTitle")}</Typography>
						<Typography className={classes.bodyText}>{contentStore.getContentValueLang("page.footer.address")}</Typography>
						<Typography className={classes.bodyText}>{contentStore.getContentValueLang("page.footer.phoneNumber")}</Typography>
						<Typography className={classes.bodyText}>{contentStore.getContentValueLang("page.footer.mailAddress")}</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.footer.aboutUsTitle")}</Typography>
						<SecretLink to={"/franchise"} showPointer>
							<Typography className={classes.bodyText}>• Yazar Olmak İster misiniz?</Typography>
						</SecretLink>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.footer.faqTitle")}</Typography>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
						<Typography className={classes.bodyText}>• Lorem ipsum dolor</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.footer.socialMediaTitle")}</Typography>
						<Typography className={classes.bodyText}><Instagram/> Instagram</Typography>
						<Typography className={classes.bodyText}><LinkedIn/> LinkedIn</Typography>
						<Typography className={classes.bodyText}><Pinterest/> Google</Typography>
						<Typography className={classes.bodyText}><Facebook/> Facebook</Typography>
					</Grid>

				</Grid>
			</Box>
		</Container>
	);
}
export default observer(UpperFooter)
