import React, { useEffect, useState } from "react";
import { makeStyles, Portal, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import AddAddressModal from "./AddAddressModal";
import ChangeBillingAddressModal from "./ChangeBillingAddressModal";
import { useStores } from "../models";
import userIcon from "../resources/temp/user.png";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
	root: {
		padding: "40px 70px",
		border: "1px solid #E3E3E3",
		boxShadow: "3px 3px 6px #00000029",
		marginBottom: 40
	},
	heading: {
		font: "normal normal bold 36px/40px Calibri",
		marginBottom: "15px"
	},
	user: {
		font: "normal normal bold 17px/20px Calibri;",
		margin: "15px 0",
		"& svg": {
			transform: "translateY(8px)",
			marginRight: 20
		}
	},
	changeUserButton: {
		font: "normal normal 300 17px/20px Calibri",
		color: "#747474",
		textTransform: "none"
	},
	addressInfo: {
		margin: "30px 0",
		font: "normal normal normal 14px/18px Montserrat"
	},
	useAsBillingAddress: {
		margin: "15px 0",
		font: "normal normal bold 17px/20px Calibri"
	},
	addNewAddress: {
		font: "normal normal 300 17px/20px Calibri",
		color: "#747474"
	},
	userIcon: {
		marginRight: 8
	}
}));

function PaymentAddressPaper ({ setFormValue }) {
	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;
	const classes = useStyles();
	const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
	const [isChangeAddressModalOpen, setIsChangeAddressModalOpen] = useState(false);

	const [userBillAddress, setUserBillAddress] = useState({});
	const [userAddresses, setUserAddresses] = useState([]);
	setFormValue("billingAddress_id", userBillAddress.id);

	function toggleAddAddressModal () {
		setIsAddAddressOpen(!isAddAddressOpen);
	}

	function toggleChangeUserBillingAddressModal () {
		setIsChangeAddressModalOpen(!isChangeAddressModalOpen);
	}

	useEffect(async () => {
		const response = await api.userAddress.index(rootStore.auth.userId);
		setUserAddresses(response.data);
		setUserBillAddress(response.data[0]);
	}, []);
	return (
		<Paper className={classes.root}>
			<Portal>
				<ChangeBillingAddressModal open={isChangeAddressModalOpen} onClose={toggleChangeUserBillingAddressModal}
																	 addresses={userAddresses} setBillingAddress={setUserBillAddress}
																	 currentBillingAddress={userBillAddress}/>
				<AddAddressModal open={isAddAddressOpen} userId={rootStore.auth.userId}
												 handleClose={toggleAddAddressModal}/>
			</Portal>
			<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.order.address")}</Typography>
			<Typography className={classes.user}>
				<img src={userIcon} className={classes.userIcon}/>
				{userBillAddress.fullName}
				<Button className={classes.changeUserButton} onClick={toggleChangeUserBillingAddressModal}>{contentStore.getContentValueLang("pages.order.changeUser")}</Button>
			</Typography>
			<Typography className={classes.addressInfo}>{userBillAddress.address}</Typography>
			<Typography className={classes.addressInfo}>{userBillAddress.phoneNumber}</Typography>
			<Typography className={classes.useAsBillingAddress}>{contentStore.getContentValueLang("pages.order.useThisAsBillingAddress")}</Typography>
			<Button className={classes.addNewAddress} onClick={toggleAddAddressModal}><AddIcon
				style={{ marginRight: 4 }}/>({contentStore.getContentValueLang("pages.order.addNewAddress")})</Button>
		</Paper>
	);
}

export default observer(PaymentAddressPaper);
