import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TrashCan from "../resources/temp/trash.png";
import { useStores } from "../models";
import { asset } from "../config/initialize";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: "3px 3px 6px #00000029",
		padding: "20px"
	},
	mainGrid: {
		display: "flex",
		alignItems: "center"
	},
	cardMedia: {
		height: 200,
		width: 150
	},
	infoGrid: {
		padding: 10,
		alignSelf: "flex-start"
	},
	productName: {
		font: "normal normal normal 22px/27px Calibri"
	},
	productDesc: {
		font: "normal normal 300 14px/18px Montserrat",
		margin: "20px 0",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "initial !important",
		display: "-webkit-box",
		lineClamp: 5,
		boxOrient: "vertical"
	},
	priceGrid: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center"
	},
	realPrice: {
		font: "normal normal normal 22px/27px Calibri",
		position: "relative",
		width: "fit-content",
		margin: "0 auto",
		color: "#8B8B8B",
		"&:before": {
			position: "absolute",
			left: 0,
			top: "40%",
			width: "100%",
			content: "''",
			transform: "rotate(30deg)",
			border: "1px solid #FF000080"
		},
		"&:after": {
			position: "absolute",
			left: 0,
			top: "40%",
			width: "100%",
			content: "''",
			transform: "rotate(-30deg)",
			border: "1px solid #FF000080"
		}
	},

	discountPrice: {
		font: "normal normal bold 28px/35px Calibri",
		color: "#2C2C2C"
	}
}));

const BasketItem = (props) => {

	const { product, product_id } = props;
	const classes = useStyles();
	const rootStore = useStores();
	const userCart = rootStore.cart;

	function handleDeleteItem () {
		userCart.deleteItem(product_id);
	}

	return (
		<Card className={classes.root}>
			<Grid container className={classes.mainGrid}>
				<Grid item sm={3} xs={12}>
					<img className={classes.cardMedia} src={asset(product?.image)}/>
				</Grid>
				<Grid item sm={6} xs={12} className={classes.infoGrid}>
					<Typography className={classes.productName}>
						{product.name.tr}
					</Typography>
					<div dangerouslySetInnerHTML={{ __html: product.shortDescription.tr }}/>

				</Grid>
				<Grid item sm={2} xs={6} className={classes.priceGrid}>
					{/*<Typography className={classes.realPrice}>
                        ${"price"}
                    </Typography>*/}
					<Typography className={classes.discountPrice}>
						${product.price}
					</Typography>
				</Grid>
				<Grid item xs={6} sm={1} className={classes.priceGrid}>
					<IconButton onClick={handleDeleteItem}>
						<img src={TrashCan} alt=""/>
					</IconButton>
				</Grid>
			</Grid>
		</Card>
	);
};

export default observer(BasketItem);
