import React from 'react'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import SecretLink from "./SecretLink";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "50px",
        border: "1px solid #E3E3E3",
        boxShadow: "3px 3px 6px #00000029"
    },
    orderSumHeading: {
        font: "normal normal bold 32px/39px Calibri",
        color: "#2C2C2C",
        marginBottom: "20px"
    },
    orderSumCont: {
        padding: "0 20px",
        flexDirection: "column",
        display: "flex",
        gap: "16px"
    },
    orderSumBox: {
        display: "flex",
        justifyContent: "space-between",
        "& p": {
            font: "normal normal 300 20px/24px Calibri",
        }
    },
    costSum: {
        font: "normal normal bold 20px/24px Calibri",
        color: "#2C2C2C",
        marginLeft: "auto"
    },
    divider: {
        border: "1px solid #CCCCCC",
    },
}))

function OrderSummary(props) {
    const {basketCost = 0} = props
	const rootStore = useStores()
	const contentStore = rootStore.contentStore
    const classes = useStyles()
    return (
        <Paper className={classes.root}>
            <Typography className={classes.orderSumHeading}>{contentStore.getContentValueLang("pages.cart.orderSummary")}</Typography>

            <Box className={classes.orderSumCont}>
                <Box className={classes.orderSumBox}>
                    <Typography>{contentStore.getContentValueLang("pages.cart.totalPrice")}</Typography>
                    <Typography>{basketCost}$</Typography>
                </Box>
                {/*
                <Box className={classes.orderSumBox}>
                    <Typography>KDV</Typography>
                    <Typography>3.99$</Typography>
                </Box>

                <Box className={classes.orderSumBox}>
                    <Typography>Hizmet Bedeli</Typography>
                    <Typography>2.99$</Typography>
                </Box>
*/}
                <Box className={classes.divider}/>


                <Typography className={classes.costSum}>{basketCost}$</Typography>
            </Box>

        </Paper>


    )
}

export default observer(OrderSummary)
