import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";
import {ContentModel} from "../content/content";

/**
 * ContentStore model
 */
export const ContentStoreModel = types.model({
    locales: types.array(types.string),
    contents: types.array(ContentModel)
}).extend(withEnvironment)
    .actions(self => ({
        getContentValue(key: string) {
            const contentMatch = self.contents.find((content) => content.key === key)
            // @ts-ignore
            return contentMatch?.value ?? ""
        },
        getContentValueLang(key: string) {
            const contentMatch = self.contents.find((content) => content.key === key)
            // @ts-ignore
            return contentMatch?.valueLang["tr"] ?? ""
        },
        afterCreate: flow(function* () {
            const apiResponse = yield self.environment.api.customizations.cms()
            const apiData = apiResponse.data
            self.locales = apiData?.locales ?? [""]
            self.contents = apiData?.contents ?? []
        })
    }))

type ContentStoreType = Instance<typeof ContentStoreModel>

export interface ContentStore extends ContentStoreType {
}

type ContentStoreSnapshotType = SnapshotOut<typeof ContentStoreModel>

export interface ContentStoreSnapshot extends ContentStoreSnapshotType {
}

// @ts-ignore
export const createContentStoreDefaultModel = () => types.optional(ContentStoreModel, {})
