import React from "react";
import SignInCard from "../components/SignInCard";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import RegisterHeader from "../components/RegisterHeader";
import Footer from "../components/layout/Footer";
import SecretLink from "../components/SecretLink";
import doctorBG from "../resources/doctorBG.png";
import { Redirect } from "react-router-dom";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles((theme) => ({
    root: {
        background: `url(${doctorBG})`,
        backgroundPosition: "center",
        backgroundSize: "auto",
        height: "150%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    box: {
        display: "flex",
        gap: "20px",
        padding: "30px 0 ",
        "@media (max-width: 512px)": {
            flexDirection: "column",
        },
        alignItems: "center",
    },
    haveAccount: {
        color: "#757575",
        font: "normal normal 300 22px/28px Calibri"
    },
    link: {
        color: "#1E2436",
        font: "normal normal bold 22px/28px Calibri",
        cursor: "pointer"
    },
    footer: {
        marginTop: "100px",
        width: "100%",
    }
}))

const Login = () => {
    const classes = useStyles()
    const rootStore = useStores()
	const contentStore = rootStore.contentStore
    if (rootStore.auth.isLoggedIn) return <Redirect to={"/"}/>
    return (
        <Container className={classes.root} maxWidth={false} disableGutters>

            <RegisterHeader/>
            <SignInCard/>

            <Box className={classes.box}>
                <Typography className={classes.haveAccount}>{contentStore.getContentValueLang("pages.signIn.dontYouHaveAnAccount.text")}</Typography>
                <SecretLink to="/signup" showPointer className={classes.link}>{contentStore.getContentValueLang("pages.signIn.dontYouHaveAnAccount.signUpButton")}</SecretLink>
            </Box>
					{/* <SecretLink to="/" className={classes.link}>Forgot Password</SecretLink>*/}
            <Box className={classes.footer}>
                <Footer/>
            </Box>
        </Container>
    )
}

export default observer(Login)
