import { IconButton } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import React from "react";
import { _ } from "../App";
import { useWindowSize } from "../helpers/hooks";
import { makeStyles } from "@material-ui/core/styles";


import Slider from "react-slick";

const useStyles = makeStyles({
	root: {},
	arrows: {
		zIndex: 10,
		top: "45%",
		position: "absolute",
		background: "rgba(0,0,0,.2)"
	}
});

function Arrow ({ direction, onClick, style }) {
	const classes = useStyles(direction);
	const directionPos = direction === "right" ? { right: 25 } : { left: 25 };

	return (
		<IconButton className={classes.arrows}
								style={{ ...style, ...directionPos }} onClick={onClick}>
			{direction === "right" ? <ArrowRight/> : <ArrowLeft/>}
		</IconButton>
	);
}

function ResponsiveCarousel ({
	hideArrows = false,
	responsivePattern = {},
	show = 1,
	children,
	dots = false,
	scroll = 1,
	infinite = false,
	...props
}) {
	const windowSize = useWindowSize();
	const dirtyResponsiveSize = _.map(responsivePattern, (pattern, screen) => {
		if (_.inRange(windowSize.width, pattern.breakpoint.min, pattern.breakpoint.max)) {
			return pattern.items;
		}
	});
	const responsiveSize = _.compact(dirtyResponsiveSize)[0] ?? show;
	return (
		<Slider
			slidesToShow={responsiveSize}
			dots={dots}
			arrows={!hideArrows}
			slidesToScroll={scroll}
			infinite={children.length > responsiveSize && infinite}
			nextArrow={<Arrow direction="right"/>}
			prevArrow={<Arrow direction="left"/>} {...props}>
			{children}
		</Slider>
	);
}

export default ResponsiveCarousel;
