import history from "./history";
import routes from "../config/routes";
import { _ } from "../App";
import qs from "qs";
export {history}
export { qs };

export function getPaths() {
    let paths = {};
    routes.map(route => {
        const {name, path} = route;
        paths[name] = path;
    });
    return paths;
}

export function getNavbarItems() {
    let navbarItems = [];
    routes.map(route => {
        const {path, icon} = route;
        const title = route.title?.navbar;
        if (title)
            navbarItems.push({title, path, icon});
    });
    return navbarItems;
}

export function getRouteByName(name) {
    return routes.find(route => route.name === name);
}

export function getPathByName(name, replace = {}) {
    const route = getRouteByName(name);
    let path = route.path;

    for (let parameter in replace) {
        if (parameter && replace && path) {
            path = path.replace(`:${parameter}`, replace[parameter]);
        }
    }

    return path;
}

export function getIconByName(name) {
    const route = getRouteByName(name);
    return route.icon;
}

export function getHomeTitleByName(name) {
    const route = getRouteByName(name);
    return route.title.home;
}

export function getNavbarTitleByName(name) {
    const route = getRouteByName(name);
    return route.title.navbar;
}

export function getQueryString() {
    return location.search;
}

export function getQuery(options = {}) {
    let newQuery = getQueryString();
    newQuery = decodeURIComponent(newQuery)
    newQuery = qs.parse(newQuery.substring(1), options);
    return newQuery;
}

export function makeQuery(query, options = {}) {
    const newQuery = qs.stringify(query, options);
    return newQuery;
}

export function makeQueryRoute(uri, query) {
    const queryString = makeQuery(query);
    const queryRoute = uri + "?" + queryString;
    return queryRoute;
}

export function setQuery(query, uri = location.pathname, options = {}) {
    const newQuery = makeQuery(query, options);
    history.push({pathname: uri, search: "?" + newQuery});
}

export function modifyQuery(query, uri = location.pathname, options = {}) {
    let newQuery = getQuery(options);
    newQuery = _.deepExtend(newQuery, query);
    setQuery(newQuery, uri, options);
}
