import { Box, Button, Card, CardContent, Grid, makeStyles, Portal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../components/UserInfoLayout";
import MainLayout from "../components/MainLayout";
import OrderDetailOrderInfo from "../components/OrderDetailOrderInfo";
import SecretLink from "../components/SecretLink";
import { localeDateLong } from "../helpers/date";
import { useStores } from "../models";
import InformingDialog from "../components/InformingDialog";
import { getContent } from "../helpers/productsContentHandler";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: {
		padding: "0",
		marginBottom: "150px",
		rowGap: "16px",
		"@media (max-width:959px)": {
			width: "100%"
		}
	},
	pageHeadingGrid: {
		display: "flex",
		alignItems: "flex-end",
		"@media (max-width:599px)": {
			textAlign: "center",
			justifyContent: "center"
		}
	},
	goBackButton: {
		margin: "16px 0",
		color: "#1E243673",
		border: "1px solid #1E243673",
		textTransform: "none",
		fontSize: "20px",
		"@media (max-width:599px)": {
			width: "100%"
		}
	},
	cardRoot: {
		border: "1px solid #B8B8B8"
	},
	cardTop: {
		background: "#E3E3E3",
		paddingLeft: "40px",
		borderBottom: "1px solid #B8B8B8"
	},
	borderedGrid: {
		borderLeft: "1px solid #B8B8B8",
		paddingLeft: "16px",
		marginLeft: "16px",
		"@media (max-width:599px)": {
			margin: "8px 0",
			padding: "8px 0",
			borderLeft: "none",
			borderTop: "1px solid #B8B8B8"
		}
	},
	cardBotGrid: {
		padding: "30px 40px",
		rowGap: "30px"
	},
	cardDescGridCont: {
		gap: "36px"
	},
	paymentInfo: {
		marginBottom: "20px"
	},
	paymentInfoBoxes: {
		display: "flex",
		justifyContent: "space-between",
		margin: "12px 0"
	},
	hr: {
		color: "#BCBCBC"
	},
	buttonGrid: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end"
	},
	button: {
		textTransform: "none"
	},
	productDescGrid: {
		display: "flex",
		flexDirection: "column",
		gap: "30px"
	},
	infoButtonGrid: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	pageHeading: {
		font: "normal normal bold 28px/34px Montserrat"
	},
	sectionHeading: {
		font: "normal normal bold 24px/24px Calibri"
	},
	sectionHeadingInfo: {
		font: "normal normal normal 19px/24px Calibri"
	},
	cardHeading: {
		font: "normal normal normal 22px/27px Calibri"
	},
	cardDesc: {
		marginTop: "10px",
		font: "normal normal 300 14px/18px Montserrat"
	}
});

const OrderInfo = (props) => {
	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;
	const [isLoading, setIsLoading] = useState(false);
	const orderId = props.match.params.id;
	const [order, setOrder] = useState({});
	const [products, setProducts] = useState([]);
	useEffect(async () => {
		setIsLoading(true);
		const response = await api.order.show(orderId);
		const orderData = response.data;
		setOrder(orderData);
		setProducts(orderData.items);
		setIsLoading(false);
	}, []);
	const totalProductCost = products.reduce((prev, product) => prev + product.price, 0);
	const classes = useStyles();
	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Layout active="/orders">
				<Grid container className={classes.root}>
					<Grid item xs={12} sm={6} className={classes.pageHeadingGrid}>
						<Typography className={classes.pageHeading}>{contentStore.getContentValueLang("pages.order.orderDetails")}</Typography>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.buttonGrid}>
						<SecretLink to="/orders">
							<Button variant="outlined" className={classes.goBackButton}>{contentStore.getContentValueLang("pages.order.goBack")}</Button>
						</SecretLink>
					</Grid>
					<Grid item xs={12}>
						<Card className={classes.cardRoot}>
							<CardContent className={classes.cardTop}>
								<Grid container>
									<Grid item xs={12} sm={4}>
										<Typography className={classes.sectionHeading}>{contentStore.getContentValueLang("pages.order.date")}</Typography>
										<Typography className={classes.sectionHeadingInfo}>{localeDateLong(order.data)}</Typography>
									</Grid>
									<Grid item xs={12} sm={4} className={classes.borderedGrid}>
										<Typography className={classes.sectionHeading}>{contentStore.getContentValueLang("pages.order.orderSummary")}</Typography>
										<Typography className={classes.sectionHeadingInfo}>{getContent(products)}</Typography>
									</Grid>
									{/*<Grid item xs={12} sm={4} className={classes.borderedGrid}>
                                        <Typography className={classes.sectionHeading}>Alıcı</Typography>
                                        <Typography className={classes.sectionHeadingInfo}>{reciever}</Typography>
                                    </Grid>*/}
								</Grid>
							</CardContent>
							<CardContent>
								<Grid container className={classes.cardBotGrid}>
									<Grid item sm={8} className={classes.cardDescGridCont}>
										{
											products.map((product, index) =>
												<OrderDetailOrderInfo {...product} key={index}/>
											)
										}
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box className={classes.paymentInfo}>
											<Typography className={classes.cardHeading}>Ödeme Bilgileri</Typography>
											{/*<Typography className={classes.cardDesc}>{paymentInfo}</Typography>*/}
										</Box>
										<Box className={classes.paymentInfoBoxes}>
											<Typography className={classes.cardDesc}>Ürün Toplamı</Typography>
											<Typography className={classes.cardDesc}>{totalProductCost}$</Typography>
										</Box>
										{/*<Box className={classes.paymentInfoBoxes}>
											<Typography className={classes.cardDesc}>KDV</Typography>
											<Typography className={classes.cardDesc}>{VAT}$</Typography>
										</Box>
										<Box className={classes.paymentInfoBoxes}>
											<Typography className={classes.cardDesc}>Hizmet Bedeli</Typography>
											<Typography className={classes.cardDesc}>{serviceCost}$</Typography>
										</Box>*/}
										<hr className={classes.hr}/>
										<Box className={classes.paymentInfoBoxes}>
											<Typography className={classes.cardDesc}><b>Toplam</b></Typography>
											<Typography
												className={classes.cardDesc}><b>{totalProductCost}$</b></Typography>
										</Box>

									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Layout>
		</MainLayout>
	);
};

export default observer(OrderInfo);
