import React from "react";
import { Box, Button, Container, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import SecretLink from "./SecretLink";
import logo from "../resources/logo.png";


const useStyles = makeStyles(theme => ({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		padding: "0"
	},
	toolbarContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "10px"
	},
	toolbarLogo: {
		maxHeight: 60
	},
	toolbarBorder: {
		height: "20px",
		width: "100%",
		background: "linear-gradient(90deg, rgba(30,36,54,1) 0%, rgba(85,70,134,1) 100%)"
	},
	loginButton: {
		margin: "0 4px",
		background: "#1E2436",
		color: "white",
		font: "normal normal normal 20px/20px Verdana",
		padding: "12px 28px",
		textTransform: "none",
		borderRadius: "7px",
		"&:hover": {
			background: "#554686"
		}
	}
}));

export default function MainPageToolbar () {
	const classes = useStyles();

	return (
		<Toolbar className={classes.toolbar}>
			<Container className={classes.toolbarContainer}>
				<SecretLink to="/" showPointer>
					<img src={logo} className={classes.toolbarLogo}/>
				</SecretLink>

				<SecretLink to="/login">
					<Button className={classes.loginButton}>Login</Button>
				</SecretLink>
			</Container>
			<Box className={classes.toolbarBorder}/>
		</Toolbar>
	);
}
