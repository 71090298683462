import React from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStores } from "../models";
import { asset } from "../config/initialize";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
	root: {
		padding: "100px 60px"
	},
	imageGridBox: {
		margin: "30px auto",
		background: "#F2FDFF",
		boxShadow: "inset 5px 5px 15px #00000080",
		display: "flex",
		padding: "20px",
		borderRadius: "15px",
		maxWidth: "fit-content"
	},
	image: {
		borderRadius: "15px",
		maxWidth:"100%"
	},
	bodyGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",

	},
	heading: {
		font: "normal normal bold 36px/50px Gilroy"

	},
	body: {
		font: "normal normal normal 18px/28px Gilroy"
	}
}));


function MainPageSection () {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	return (
		<Container className={classes.root} maxWidth={false}>

			<Grid container direction="row" spacing={5}>
				<Grid item xs={12} md={6}>
					<Box className={classes.imageGridBox}>
						<img loading="lazy" className={classes.image}
								 src={asset(contentStore.getContentValue("pages.mainPage.section3.image1"))}/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} className={classes.bodyGrid}>
					<Typography
						className={classes.heading}>{contentStore.getContentValueLang("pages.mainPage.section3.title1")}</Typography>
					<Typography
						className={classes.body}>{contentStore.getContentValueLang("pages.mainPage.section3.description1")}</Typography>
				</Grid>
			</Grid>
			<Grid container direction="row-reverse" spacing={5}>
				<Grid item xs={12} md={6}>
					<Box className={classes.imageGridBox}>
						<img loading="lazy" className={classes.image}
								 src={asset(contentStore.getContentValue("pages.mainPage.section3.image2"))}/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} className={classes.bodyGrid}>
					<Typography
						className={classes.heading}>{contentStore.getContentValueLang("pages.mainPage.section3.title2")}</Typography>
					<Typography
						className={classes.body}>{contentStore.getContentValueLang("pages.mainPage.section3.description2")}</Typography>
				</Grid>
			</Grid>
		</Container>
	);
}

export default observer(MainPageSection);
