import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Box, Button, Card, Checkbox, FormControlLabel, makeStyles, Portal, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import TextField from "./CustomTextField";
import { useForm } from "react-hook-form";
import { getPathByName, history } from "../helpers/routing";
import { useYupValidationResolver } from "../helpers/hooks";
import PhoneInput from "./PhoneInput";
import { useStores } from "../models";
import { preparePhoneNumber } from "../helpers/phoneNumberHelper";
import { observer } from "mobx-react-lite";
import InformingDialog from "./InformingDialog";

const validationSchema = Yup.object({
	phoneNumber: Yup.string()
		.matches(/\d{2}\s\d{3}\s\d{3}\s\d{2}\s\d{2}/g, "Lütfen geçerli bir telefon numarası giriniz")
		.required("Lütfen geçerli bir telefon numarası giriniz"),
	password: Yup.string()
		.required("Lütfen şifrenizi giriniz")
});

const useStyles = makeStyles((theme) => ({
	formCard: {
		width: "500px",
		padding: "100px 50px",
		borderRadius: "0",
		boxShadow: " 3px 3px 20px #00000024",
		"@media (max-width:550px)": {
			width: "90%"
		}
	},
	formHeader: {
		font: "normal normal 300 40px/28px Calibri"
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "24px",
		margin: "36px 0"
	},
	formButton: {
		width: "fit-content",
		background: "linear-gradient(109deg, rgba(30,36,54,1) 0%, rgba(85,70,134,1) 100%)",
		color: "white",
		padding: "14px 24px",
		textTransform: "none",
		font: "normal normal 300 20px/28px Calibri",
		borderRadius: "7px"
	},
	checkBoxLabel: {
		font: "normal normal 300 16px/28px Calibri"
	}


}));


const SignInForm = () => {
	const [showPassword, setShowPassword] = useState(false);
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	const resolver = useYupValidationResolver(validationSchema);
	const { register, handleSubmit, control, setValue, getValues } = useForm({ resolver });
	const [errors, setErrors] = useState({});
	const location = history.location;
	const { from } = location.state || { from: { pathname: getPathByName("home") } };
	const [isLoading, setIsLoading] = useState(false);

	async function onSubmit (data) {
		setIsLoading(true);
		const response = await rootStore.auth.login(data.phoneNumber, data.password, data.savePassword);
		setIsLoading(false);
		if (response.status === 201) {
			history.push(from);
		} else {
			const errors = {
				phoneNumber: {
					message: "Telefon numaranız veya şifreniz yanlış"
				},
				password: {
					message: "Telefon numaranız veya şifreniz yanlış"
				}
			};
			setErrors(errors);
		}
	}

	function onError (errors) {
		setErrors(errors);
	}

	useEffect(() => {
		const user = rootStore.auth;
		setValue("phoneNumber", preparePhoneNumber(user.phoneNumber));
		setValue("password", user.password);
		setValue("savePassword", user.savePassword);
	}, []);

	return (
		<Card className={classes.formCard}>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Typography
				className={classes.formHeader}>{contentStore.getContentValueLang("pages.signIn.pageTitle")}</Typography>
			<form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
				<PhoneInput control={control} error={!!errors?.phoneNumber} helperText={errors?.phoneNumber?.message ?? ""}
										label={contentStore.getContentValueLang("pages.signIn.placeholder.phoneNumber")} InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<PhoneIcon/>
						</InputAdornment>
					)

				}}/>

				<TextField
					{...register("password")}
					error={!!errors?.password}
					helperText={errors?.password?.message ?? ""}
					label={contentStore.getContentValueLang("pages.signIn.placeholder.password")}
					variant="outlined"
					name="password"
					type={showPassword ? "text" : "password"}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LockIcon/>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(pre => !pre)}>
									{showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				<Box display="flex" justifyContent="space-between">
					<FormControlLabel
						control={<Checkbox type="checkbox" defaultChecked={rootStore.auth.savePassword}
															 name="savePassword" {...register("savePassword")}/>}
						label={<Typography
							className={classes.checkBoxLabel}>{contentStore.getContentValueLang("pages.signIn.savePasswordButton")}</Typography>}/>
					<Button type="submit"
									className={classes.formButton}>{contentStore.getContentValueLang("pages.signIn.loginButton")}</Button>
				</Box>
			</form>
		</Card>
	);
};

export default observer(SignInForm);
