import {ApiBase} from "../api"


export class ProductPhotosApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index  (productId:string)  {
        const response = await this.api.apisauce.get(`/products/${productId}/photos`)
        return response
    }
}
