import {ApiBase} from "../api"

export class OrderApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async store (paymentData:any)  {
        const response = await this.api.apisauce.post(
            "/orders",
            paymentData
        );
        return response;
    }
    async index ()  {
        const response = await this.api.apisauce.get(
            "/orders"
        );
        return response;
    }
    async show  (orderId:string)  {
        const response = await this.api.apisauce.get(
            "/orders/" + orderId,
        );
        return response;
    }

}
