import {ApiBase} from "../api"

export class OrganizationsApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index   ()  {
        const response = await this.api.apisauce.get("/organisations")
        return response
    }
}
