import React, { useState } from "react";
import { Checkbox, Container, FormControlLabel, makeStyles, Paper, Typography } from "@material-ui/core";
import OrderSummary from "./OrderSummary";
import Button from "@material-ui/core/Button";
import { useStores } from "../models";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column"
	},
	paper: {
		padding: "20px 40px",
		margin: "40px 0"
	},
	checkbox: {
		marginRight: "20px"
	},
	agreement: {
		"&, & a": {
			color: "#2C2C2C",
			font: "normal normal 300 17px/21px Calibri"
		}
	},
	payLink: {
		display: "flex",
		alignItems: "center"
	},
	payButton: {
		font: "normal normal bold 24px/48px Calibri",
		textTransform: "none",
		color: "white",
		background: "linear-gradient(114deg, #1E2436 0%, #554686 100%)",
		width: "70%",
		margin: "0 auto",
		borderRadius: "7px",
		"&.MuiButton-root.Mui-disabled": {
			color: "white",
			opacity: .4
		}
	}
}));

function PaymentOrderSummary (props) {
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	const { basketCost = 0 } = props;
	const [agreed, setAgreed] = useState(false);


	function handleAgreement () {
		setAgreed(pre => !pre);
	}


	const classes = useStyles();
	return (
		<Container disableGutters className={classes.root}>
			<OrderSummary basketCost={basketCost}/>
			<Paper className={classes.paper}>
				<FormControlLabel
					control={<Checkbox className={classes.checkbox} value={agreed} onChange={handleAgreement}/>}
					label={<Typography className={classes.agreement}>{contentStore.getContentValueLang("pages.order.acceptTerms")}</Typography>}/>
			</Paper>
			<Button disabled={!agreed} type={"submit"}
							className={classes.payButton}>{contentStore.getContentValueLang("pages.order.pay")}</Button>

		</Container>
	);
}

export default PaymentOrderSummary;
