import Container from "@material-ui/core/Container";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Copyright } from "@material-ui/icons";
import { useStores } from "../../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: regPage => ({
		borderTop: regPage ? "1px solid #C1C1C1" : "none",
		background: regPage ? "transparent" : "#1E2436",
		padding: "24px 5%",
		color: regPage ? "#1E2436" : "white",
		"& > a": {
			color: regPage ? "#1E2436" : "white"
		}
	}),
	copyrightBox: {
		display: "flex",
		alignItems: "center",
		gap: 8,
		"& > *": {
			font: "normal normal 200 12px/16px Calibri"
		}
	},
	copyrightDesc: {
		maxWidth: 400,
		font: "normal normal 200 12px/16px Calibri"
	},
	linkGrid: {
		textAlign: "right"
	},
	links: regPage => ({
		color: regPage ? "#1E2436" : "white",
		textDecoration: "none",
		font: "normal normal 400 12px/16px Calibri"
	})
});

function Footer ({ regPage = false }) {
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	const classes = useStyles(regPage);
	return (

		<Container maxWidth={false} className={classes.root} disableGutters>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Box className={classes.copyrightBox}>
						<Copyright/>
						<Typography>{contentStore.getContentValueLang("pages.footer.copyright.title")}</Typography>
					</Box>
					<Typography
						className={classes.copyrightDesc}>{contentStore.getContentValueLang("pages.footer.copyright.description")}</Typography>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.linkGrid}>

					<a className={classes.links}>
						Kullanıcı Sözleşmesi
					</a>
					{" - "}
					<a className={classes.links}>
						Gizlilik Politikası
					</a>
					{" - "}
					<a className={classes.links}>
						Kişisel Veriler Politikası
					</a>
				</Grid>

			</Grid>


		</Container>
	);
}

export default observer(Footer);
