import React, {forwardRef, useState} from "react";
import TextField from "../components/CustomTextField";
import NumberFormat from "react-number-format";
import {Controller} from "react-hook-form";

const DebitCardInput = forwardRef(({
	control,
	error,
	variant = "outlined",
	size = "medium",
	helperText,
	format = "#### #### #### ####",
	popoverError = false,
	...props
}, ref) => {
	const helperMessage = popoverError ? null : error?.message ?? helperText;


	return (
		<Controller
			control={control}
			render={
				({field: {onChange, name, value}}) =>
					<NumberFormat
						fullWidth
						autoComplete='off'
						value={value}
						ref={ref}
						name={name}
						onValueChange={values=>onChange(values.floatValue)}
						customInput={TextField}
						variant={variant}
						format={format}
						mask="_"
						size={size}
						error={Boolean(error)}
						helperText={helperMessage}
						{...props}
					/>
			} name={"card_number"}/>
	);
});

export default DebitCardInput;
