import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Container, Typography} from "@material-ui/core";
import SecretLink from "./SecretLink";

const useStyles = makeStyles({
    root:{
        padding:50
    },
    box:{
        background:"#827680",
        color:"white",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        textAlign:"center",
        borderRadius:14,
        padding:60,
        gap:24
    },
    title:{
        font:"normal normal bold 38px/40px Gilroy",
        maxWidth:500,
        "@media (max-width:959px)":{
            fontSize:30,
            lineHeight:"34px"
        },
        "@media (max-width:599px)":{
            fontSize:24,
            lineHeight:"30px"
        }
    },
    body:{
        font:"normal normal normal 16px/24px Gilroy",
        maxWidth:800,
        "@media (max-width:959px)":{
            fontSize:14
        },
        "@media (max-width:599px)":{
            fontSize:12
        }
    },
    button:{
        font:"normal normal 600 17px/20px Gilroy",
        textTransform:"none",
        background:"white",
        color:"#1E2436",
        padding:"10px 14px"
    }
})

export default function FranchiseRegisterNow(){
    const classes = useStyles()
    return(
        <Container disableGutters maxWidth="xl" className={classes.root}>
            <Box className={classes.box}>

                <Typography className={classes.title}>Yazarlık başvurusunu Hemen Yapın!</Typography>
                <Typography className={classes.body}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. lis in posuere nec d ultrices dictum. Sed rhon Pellentesque odio sapien, iaculis in posuere nec d ultrices dictum. Sed rhon Sed porta augue sed ultrices dictum. Sed rhoncus</Typography>
							<SecretLink to={"/author-register"}>
								<Button className={classes.button}>Hemen Başvur</Button>
							</SecretLink>
            </Box>
        </Container>
    )
}
