import React from "react";
import { Box, Card, CardActionArea, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import SecretLink from "./SecretLink";
import { asset } from "../config/initialize";
import ResponsiveCarousel from "./ResponsiveCarousel";

const useStyles = makeStyles({
	root: {
		padding: 30
	},
	heading: {
		font: "normal normal bold 28px/30px Calibri",
		marginBottom: 14
	},
	body: {
		font: "normal normal normal 20px/20px Calibri",
		"@media (max-width:599px)": {
			fontSize: 16
		},
		"@media (max-width:959px)": {
			fontSize: 18
		}
	},
	subHeading: {
		font: "normal normal normal 16px/20px Calibri",
		margin: "30px 10% 10px"
	},
	lastsImage: {
		height: 150,
		margin: "0 auto"
	},
	itemCard: {
		margin: 12
	},
	itemCardAction: {
		justifyContent: "center",
		display: "flex",
		padding: 12
	},
	carouseIMG: {
		height: 200,
		width: 150
	},
	personalCard: {
		display: "flex",
		flexDirection: "column",
		height: "90%"
	},
	profilePicture: {
		width: 100,
		height: 100,
		margin: 16
	},
	card: {
		borderRadius: 14,
		padding: 30
	}
});

export default function PersonalizedSection ({
	branchProducts,
	opportunities,
	opportunitiesTitle = "",
	branchProductsTitle = ""
}) {
	const classes = useStyles();
	return (
		<Container disableGutters maxWidth="xl" className={classes.root}>
			<Grid container spacing={6}>
				<Grid item xs={12}>

					{
						opportunities.length > 0 && (
							<Card style={{ marginBottom: 48 }} className={classes.card}>
								<Typography className={classes.heading}>{opportunitiesTitle}</Typography>
								<ResponsiveCarousel responsivePattern={responsive}

								>
									{opportunities.map((opportunity) =>

										<Box key={opportunity.id}>
											<Card className={classes.itemCard}>
												<SecretLink to={"/product-detail/" + opportunity.id} showPointer>
													<CardActionArea className={classes.itemCardAction}>
														<img loading="lazy" className={classes.carouseIMG}
																 src={asset(opportunity?.product.image)}
																 alt=""/>
													</CardActionArea>
												</SecretLink>
											</Card>
										</Box>
									)}
								</ResponsiveCarousel>
							</Card>
						)
					}
					{
						branchProducts.length > 0 && (
							<Card className={classes.card}>
								<Typography className={classes.heading}>{branchProductsTitle}</Typography>
								<ResponsiveCarousel responsivePattern={responsive} infinite
								>
									{branchProducts.map((book) =>
										<Box key={book.id}>
											<Card className={classes.itemCard} key={book.id}>
												<SecretLink to={"/product-detail/" + book.id}>
													<CardActionArea className={classes.itemCardAction}>
														<img className={classes.carouseIMG}
																 src={asset(book.image)}
																 alt=""/>
													</CardActionArea>
												</SecretLink>
											</Card>
										</Box>
									)}
								</ResponsiveCarousel>
							</Card>
						)
					}
				</Grid>
			</Grid>
		</Container>
	);
}

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 1200 },
		items: 4
	},
	desktop: {
		breakpoint: { max: 1200, min: 1024 },
		items: 3
	},
	tablet: {
		breakpoint: { max: 1024, min: 600 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 600, min: 0 },
		items: 1
	}
};
