import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {CategoryModel} from "../category/category";
import {isBefore, sub} from "date-fns";
import {withEnvironment} from "../extensions/with-environment";

/**
 * CategoryStore model.
 */
export const CategoryStoreModel = types.model({
    categories: types.optional(types.array(CategoryModel), []),
    lastUpdated: types.optional(types.union(types.Date, types.null), null)
}).extend(withEnvironment)
    .actions(self => ({
        getCategories: flow(function* () {
            const response = yield self.environment.api.categories.index()
            self.categories = response.data
            self.lastUpdated = new Date()
        })
    })).actions(self => ({
        afterCreate() {
            self.getCategories()
            if (!self.lastUpdated || !isBefore(sub(self.lastUpdated, {hours: 2}), new Date())) {
            }
        }
    }))

type CategoryStoreType = Instance<typeof CategoryStoreModel>

export interface CategoryStore extends CategoryStoreType {
}

type CategoryStoreSnapshotType = SnapshotOut<typeof CategoryStoreModel>

export interface CategoryStoreSnapshot extends CategoryStoreSnapshotType {
}

export const createCategoryStoreDefaultModel = () => types.optional(CategoryStoreModel, {})
