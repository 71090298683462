import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import TextField from "./UserInfoTextField";
import { useForm } from "react-hook-form";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const validationSchema = Yup.object({
	couponCode: Yup.string("")
		.required("Lütfen Kupon kodunu giriniz")

});

const useStyles = makeStyles(theme => ({

	button: {
		textTransform: "none",
		border: "1px solid #1E2436",
		margin: "8px auto",
		font: "normal normal normal 24px/24px Calibri",
		width: "60%",
		padding: "12px"
	},
	card: {
		display: "flex",
		flexDirection: "column",
		gap: "36px",
		padding: "30px 40px",
		width: "50%",
		margin: "0 auto",
		boxShadow: "3px 3px 6px #00000029",
		border: "1px solid #B8B8B8",
		borderRadius: "7px",
		"@media (max-width:752px)": {
			width: "80%"
		}
	},
	heading: {
		font: " normal normal normal 30px/24px Calibri"
	},
	inputBase: {
		background: "#EEEEEE"
	}
}));

const AddCouponCard = (props) => {
	const { refreshCoupons } = props;
	const [error, setError] = useState({ is: false, text: "" });
	const classes = useStyles();

	const rootStore = useStores();
	const api = rootStore.environment.api;
	const contentStore = rootStore.contentStore

	const { register, handleSubmit } = useForm();


	const onSubmit = async (data) => {
		try {
			setError({ is: false, text: "" });
			const response = await api.coupons.consumeCoupon(rootStore.auth.userId, data.couponCode);
			const responseMessage = response.data.message;
			setError({ is: false, text: responseMessage });

			if (response.status === 201) {
				refreshCoupons();
				rootStore.auth.getOwnedProducts();
			}
		} catch (e) {
			setError({ is: true, text: "Bir hata oluştu." });
		}

	};
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Card className={classes.card}>
					<Typography className={classes.heading}> {contentStore.getContentValueLang("pages.coupons.addCoupon")} </Typography>
					<TextField variant="outlined" name="couponCode" error={error.is} required
										 InputProps={{ className: classes.inputBase }}
										 helperText={error.text} {...register("couponCode")}/>
					<Button variant="outlined" type="submit" className={classes.button}>{contentStore.getContentValueLang("pages.coupons.approveButton")}</Button>
				</Card>
			</form>
		</>
	);
};

export default observer(AddCouponCard);
