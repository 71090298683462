import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";
import { Fab, Grid, Icon, LinearProgress, Tooltip } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import PdfReaderStyles from "./style";
import FullscreenModalLayout from "./layout/FullscreenModalLayout";

pdfjs.GlobalWorkerOptions.workerSrc = import(
	"react-pdf/dist/esm/pdf.worker.entry"
	);

const CreatePages = (numPages, scale, page) => {
	const pages = [];

	for (let i = page * 10 + 1; i <= page * 10 + 10; i++) {
		if (i > numPages) break;

		pages.push(
			<Page
				renderMode="canvas"
				loading={<LinearProgress color="primary"/>}
				pageNumber={i}
				width={scale}
				renderTextLayer={false}
				renderAnnotationLayer={true}
			/>
		);
	}

	return pages;
};

const ZoomNavigator = ({ config, setConfig }) => {
	const { scale, minScale, maxScale, scalePoint } = config;

	const handleScale = type => {
		const comparation = type === "max" ? scale < maxScale : scale > minScale;
		const newScale =
			type === "max"
				? parseInt(scale) + parseInt(scalePoint)
				: parseInt(scale) - parseInt(scalePoint);

		if (comparation) setConfig({ ...config, scale: newScale });
	};

	const handleAutoScale = () => {
		const newScale = scale === minScale ? maxScale : minScale;
		setConfig({ ...config, scale: newScale });
	};

	return (
		<Grid
			container
			direction="column"
			justifyContent="space-between"
			alignItems="flex-end"
		>
			<Grid item>
				<Tooltip title="Ekrana Sığdır" placement="left">
					<Fab
						disableRipple
						color="primary"
						aria-label="add"
						onClick={handleAutoScale}
					>
						<Icon>fullscreen</Icon>
					</Fab>
				</Tooltip>
			</Grid>

			<Grid item>
				<Tooltip title="Yakınlaştır" placement="left">
					<Fab
						disableRipple
						color="primary"
						aria-label="add"
						onClick={() => handleScale("max")}
					>
						<Icon>add</Icon>
					</Fab>
				</Tooltip>
			</Grid>

			<Grid item>
				<Tooltip title="Uzaklaştır" placement="left">
					<Fab
						disableRipple
						color="primary"
						aria-label="add"
						onClick={() => handleScale("min")}
					>
						<Icon>remove</Icon>
					</Fab>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

const PDFViewer = ({ file, filename, onClose, open }) => {
	if (_.isEmpty(file)) return null;
	const classes = PdfReaderStyles();
	const fileOrBase64 = file.toLowerCase().endsWith(".pdf")
		? file
		: `data:application/pdf;base64,${file}`;

	const [config, setConfig] = useState({
		fullLoading: false,
		numPages: null,
		scale: parseInt(`${isMobile ? 370 : 700}`),
		pageNumber: 10,
		page: 0,
		minScale: 500,
		maxScale: parseInt(window.innerWidth) - 50,
		scalePoint: 40
	});

	const onDocumentLoadSuccess = ({ numPages }) => {
		setConfig({ ...config, numPages, fullLoading: true });
	};


	return (
		<FullscreenModalLayout
			open={open}
			onClose={onClose}
			title={filename}
			disableEscapeKeyDown
		>
			<Grid
				container
				direction="column"
				justifyContent="flex-start"
				alignItems="stretch"
				className={classes.container}
			>
				<Document
					file={fileOrBase64}
					onLoadSuccess={onDocumentLoadSuccess}
					renderTextLayer={false}
					loading={<LinearProgress color="primary"/>}
				>
					{CreatePages(config.numPages, config.scale, config.page).map(
						(page, index) => (
							<div key={`pdf-viewer-key-${index}`}>{page}</div>
						)
					)}
				</Document>
			</Grid>

			{!isMobile && (
				<div className={classes.zoom}>
					<ZoomNavigator config={config} setConfig={setConfig}/>
				</div>
			)}
		</FullscreenModalLayout>
	);
};

CreatePages.propTypes = {
	numPages: PropTypes.number.isRequired,
	scale: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired
};

ZoomNavigator.propTypes = {
	setConfig: PropTypes.func.isRequired,
	config: PropTypes.exact({
		fullLoading: PropTypes.bool.isRequired,
		scale: PropTypes.number.isRequired,
		pageNumber: PropTypes.number.isRequired,
		page: PropTypes.number.isRequired,
		minScale: PropTypes.number.isRequired,
		maxScale: PropTypes.number.isRequired,
		scalePoint: PropTypes.number.isRequired,
		numPages: PropTypes.number
	}).isRequired
};

PDFViewer.propTypes = {
	file: PropTypes.string.isRequired,
	filename: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
};

export default memo(PDFViewer);
