import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainLayout from "../components/MainLayout";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PaymentAddressPaper from "../components/PaymentAddressPaper";
import PaymentOrderSummary from "../components/PaymentOrderSummary";
import SecretLink from "../components/SecretLink";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";
import PaymentCardPaper from "../components/PaymentCardPaper";
import { FormProvider, useForm } from "react-hook-form";
import PaymentModal from "../components/PaymentModal";
import { Portal } from "@material-ui/core";
import { useYupValidationResolver } from "../helpers/hooks";
import * as Yup from "yup";
import InformingDialog from "../components/InformingDialog";
import { getPathByName, history } from "../helpers/routing";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "40px",
		display: "flex",
		flexDirection: "column",
		marginBottom: "100px"
	},
	backLink: {
		marginLeft: "auto",
		width: "300px",
		"@media (max-width:599px)": {
			width: "100%"
		}
	},
	backButton: {
		border: "2px #1E243673 solid",
		color: "#1E243673",
		textTransform: "none",
		font: "normal normal bold 18px/20px Calibri",

		margin: "40px 0",
		padding: "8px 10px",
		width: "100%"

	},
	continueShoppingButton: {
		border: "2px #1E243673 solid",
		color: "#1E243673",
		background: "transparent",
		width: "40%",
		margin: "80px 0",
		font: "normal normal bold 18px/20px Calibri",
		textTransform: "none",
		padding: "8px 10px",
		"@media (max-width:599px)": {
			width: "100%"
		},
		"@media (max-width:959px)": {
			margin: "40px 0 -10px"

		}
	}
}));

const validationSchema = Yup.object({
	card_name: Yup.string().required("Lütfen kart sahibinin ismini giriniz."),
	card_date: Yup.string().required("Lütfen kartın son kullanma tarihini giriniz."),
	card_cvv: Yup.string().required("Lütfen güvenlik kodunu giriniz"),
	card_number: Yup.string().required("Lütfen kart numarasını giriniz")
		.min(5, "Lütfen Geçerli bir kart numarası giriniz")
});

const Payment = observer(() => {
	const classes = useStyles();
	const [basket, setBasket] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const resolver = useYupValidationResolver(validationSchema);
	const formMethods = useForm({ resolver });

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;
	const userId = rootStore.auth.userId;
	const userCart = rootStore.cart;
	const itemCountInCart = userCart.itemIds.length;

	const [userHasAddress, setUserHasAddress] = useState(false);
	const [paymentData, setPaymentData] = useState(null);

	const [order, setOrder] = useState(null);
	const [errors, setErrors] = useState({});


	function handleSetOrder (newOrder) {
		if (newOrder) {
			const isSuccess = newOrder.status === 1;

			if (isSuccess) {
				rootStore.auth.getOwnedProducts();
				history.push(getPathByName("payment-success"));
			} else {

			}
		}
		setOrder(newOrder);
	}

	function calculateBasketCost () {
		if (basket.length === 0) return 0;

		function priceReducer (prevValue, currentItem) {
			return prevValue + parseFloat(currentItem.price);
		}

		return basket.reduce(priceReducer, 0);
	}

	const [paymentModalOpen, setPaymentModalOpen] = useState(false);

	function handlePaymentModalClose () {
		setPaymentModalOpen(false);
	}

	async function onSubmit (data) {

		const paymentData = {
			card_name: data.card_name,
			card_number: data.card_number,
			card_date: data.card_date,
			card_cvv: data.card_cvv,
			method: "iyzico"
		};
		setOrder({});

		const response = await api.order.store(paymentData);

		setPaymentData(response.data);

		setPaymentModalOpen(true);
	}

	function onError (error) {
		setErrors(error);
	}

	async function getBasketData () {
		const response = await api.cart.getCart();
		const itemsInBasket = response.data?.items ?? [];
		setBasket(itemsInBasket);
	}

	async function getAddressData () {
		const addressResponse = await api.userAddress.index(userId);
		setUserHasAddress(addressResponse?.data?.length > 0 ?? false);
	}

	useEffect(async () => {
		setIsLoading(true);
		await getBasketData();
		setIsLoading(false);
	}, [itemCountInCart]);

	useEffect(async () => {
		await getAddressData();
	}, []);


	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
				<PaymentModal order={order} open={paymentModalOpen} data={paymentData} onClose={handlePaymentModalClose}
											setOrder={handleSetOrder}/>
			</Portal>
			<FormProvider {...formMethods}>
				<form onSubmit={formMethods.handleSubmit(onSubmit, onError)}>
					<Container className={classes.root}>
						<SecretLink to="/basket" className={classes.backLink}>
							<Button variant="outlined" className={classes.backButton}>{contentStore.getContentValueLang("pages.order.goBackButton")}</Button>
						</SecretLink>
						<Grid container spacing={5}>
							<Grid item md={8} xs={12}>
								{userHasAddress && <PaymentAddressPaper setFormValue={formMethods.setValue}/>}
								<PaymentCardPaper errors={errors}/>
								<SecretLink to="/search">
									<Button variant="outlined" className={classes.continueShoppingButton}>{contentStore.getContentValueLang("pages.cart.continue")}</Button>
								</SecretLink>
							</Grid>
							<Grid item md={4} xs={12}>
								<PaymentOrderSummary basketCost={calculateBasketCost()}/>
							</Grid>
						</Grid>
					</Container>
				</form>
			</FormProvider>


		</MainLayout>
	);
});

export default Payment;
