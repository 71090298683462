import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import image1 from "../resources/home1/image11.jpg";
import image2 from "../resources/home1/image12.jpg";


const data = [
	{
		image: image1,
		title: "Klinik yazılarınız, alanında uzman profesörler",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. lis in posuere nec d ultrices dictum. Sed rhonPellentesque odio sapien, iaculis in posuere nec d ultrices dictum. Sed rhon Sed porta augue sed ultrices dictum. Sed rhoncus"
	}, {
		image: image2,
		title: "Klinik yazılarınız, alanında uzman profesörler",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. lis in posuere nec d ultrices dictum. Sed rhonPellentesque odio sapien, iaculis in posuere nec d ultrices dictum. Sed rhon Sed porta augue sed ultrices dictum. Sed rhoncus"
	}];

const useStyles = makeStyles({
	root: {},
	listItem: {
		padding: "30px 10%",
		"&:nth-of-type(even)": {
			flexDirection: "row-reverse"
		}
	},
	imgGrid: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	imgBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		overflow: "hidden",
		height: 300,
		width: 300,
		margin: 20,
		borderRadius: "50%"
	},
	img: {
		height: "100%",
		width: "auto"
	},
	title: {
		font: "normal normal bold 48px/60px Gilroy",
		width: "60%",
		"@media (max-width:959px)": {
			fontSize: 36,
			width: "100%",
			textAlign: "center"
		}
	},
	body: {
		font: "normal normal normal 20px/32px Gilroy",
		margin: "20px 0",
		"@media (max-width:959px)": {
			fontSize: 16,
			textAlign: "center"
		}
	}
});

export default function FranchiseFeature () {
	const classes = useStyles();
	return (
		<Container disableGutters maxWidth="xl">
			{data.map((datum, index) =>
				<Grid container className={classes.listItem} disableGutters>
					<Grid item xs={12} md={5} lg={4} className={classes.imgGrid}>

						<Box className={classes.imgBox}>
							<img width="auto" className={classes.img} src={datum.image}/>
						</Box>
					</Grid>

					<Grid item xs={12} md={7} lg={8}>
						<Typography className={classes.title}>{datum.title}</Typography>
						<Typography className={classes.body}>{datum.body}</Typography>
					</Grid>
				</Grid>
			)}
		</Container>
	);
}
