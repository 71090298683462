import {ApiBase} from "../api"
import {_} from "../../../App";
import jwtTokenHandler from "../../../helpers/jwtTokenHandler";


export class AuthApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async login(phoneNumber: string, password: string) {

        jwtTokenHandler.clearToken()

        let response = await this.api.apisauce.post(
            "/login",
            {
                "phoneNumber": phoneNumber,
                "password": password
            },
            {
                transformRequest: [(data, headers) => {
                    delete headers.common["Authorization"];
                    return _.isEmpty(data) ? data : JSON.stringify(data);
                }]
            }
        )

        // @ts-ignore
        jwtTokenHandler.setToken(response.data.token);

        return response;
    }

    async register(phoneNumber: string, password: string) {

        let response = await this.api.apisauce.post(
            "/register",
            {phoneNumber, password},
            {
                transformRequest: [(data, headers) => {
                    delete headers.common["Authorization"];
                    return _.isEmpty(data) ? data : JSON.stringify(data);
                }]
            }
        )
        return response;
    }

    async logout() {
        let response = await this.api.apisauce.post(
            "/logout",
        )
        jwtTokenHandler.clearToken()

        return response;
    }
}
