import {ApiBase} from "../api"

export class BranchesApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index   ()  {
        const response = await this.api.apisauce.get("/branches")
        return response
    }
    async branchProducts(userId:string){
        const response = await this.api.apisauce.get(
            "/branches/"+userId+"/products"
        )
        return response
    }
}
