import React, { useState } from "react";
import { Card, CardActionArea, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { useStores } from "../models";
import { asset } from "../config/initialize";
import emptyStar from "../resources/temp/starEmpty.png";
import star from "../resources/temp/star.png";
import SecretLink from "./SecretLink";
import PDFViewer from "./PdfReader/PdfReader";
import { localeDateLong } from "../helpers/date";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
	root: {
		boxShadow: "3px 3px 6px #00000029",
		width: "100%"
	},
	cardActionArea: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	cardMedia: {
		height: 200,
		width: 150
	},
	infoGrid: {
		padding: "30px",
		display: "flex",
		flexDirection: "column",
		"& > p": {
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap"

		}
	},
	productName: {
		font: "normal normal normal 22px/27px Calibri"
	},
	subheading: {
		font: "normal normal normal 18px/22px Calibri"
	},
	divider: {
		height: 1,
		width: 25,
		background: "#2B2B2B",
		margin: "10px 0"
	},
	productDesc: {
		font: "normal normal 300 14px/18px Montserrat",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "initial !important",
		display: "-webkit-box",
		lineClamp: 3,
		boxOrient: "vertical"
	},
	rating: {
		marginTop: "auto"
	},
	buttonGrid: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderLeft: "#E3E3E3 1px dashed"
	},
	button: {
		background: "#1E2436",
		color: "white",
		borderRadius: 7,
		padding: "16px 20px",
		textTransform: "none",
		font: "normal normal normal 17px/21px Calibri",
		"&:hover": {
			background: "#1E2436"
		},
		"&.Mui-disabled": {
			color: "white",
			opacity: .4
		}
	}
}));

function LibraryItem ({ product }) {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	const api = rootStore.environment.api;
	const userId = rootStore.auth.userId;
	const [isPdfReaderOpen, setIsPdfReaderOpen] = useState(false);
	const fileSource = product.file_src;

	function handleTogglePdfReader () {
		setIsPdfReaderOpen(!isPdfReaderOpen);
	}


	async function handleRate (event, value) {
		await api.products.sendRating(userId, product.id, value);
	}


	return (
		<Card className={classes.root}>
			<PDFViewer file={asset(fileSource)} filename={product.name} onClose={handleTogglePdfReader}
								 open={isPdfReaderOpen}/>
			<Grid container>
				<Grid item sm={3} xs={12}>
					<SecretLink to={"/product-detail/" + product.id}>
						<CardActionArea className={classes.cardActionArea}>
							<img className={classes.cardMedia} src={asset(product.image)}/>
						</CardActionArea>
					</SecretLink>
				</Grid>
				<Grid item sm={5} xs={12} className={classes.infoGrid}>

					<Typography className={classes.productName}>{product.id}</Typography>
					<Typography className={classes.subheading}>{product.author}</Typography>
					<Typography className={classes.subheading}>{localeDateLong(product.date)}</Typography>
					<Divider className={classes.divider}/>
					<div dangerouslySetInnerHTML={{ __html: product.shortDescription }}/>
					<Rating
						name={`rating-${product.id}`}
						emptyIcon={<img src={emptyStar}/>}
						icon={<img src={star}/>}
						defaultValue={+product.rating_average}
						onChange={handleRate}
						className={classes.rating}/>
				</Grid>
				<Grid item sm={4} xs={12} className={classes.buttonGrid}>
					<Button onClick={handleTogglePdfReader} className={classes.button} disabled={!fileSource}>
						{contentStore.getContentValueLang("pages.searchPage.read")}
					</Button>
				</Grid>
			</Grid>

		</Card>
	);
}

export default observer(LibraryItem);
