import lodash from "lodash-es";
import deepdash from "deepdash-es";
const _ = deepdash(lodash);

export function stringWrapper(input, settings) {
	const seperator = _.toString(settings.seperator);
	const basePrefix = _.toString(settings.prefix);
	const baseSuffix = _.toString(settings.suffix);

	const hasSeperator = !_.isEmpty(seperator) && _.isString(seperator);
	const hasPrefix = !_.isEmpty(basePrefix) && _.isString(basePrefix);
	const hasSuffix = !_.isEmpty(baseSuffix) && _.isString(baseSuffix);

	const prefix = hasSeperator ? basePrefix + seperator : basePrefix;
	const suffix = hasSeperator ? seperator + baseSuffix : baseSuffix;

	function stringWrapperHandler(inputString) {
		return (hasPrefix ? prefix : "") + inputString + (hasSuffix ? suffix : "");
	}

	if (_.isString(input)) {
		return stringWrapperHandler(input);
	} else if (_.isObjectLike(input)) {
		return _.mapValuesDeep(
			input,
			(baseName) => stringWrapperHandler(baseName),
			{leavesOnly: true}
		);
	} else {
		return input;
	}
}
