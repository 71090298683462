import { Box, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import RegisterFooter from "../components/RegisterFooter";
import AuthorSignUp from "../components/AuthorSignUp";
import { Close } from "@material-ui/icons";
import SecretLink from "../components/SecretLink";
import logoWhite from "../resources/logowhite.png";
import logo from "../resources/logo.png";
import { history } from "../helpers/routing";
import { useStores } from "../models";

const useStyles = makeStyles(theme => ({
	logoGrid: {
		background: "linear-gradient(127deg, #1E2436 0%, #554686 100%) 0% 0% no-repeat padding-box",
		zIndex: 9,
		"@media (max-width: 600px)": {
			background: "#EEEEEE",
			width: "100%"
		}
	},
	footer: {
		marginTop: "-120px"
	},
	page: {
		padding: "140px 10%",
		background: "#EEEEEE",
		"@media (max-width:599px)": {
			padding: "20px 10% 140px"
		}
	},
	logoBox: {
		position: "sticky",
		top: "0",
		color: "white",
		padding: "60px",
		display: "flex",
		flexDirection: "column",
		"@media (max-width:599px)": {
			alignItems: "center",
			flexDirection: "row"
		}


	},
	logoLink: {
		maxWidth: "60%",
		maxHeight: 100,
		"@media (max-width:599px)": {
			margin: "0 auto"
		}
	},
	logoDesktop: {
		maxWidth: "100%",
		maxHeight: 100,
		"@media (max-width:599px)": {
			display: "none"
		}
	},
	logoMobile: {
		maxWidth: "100%",
		maxHeight: 100,
		display: "none",
		"@media (max-width:599px)": {
			display: "block"
		}
	},
	logoBoxBody: {
		padding: "20px 0",
		font: "normal normal 300 30px/48px Calibri",
		"@media (max-width:599px)": {
			display: "none"
		}
	},
	closeButton: {
		display: "none",
		"@media (max-width:600px)": {
			display: "block",
			marginLeft: "auto"
		}
	},
	desc: {
		maxWidth: 375,
		margin: "40px 0",
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	},
	box: {
		display: "flex",
		gap: "20px",
		padding: "20px 0",
		"@media (max-width: 512px)": {
			flexDirection: "column"
		}
	},
	haveAccount: {
		color: "#757575",
		font: "normal normal 300 24px/28px Calibri",
		"@media (max-width:600px)": {
			font: "normal normal 300 18px/20px Calibri"
		}
	},
	signInLink: {
		textDecoration: "none",
		color: "#1E2436",
		font: "normal normal bold 24px/28px Calibri",
		"@media (max-width:600px)": {
			font: "normal normal bold 18px/20px Calibri"
		}
	},
	heading: {
		font: "normal normal bold 40px/48px Calibri",
		"@media (max-width:600px)": {
			font: "normal normal bold 30px/40px Calibri"
		}
	},
	descHeading: {
		font: "normal normal bold 28px/38px Calibri",
		"@media (max-width:600px)": {
			fontSize: 20
		}
	},
	descBody: {
		font: "normal normal 300 24px/28px Calibri",
		"@media (max-width:600px)": {
			fontSize: 18
		}
	},
	copyright: {
		color: "white",
		"@media (max-width:959px)": {
			color: "black"
		}
	}
}));
const AuthorRegister = () => {
	const classes = useStyles();
	const rootStore = useStores()
	const contentStore = rootStore.contentStore

	function handleGoBack () {
		history.back();
	}

	return (
		<Grid container>
			<Grid item sm={12} md={3} className={classes.logoGrid}>
				<Box className={classes.logoBox}>
					<SecretLink to="/" showPointer className={classes.logoLink}>
						<img src={logoWhite} alt="" className={classes.logoDesktop}/>
						<img src={logo} alt="" className={classes.logoMobile}/>
					</SecretLink>
					<Typography className={classes.logoBoxBody}>{contentStore.getContentValueLang("pages.author.slogan")}</Typography>
					<IconButton className={classes.closeButton} onClick={handleGoBack}><Close/></IconButton>
				</Box>
			</Grid>
			<Grid item sm={12} md={9} className={classes.page}>
				<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.author.title")}</Typography>
				<Box className={classes.desc}>
					<Typography className={classes.descHeading}>{contentStore.getContentValueLang("pages.author.header")}</Typography>
					<Typography className={classes.descBody}>{contentStore.getContentValueLang("pages.author.header.description")}</Typography>
				</Box>
				<AuthorSignUp/>
				<Box className={classes.box}>
					<Box display="flex" gridGap="10px" alignItems="center">
						<Typography className={classes.haveAccount}>{contentStore.getContentValueLang("pages.author.alreadySigned")}</Typography>
						<SecretLink to="/login" className={classes.signInLink} showPointer>{contentStore.getContentValueLang("pages.author.signIn")}</SecretLink>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} className={classes.footer}>
				<RegisterFooter copyrightClassName={classes.copyright}/>
			</Grid>
		</Grid>
	);
};

export default AuthorRegister;
