import React, { useState } from "react";
import { Button, Card, Checkbox, FormControlLabel, makeStyles, Portal, Typography } from "@material-ui/core";
import * as Yup from "yup";
import TextField from "./CustomTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneInput from "./PhoneInput";
import { useYupValidationResolver } from "../helpers/hooks";
import { history } from "../helpers/routing";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";
import InformingDialog from "./InformingDialog";

const validationSchema = Yup.object({
	phoneNumber: Yup.string("")
		.matches(/\d{2}\s\d{3}\s\d{3}\s\d{2}\s\d{2}/g, "Lütfen geçerli bir telefon numarası giriniz")
		.required("Lütfen geçerli bir telefon numarası giriniz"),
	password: Yup.string()
		.required("Lütfen bir şifre giriniz")
		.matches(/^(?=.*?[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Lütfen en az bir küçük harf, bir büyük harf, bir rakam ve en az 8 karakter içeren bir şifre giriniz."),
	passwordConfirm: Yup.string()
		.oneOf([Yup.ref("password"), null], "Şifreler eşleşmiyor")
		.required("Lütfen şifrenizi doğrulayın."),
	acceptTerms: Yup.bool().oneOf([true], "Lütfen kullanım koşullarını kabul edin")

});

const useStyles = makeStyles((theme) => ({
	formCard: {
		width: "550px",
		padding: "100px 50px",
		borderRadius: "0",
		font: "Calibri",
		boxShadow: " 3px 3px 20px #00000024",
		"@media (max-width:600px)": {
			width: "90%"
		}
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "24px",
		margin: "36px 0"
	},
	formButton: {
		width: "fit-content",
		borderRadius: 6,
		alignSelf: "center",
		background: "linear-gradient(109deg, rgba(30,36,54,1) 0%, rgba(85,70,134,1) 100%)",
		color: "white",
		padding: "14px 24px"
	},
	formButtonText: {
		font: " normal normal 300 20px/28px Calibri",
		textTransform: "none"
	},
	checkboxLabel: {
		font: " normal normal 300 16px/28px Calibri"
	},
	cardHeading: {
		font: "normal normal 300 40px/48px Calibri"
	}


}));

const SignUpForm = observer(() => {
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const resolver = useYupValidationResolver(validationSchema);
	const { register, handleSubmit, control } = useForm({ resolver });
	const classes = useStyles();
	const rootStore = useStores();

	const api = rootStore.environment.api;
	const contentStore = rootStore.contentStore;
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	async function onSubmit (data) {

		setIsLoading(true);

		const response = await rootStore.auth.register(data.phoneNumber, data.password);

		setIsLoading(false);

		if (response.status === 201) {
			const userData = response.data.user;
			userData.password = "";
			userData.savePassword = false;
			rootStore.auth.setUser(userData);
			history.push("/onboarding");
		} else if (response.status === 500) {
			const errors = {
				phoneNumber: {
					message: "Lütfen telefon numarasının size ait olduğundan emin olun."
				},
				password: {
					message: ""
				},
				passwordConfirm: {
					message: ""
				}
			};
			setErrors(errors);
		}
	}

	function onError (errors) {
		setErrors(errors);
	}

	return (
		<Card className={classes.formCard}>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Typography
				className={classes.cardHeading}>{contentStore.getContentValueLang("pages.signUp.pageTitle")}</Typography>
			<form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
				<PhoneInput control={control} label={contentStore.getContentValueLang("pages.signUp.placeholder.phoneNumber")}
										error={!!errors?.phoneNumber} helperText={errors?.phoneNumber?.message ?? ""}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIcon/>
												</InputAdornment>
											)

										}}/>
				<TextField label={contentStore.getContentValueLang("pages.signUp.placeholder.password")} variant="outlined"
									 name="password"
									 error={!!errors?.password} helperText={errors?.password?.message ?? ""}
									 type={showPassword ? "text" : "password"} {...register("password")}
									 inputProps={{ maxLength: 16 }}
									 InputProps={{
										 endAdornment: (
											 <InputAdornment position="end">
												 <IconButton onClick={() => setShowPassword(pre => !pre)}>
													 {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
												 </IconButton>
											 </InputAdornment>
										 )
									 }}/>
				<TextField label={contentStore.getContentValueLang("pages.signUp.placeholder.confirmPassword")}
									 variant="outlined" name="passwordConfirm"
									 error={!!errors?.passwordConfirm} helperText={errors?.passwordConfirm?.message ?? ""}
									 type={showPasswordConfirm ? "text" : "password"} {...register("passwordConfirm")}
									 inputProps={{ maxLength: 16 }}
									 InputProps={{
										 endAdornment: (
											 <InputAdornment position="end">
												 <IconButton onClick={() => setShowPasswordConfirm(pre => !pre)}>
													 {showPasswordConfirm ? <VisibilityIcon/> : <VisibilityOffIcon/>}
												 </IconButton>
											 </InputAdornment>
										 )
									 }}/>

				<FormControlLabel
					control={
						<Checkbox type="checkbox" required name="acceptTerms" {...register("acceptTerms")}/>
					}
					label={
						<Typography
							className={classes.checkboxLabel}>{contentStore.getContentValueLang("pages.signUp.agreeTermsText")}</Typography>
					}/>


				<Button className={classes.formButton} type="submit">
					<Typography
						className={classes.formButtonText}>{contentStore.getContentValueLang("pages.signUp.createAnAccountButton")}</Typography>
				</Button>
			</form>
		</Card>

	);
});

export default SignUpForm;
