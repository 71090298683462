import {flow, getParent, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";
import {history} from "../../helpers/routing";
import {RootStore} from "../root-store/root-store";

/**
 * Register model.
 */
export const RegisterModel = types.model({
    firstName: types.maybe(types.string),
    lastName: types.maybe(types.string),
    type: types.maybe(types.string),
    branchId: types.maybe(types.string),
    organizationId: types.maybe(types.string),
}).extend(withEnvironment)
    .actions(self => ({
        setName: function (firstName: string, lastName: string, type: string) {
            self.firstName = firstName
            self.lastName = lastName
            self.type = type
        },
        setBranch: flow(function* (branchId: string, organizationId: string) {
            self.branchId = branchId
            self.organizationId = organizationId

            const rootStore: RootStore = getParent(self);
            const user = rootStore.auth

            const userUpdate = {
                branch_id: self.branchId,
                organisation_id: self.organizationId,
                type: self.type,
                firstName: self.firstName,
                lastName: self.lastName
            }

            yield user.update(userUpdate)
            const isUserOnboarded: Boolean = user.isOnboarded
            return history.push(isUserOnboarded ? "/" : "/onboarding")
        })
    }))

type RegisterType = Instance<typeof RegisterModel>

export interface Register extends RegisterType {
}

type RegisterSnapshotType = SnapshotOut<typeof RegisterModel>

export interface RegisterSnapshot extends RegisterSnapshotType {
}

export const createRegisterDefaultModel = () => types.optional(RegisterModel, {})
