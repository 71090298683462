import {ApiBase} from "../api"

export class CustomizationsApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async sliders   ()  {
        const response = await this.api.apisauce.get(
            "/homepage_sliders"
        )
        return response
    }

    async homePageObject  ()  {
        const response = await this.api.apisauce.get(
            "/homepage_objects"
        )
        return response
    }

    async cms(){
        const response = await this.api.apisauce.get(
            "/cms/index"
        )
        return response
    }

}
