import React from "react";
import { Box, Card, CardActionArea, Container, makeStyles, Typography } from "@material-ui/core";
import SecretLink from "./SecretLink";
import { makeQueryRoute } from "../helpers/routing";
import { asset } from "../config/initialize";
import ResponsiveCarousel from "./ResponsiveCarousel";

const useStyles = makeStyles({
	root: {
		padding: "10%"
	},
	card: {
		margin: "5%",
		boxShadow: "1px 3px 6px #0000004D",
		borderRadius: 16
	},
	cardAction: {
		padding: 24,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"

	},
	img: {
		margin: "auto",
		height: 280,
		width: 210
	},
	highestRating: {
		font: "normal normal bold 28px/20px Calibri",
		textAlign: "center",
		marginBottom: 40

	},
	category: {
		font: "normal normal bold 24px/20px Calibri",
		margin: "30px 0 10px"
	},
	title: {
		font: "normal normal normal 20px/20px Calibri"
	},
	icon: {
		transform: "translateY(4px)"
	},
	sectionTitle: {
		font: "normal normal bold 44px/40px Calibri",
		marginLeft: "5%"
	},
	seeAll: {
		font: "normal normal bold 20px/20px Calibri",
		margin: "20px 5%"
	}
});

export default function HomePageProductSection ({ title, items = [], query = {}, count = 0, seeAllText = "" }) {
	const classes = useStyles();
	const showAllQuery = makeQueryRoute("/search", query);

	return (
		<Container maxWidth="xl" disableGutters className={classes.root}>
			<Typography className={classes.sectionTitle}>{title}</Typography>

			<Typography align={"right"} className={classes.seeAll}>
				<SecretLink showPointer to={showAllQuery}>
					{seeAllText} ({count || 0})
				</SecretLink>
			</Typography>


			<ResponsiveCarousel responsivePattern={responsive} rows={2}>
				{
					items.map((item) => (
						<Box key={item.id}>
							<Card className={classes.card}>
								<SecretLink to={"/product-detail/" + item.id}>

									<CardActionArea className={classes.cardAction}>

										<img
											loading="lazy"
											className={classes.img}
											src={asset(item.image)}
											alt=""/>
										<Typography className={classes.category}>{item.mainCategory.name}</Typography>
										<Typography className={classes.title}>{item.name}</Typography>
									</CardActionArea>
								</SecretLink>
							</Card>
						</Box>
					))
				}

			</ResponsiveCarousel>


		</Container>
	);
}


const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 1500 },
		items: 4
	},
	desktop: {
		breakpoint: { max: 1500, min: 1200 },
		items: 3
	},
	tablet: {
		breakpoint: { max: 1200, min: 700 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 700, min: 0 },
		items: 1
	}
};
