import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "../components/CustomTextField";
import { useFormContext } from "react-hook-form";
import DebitCardInput from "./DebitCardInput";
import CardCvvInput from "./CardCvvInput";
import CardDateInput from "./CardDateInput";
import { useStores } from "../models";

const useStyles = makeStyles(theme => ({
	root: {
		padding: "10%",
		border: "1px solid #E3E3E3",
		boxShadow: "3px 3px 6px #00000029"
	},
	heading: {
		font: "normal normal bold 36px/40px Calibri",
		marginBottom: "36px"
	}
}));

function PaymentCardPaper (props) {
	const { errors } = props;
	const classes = useStyles();
	const { register, control } = useFormContext();
	const rootStore = useStores()
	const contentStore = rootStore.contentStore

	return (
		<Paper className={classes.root}>
			<Typography className={classes.heading}>{contentStore.getContentValueLang("pages.order.cardInformation")}</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DebitCardInput label={contentStore.getContentValueLang("pages.order.cardNumber")} control={control} error={!!errors?.card_number}
													helperText={errors?.card_number?.message ?? ""}/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField fullWidth autoComplete="off" {...register("card_name")} variant={"outlined"}
										 error={!!errors?.card_name} helperText={errors?.card_name?.message ?? ""}
										 label={contentStore.getContentValueLang("pages.order.cardName")}/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<CardCvvInput label={contentStore.getContentValueLang("pages.order.cardCvv")} control={control} error={!!errors?.card_cvv} helperText={errors?.card_cvv?.message ?? ""}/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<CardDateInput label={contentStore.getContentValueLang("pages.order.cardExpirationDate")} control={control} error={!!errors?.card_date} helperText={errors?.card_date?.message ?? ""}/>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default PaymentCardPaper;
