import React, { useEffect, useState } from "react";
import Layout from "../components/MainLayout";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { Rating } from "@material-ui/lab";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YourSpecial from "../components/YourSpecial";
import { localeDateLong } from "../helpers/date";
import { useStores } from "../models";
import { asset } from "../config/initialize";
import emptyStar from "../resources/temp/starEmpty.png";
import star from "../resources/temp/star.png";
import { history } from "../helpers/routing";
import bag from "../resources/temp/bag.png";
import { Portal } from "@material-ui/core";
import InformingDialog from "../components/InformingDialog";
import { observer } from "mobx-react-lite";
import PDFViewer from "../components/PdfReader/PdfReader";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "100px 0"
	},
	paper: {
		padding: "40px"
	},
	imageGrid: {
		display: "flex",
		flexDirection: "column",
		margin: 20
	},
	imageBox: {
		padding: "auto",
		margin: "30px ",
		alignSelf: "center"
	},
	image: {
		width: 360,
		height: 480,
		"@media (max-width:599px)": {
			width: 300,
			height: 400
		}
	},
	infoGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	infoGridTop: {
		width: "75%"
	},
	prodName: {
		font: "normal normal bold 24px/22px Calibri",
		borderBottom: "1px solid #22263B",
		paddingBottom: 12,
		marginBottom: 16
	},


	infoGridBottom: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "50%"
	},
	author: {
		font: "normal normal bold 18px/22px Calibri",
		color: "#2B2B2B",
		margin: "9px 0"
	},
	authorNameBox: {
		paddingRight: "16px",
		"& p": {
			font: "normal normal normal 18px/22px Calibri",
			color: "#2B2B2B"
		},
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "40px"
	},
	price: {
		font: "normal normal bold 24px/29px Calibri"
	},
	sectionTitle: {
		"font": "normal normal bold 20px/22px Calibri",
		"color": "#2B2B2B",
		margin: "10px 0"
	},
	sectionDesc: {
		"font": "normal normal normal 18px/22px Calibri",
		"color": "#1E2436",
		margin: "8px 0",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "initial !important",
		display: "-webkit-box",
		lineClamp: 4,
		boxOrient: "vertical"
	},
	smallImgs: {
		display: "flex",
		gap: 8
	},
	smallImg: {
		cursor: "pointer",
		width: 150,
		height: 200,
		"@media (max-width:599px)": {
			width: 120,
			height: 160
		}
	},
	divider: {
		margin: "16px 0",
		width: "100%"
	},
	cartButton: {
		marginTop: "16px",
		background: "linear-gradient(120deg, #1E2436 0%, #554686 100%)",
		borderRadius: "7px",
		font: "normal normal normal 17px/21px Calibri",
		color: "#FFFFFF",
		"&.Mui-disabled": {
			color: "white",
			opacity: .4
		}
	},
	shoppingBag: {
		marginRight: 8
	},
	moreInfo: {
		font: "italic normal 300 14px/22px Calibri",
		margin: "16px auto 0px"
	},
	socialBox: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		gap: "8px"
	}
}));

const ProductDetail = (props) => {
	const rootStore = useStores();
	const api = rootStore.environment.api;
	const contentStore = rootStore.contentStore
	const cart = rootStore.cart;
	const ownedProducts = rootStore.auth.ownedProducts;

	const productId = props.match.params.id;

	const isOwned = ownedProducts.includes(productId);
	const isInCart = cart.hasItem(productId);

	const classes = useStyles();
	const [activeImage, setActiveImage] = useState({});
	const [product, setProduct] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);

	function handleTogglePdfViewer () {
		setIsPdfViewerOpen(!isPdfViewerOpen);
	}

	async function handleProductAction () {
		if (isOwned) {
			handleTogglePdfViewer();
		} else {
			setIsLoading(true);
			if (isInCart) {
				await cart.deleteItem(productId);
			} else {
				await cart.addItem(productId);
			}
			setIsLoading(false);
		}
	}

	useEffect(async () => {
		setIsLoading(true);
		const response = await api.products.show(productId);

		if (response.status === 404) {
			history.push("/");
			return;
		}
		setProduct(response.data);
		if (response.data.photos.length > 0) setActiveImage(response.data.photos[0]);
		setIsLoading(false);
	}, []);
	return (
		<Layout>
			<Portal>
				<InformingDialog open={isLoading}/>
				{!!product?.file_src &&
				<PDFViewer file={asset(product.file_src)} filename={product.name ?? "Yükleniyor"}
									 onClose={handleTogglePdfViewer}
									 open={isPdfViewerOpen}/>}
			</Portal>
			<Container className={classes.root}>
				<Paper className={classes.paper}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} md={5} className={classes.imageGrid}>
							<Box className={classes.imageBox}>
								<img className={classes.image} src={asset(activeImage?.photo ?? "")}/>
							</Box>
							<Typography className={classes.author}>{contentStore.getContentValueLang("pages.productPage.author")}</Typography>
							<Box className={classes.authorNameBox}>
								<Typography>{product.author}</Typography>
								<Rating
									emptyIcon={<img src={emptyStar}/>}
									icon={<img src={star}/>}
									value={product.rating_average ?? 5} readOnly/>
							</Box>
							<Box className={classes.smallImgs}>
								{product?.photos?.map((photo) => <img src={asset(photo.photo)} className={classes.smallImg}
																											alt=""
																											key={photo.id}
																											onClick={() => setActiveImage(photo)}/>) ?? null}
							</Box>
						</Grid>
						<Grid item xs={12} md={6} className={classes.infoGrid}>
							<Box className={classes.infoGridTop}>
								<Typography className={classes.prodName}>{product.name}</Typography>
								<Typography className={classes.sectionTitle}>Price: <span
									className={classes.price}>${product.price}</span></Typography>

								<Typography className={classes.sectionTitle}>{contentStore.getContentValueLang("pages.productPage.topic")}</Typography>
								<div dangerouslySetInnerHTML={{ __html: product.shortDescription }}/>

								<Divider className={classes.divider}/>

								<Typography className={classes.sectionTitle}>{contentStore.getContentValueLang("pages.productPage.description")}</Typography>
								{/*<Typography className={classes.sectionDesc}>{}</Typography>*/}
								<div dangerouslySetInnerHTML={{ __html: product.longDescription }}/>


								<Divider className={classes.divider}/>

								<Typography className={classes.sectionTitle}>{contentStore.getContentValueLang("pages.productPage.date")}</Typography>
								<Typography className={classes.sectionDesc}>{localeDateLong(product.date)}</Typography>

								<Divider className={classes.divider}/>

								<Typography className={classes.sectionTitle}>{contentStore.getContentValueLang("pages.productPage.pageNumber")}</Typography>
								<Typography className={classes.sectionDesc}>{product.pageNumber}</Typography>

								<Divider className={classes.divider}/>

								<Typography className={classes.sectionTitle}>{contentStore.getContentValueLang("pages.productPage.publisher")}</Typography>
								<Typography className={classes.sectionDesc}>{product.publisher}</Typography>
							</Box>
							<Box className={classes.infoGridBottom}>
								<Button size="large" className={classes.cartButton} onClick={handleProductAction}
												disabled={isOwned && !product?.file_src} variant="contained">
									{isOwned ?
										<>
											<Typography>{contentStore.getContentValueLang("pages.productPage.read")}</Typography>
										</>
										:
										(isInCart ?
											<>
												<img src={bag} className={classes.shoppingBag}/>
												<Typography>{contentStore.getContentValueLang("pages.productPage.alreadyInCart")}</Typography>
											</>
											: <>
												<img src={bag} className={classes.shoppingBag}/>
												<Typography>{contentStore.getContentValueLang("pages.productPage.addToCart")}</Typography>
											</>)
									}
								</Button>
								<Typography className={classes.moreInfo}>{contentStore.getContentValueLang("pages.productPage.toLearnMore")}</Typography>
								<Divider variant="fullWidth" className={classes.divider}/>
								<Box className={classes.socialBox}>
									<LinkedInIcon/>
									<FacebookIcon/>
									<TwitterIcon/>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Paper>
				<YourSpecial/>

			</Container>

		</Layout>
	);
};

export default observer(ProductDetail);
