import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Button, Checkbox, Container, FormControlLabel, makeStyles, Portal, Typography } from "@material-ui/core";
import TextField from "./CustomTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { DropzoneArea } from "material-ui-dropzone";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useForm } from "react-hook-form";
import { useStores } from "../models";
import { useYupValidationResolver } from "../helpers/hooks";
import PhoneInput from "./PhoneInput";
import InformingDialog, { AlertDialog } from "./InformingDialog";
import { history } from "../helpers/routing";
import { preparePhoneNumber } from "../helpers/phoneNumberHelper";
import { observer } from "mobx-react-lite";

const validationSchema = Yup.object({
	firstName: Yup.string()
		.required("Lütfen isminizi giriniz"),
	lastName: Yup.string()
		.required("Lütfen soyisminizi giriniz"),
	phoneNumber: Yup.string("")
		.matches(/\d{2}\s\d{3}\s\d{3}\s\d{2}\s\d{2}/g, "Lütfen geçerli bir telefon numarası giriniz")
		.required("Lütfen geçerli bir telefon numarası giriniz"),
	email: Yup.string()
		.email("Lütfen geçerli bir e-posta adresi giriniz")
		.required("Lütfen bir e-posta adresi giriniz"),
	password: Yup.string()
		.required("Lütfen bir şifre giriniz")
		.matches(/^(?=.*?[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Lütfen en az bir küçük harf, bir büyük harf, bir rakam ve en az 8 karakter içeren bir şifre giriniz."),
	passwordConfirm: Yup.string()
		.oneOf([Yup.ref("password"), null], "Şifreler eşleşmiyor").required("Lütfen şifrenizi doğrulayın"),
	workInformation: Yup.string().required("Lütfen iş bilginizi giriniz"),

	resume: Yup.string().required("Lütfen CV namına bir şeyler karalayınız."),

	acceptTerms: Yup.bool().oneOf([true], "Lütfen kullanım koşullarını kabul edin.")

});

const useStyles = makeStyles(theme => ({
	formBox: {
		display: "flex",
		gap: "20px",
		margin: "20px 0",
		"@media (max-width:500px)": {
			flexDirection: "column"
		}
	},
	textArea: {
		margin: "20px 0"
	},
	submitButton: {
		borderRadius: "7px",
		padding: "8px 30px",
		margin: "30px 0",
		background: "linear-gradient(127deg, #1E2436 0%, #554686 100%)",
		color: "white",
		textTransform: "none",
		font: "normal normal 300 20px/28px Calibri",
		"@media (max-width:600px)": {
			font: "normal normal 300 14px/20px Calibri"
		}
	},
	step: {
		font: "normal normal bold 32px/54px Calibri",
		"@media (max-width:600px)": {
			font: "normal normal bold 24px/54px Calibri"
		}
	},
	stepBody: {
		font: "normal normal normal 24px/54px Calibri"
		,
		"@media (max-width:600px)": {
			font: "normal normal normal 12px/54px Calibri"
		}
	},
	checkbox: {
		font: "normal normal 300 16px/28px Calibri",
		"@media (max-width:600px)": {
			font: "normal normal 300 12px/20px Calibri"
		}
	},
	uploadCont: {
		maxWidth: 350,
		display: "flex",
		flexDirection: "column"
	},
	dropbox: {
		background: "#EEE",
		border: "1px dashed #000000",
		display: "flex",
		margin: "20px 0",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& .MuiDropzoneArea-textContainer": {
			display: "flex",
			flexDirection: "column-reverse",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			"& svg": {
				color: "#A8A8A8"
			}
		}

	},
	dropboxText: {
		color: "#A8A8A8"
	}
}));


const SignUpForm = () => {
	const classes = useStyles();

	const rootStore = useStores();
	const api = rootStore.environment.api;
	const contentStore = rootStore.contentStore;
	const user = rootStore.auth;


	const defaultValues = {
		firstName: user.firstName,
		lastName: user.lastName,
		phoneNumber: preparePhoneNumber(user.phoneNumber),
		email: user.email,
		password: user.password,
		passwordConfirm: user.password
	};

	const resolver = useYupValidationResolver(validationSchema);
	const { register, setValue, control, handleSubmit } = useForm({ resolver, defaultValues });

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	const [referenceFile, setReferenceFile] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [errors, setErrors] = useState({});

	function handleUpdate (files) {
		setReferenceFile(files[0]);
	}

	function afterTimeout () {
		setIsAlertOpen(false);
		history.push("/");
	}

	async function onSubmit (data) {

		const formData = new FormData();
		formData.append("firstName", data.firstName);
		formData.append("lastName", data.lastName);
		formData.append("phoneNumber", data.phoneNumber);
		formData.append("email", data.email);
		formData.append("password", data.password);
		formData.append("workInformation", data.resume);
		formData.append("resume", data.firstName);
		formData.append("uploadedFile", referenceFile);
		formData.append("contactPermission_email", data.sendMail ? "on" : "off");
		setIsLoading(true);
		const response = await api.authorRegister.authorRegister(formData);
		setIsLoading(false);
		setIsAlertOpen(true);

	}

	function onError (errors) {
		setErrors(errors);
	}


	return (

		<Container disableGutters>
			<Portal>
				<InformingDialog open={isLoading}/>
				<AlertDialog open={isAlertOpen} text={"Başvurunuz alınmıştır"} afterTimeout={afterTimeout} timeout={1500}/>
			</Portal>

			<form onSubmit={handleSubmit(onSubmit, onError)}>

				<Typography className={classes.stepBody}><span className={classes.step}>{contentStore.getContentValueLang("pages.author.step1.title")}</span> - {contentStore.getContentValueLang("pages.author.step1.description")}</Typography>

				<Box className={classes.formBox}>
					<TextField variant="outlined" label={contentStore.getContentValueLang("pages.author.placeholder.firstName")} name="firstname" error={!!errors?.firstName}
										 helperText={errors?.firstName?.message ?? ""} fullWidth {...register("firstName")}/>


					<TextField variant="outlined" label={contentStore.getContentValueLang("pages.author.placeholder.lastName")} name="lastname" error={!!errors?.lastName}
										 helperText={errors?.lastName?.message ?? ""} fullWidth {...register("lastName")}/>
				</Box>

				<Box className={classes.formBox}>
					<PhoneInput control={control} fullWidth label={contentStore.getContentValueLang("pages.author.placeholder.phoneNumber")} error={!!errors?.phoneNumber}
											helperText={errors?.phoneNumber?.message ?? ""}/>
					<TextField variant="outlined" label={contentStore.getContentValueLang("pages.author.placeholder.email")} name="email" error={!!errors?.email}
										 helperText={errors?.address?.email ?? ""} type="email" fullWidth {...register("email")}/>
				</Box>

				<Box className={classes.formBox}>
					<TextField variant="outlined" error={!!errors?.password} helperText={errors?.password?.message ?? ""}
										 label={contentStore.getContentValueLang("pages.author.placeholder.password")} name="password" type={showPassword ? "text" : "password"}
										 InputProps={{
											 endAdornment: (
												 <InputAdornment position="end">
													 <IconButton onClick={() => setShowPassword(pre => !pre)}>
														 {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
													 </IconButton>
												 </InputAdornment>
											 )
										 }} fullWidth
										 {...register("password")}/>
					<TextField variant="outlined" label={contentStore.getContentValueLang("pages.author.placeholder.confirmPassword")} error={!!errors?.passwordConfirm}
										 helperText={errors?.passwordConfirm?.message ?? ""} name="passwordConfirm"
										 type={showPasswordConfirm ? "text" : "password"}
										 InputProps={{
											 endAdornment: (
												 <InputAdornment position="end">
													 <IconButton onClick={() => setShowPasswordConfirm(pre => !pre)}>
														 {showPasswordConfirm ? <VisibilityIcon/> : <VisibilityOffIcon/>}
													 </IconButton>
												 </InputAdornment>
											 )
										 }} fullWidth
										 {...register("passwordConfirm")}/>
				</Box>

				<Typography className={classes.stepBody}><span className={classes.step}>{contentStore.getContentValueLang("pages.author.step2.title")}</span>- {contentStore.getContentValueLang("pages.author.step2.description")}</Typography>


				<TextField label={contentStore.getContentValueLang("pages.author.placeholder.workInformation")} variant="outlined" name="workInfo" type="text" row="4" minLength="10"
									 error={!!errors?.workInformation} helperText={errors?.workInformation?.message ?? ""}
									 multiline fullWidth rows={5} className={classes.textArea}  {...register("workInformation")}/>
				<TextField label={contentStore.getContentValueLang("pages.author.placeholder.yourResumes")} variant="outlined" name="yourResume" type="text" minLength="10" multiline
									 error={!!errors?.resume} helperText={errors?.resume?.message ?? ""}
									 fullWidth rows={5} className={classes.textArea}  {...register("resume")}/>

				<Typography className={classes.stepBody}><span className={classes.step}>{contentStore.getContentValueLang("pages.author.step3.title")}</span>- {contentStore.getContentValueLang("pages.author.step3.description")}</Typography>

				<Box className={classes.uploadCont}>
					<DropzoneArea dropzoneClass={classes.dropbox} Icon={InsertDriveFileIcon}
												useChipsForPreview
												filesLimit={1}
												acceptedFiles={["application/pdf"]}
												dropzoneParagraphClass={classes.dropboxText}
												dropzoneText={contentStore.getContentValueLang("pages.author.placeholder.uploadYourReferences")}
												onChange={handleUpdate}/>
				</Box>


				<Box display="flex" flexDirection="column">
					<FormControlLabel
						control={<Checkbox required type="checkbox" name="acceptTerms"  {...register("acceptTerms")}/>}
						label={<Typography className={classes.checkbox}>{contentStore.getContentValueLang("pages.author.termsOfService")}</Typography>}/>
					<FormControlLabel control={<Checkbox type="checkbox" name="sendMail"  {...register("sendMail")}/>}
														label={<Typography className={classes.checkbox}>{contentStore.getContentValueLang("pages.author.receiveEmails")}</Typography>}/>
				</Box>


				<Button type="submit" className={classes.submitButton}>{contentStore.getContentValueLang("pages.author.registerButton")}</Button>
			</form>
		</Container>

	);
};

export default observer(SignUpForm);
