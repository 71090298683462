import {applySnapshot, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../index"
import {createUserDefaultModel} from "../user/user";
import {createCartDefaultModel} from "../cart/cart";
import {createRegisterDefaultModel} from "../register/register";
import {createCategoryStoreDefaultModel} from "../category-store/category-store";
import {toJS} from "mobx";
import {getPathByName, history} from "../../helpers/routing";
import {createContentStoreDefaultModel} from "../contentStore/contentStore";

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
    auth: createUserDefaultModel(),
    cart: createCartDefaultModel(),
    register: createRegisterDefaultModel(),
    categoryStore: createCategoryStoreDefaultModel(),
    contentStore: createContentStoreDefaultModel()
}).extend(withEnvironment)
    .actions((self) => ({
        logout: function () {
            applySnapshot(self, {
                categoryStore: toJS(self.categoryStore),
                auth: {
                    phoneNumber: self.auth?.phoneNumber,
                    password: self.auth?.password,
                    savePassword: self.auth?.savePassword
                },
                contentStore: toJS(self.contentStore)
            })
            logoutRedirect()
        }
    }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {
}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {
}

function logoutRedirect() {

    const location = history.location;
    if (["/login", "/signup"].includes(location.pathname)) return
    // @ts-ignore
    const {from} = location.state || {from: {pathname: getPathByName("home")}};
    history.replace(from);
}
