import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { Button, CardContent, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SecretLink from "./SecretLink";
import { localeDateLong } from "../helpers/date";
import { asset } from "../config/initialize";
import { getContent } from "../helpers/productsContentHandler";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles(theme => ({
	root: {
		border: "1px solid #B8B8B8",
		boxShadow: "3px 3px 6px #00000029",
		width: "100%"
	},
	cardHeader: {
		background: "#E3E3E3",
		borderBottom: "1px solid #B8B8B8",
		display: "flex",
		padding: "20px 30px"
	},
	cardHeaderLabels: {
		font: "normal normal bold 24px/24px Calibri"
	},
	cardHeaderInfos: {
		font: "normal normal normal 19px/24px Calibri",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	cardHeaderSection: {
		padding: 8
	},
	cardHeaderButtonBox: {
		marginLeft: "auto"
	},
	moreInfoButton: {
		marginLeft: "auto",
		background: "linear-gradient(105deg, #1E2436 0%, #554686 100%)",
		color: "white",
		width: "100%",
		padding: "10px 36px",
		borderRadius: "7px",
		textTransform: "none",
		font: "normal normal 300 20px/28px Calibri",
		"@media (max-width:599px)": {
			marginTop: "8px"
		}
	},
	productGrid:{
		margin:"18px 0",
	},
	imageGrid: {
		padding: "16px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	descGrid: {
		display: "flex",
		flexDirection: "column",
		gap: 10,
		padding: "16px 0"
	},
	infoIconGrid: {
		display: "flex",
		justifyContent: "flex-end"
	},
	productName: {
		font: "normal normal normal 22px/27px Calibri"
	},
	productInfo: {
		font: "normal normal normal 18px/20px Calibri",
		margin: 0
	},
	divider: {
		width: 30
	},
	productDesc: {
		font: "normal normal 300 14px/18px Montserrat",
		textOverflow: "ellipsis",
		overflow: "hidden",
		display: "-webkit-box",
		lineClamp: 4,
		boxOrient: "vertical"
	}


}));
const OrderCard = (props) => {
	const rootStore = useStores()
	const contentStore = rootStore.contentStore
	const { items } = props;
	const classes = useStyles();
	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardHeader}>
				<Grid container>

					<Grid item xs={4} md={"auto"} className={classes.cardHeaderSection}>
						<Typography className={classes.cardHeaderLabels}>{contentStore.getContentValueLang("pages.order.date")}</Typography>
						<Typography className={classes.cardHeaderInfos}>{localeDateLong(props.created_at)}</Typography>
					</Grid>
					<Grid item xs={4} md={"auto"} className={classes.cardHeaderSection}>
						<Typography className={classes.cardHeaderLabels}>{contentStore.getContentValueLang("pages.order.orderSummary")}</Typography>
						<Typography className={classes.cardHeaderInfos}>{getContent(items)}</Typography>
					</Grid>
					<Grid item xs={4} md={"auto"} className={classes.cardHeaderSection}>
						<Typography className={classes.cardHeaderLabels}>{contentStore.getContentValueLang("pages.order.amount")}</Typography>
						<Typography className={classes.cardHeaderInfos}>{props.totalAmount} TL</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={"auto"} className={classes.cardHeaderButtonBox}>
						<SecretLink to={"/order-detail/" + props.id}><Button className={classes.moreInfoButton}>{contentStore.getContentValueLang("pages.order.orderDetails")}</Button></SecretLink>
					</Grid>

				</Grid>

			</CardContent>

			<CardContent>
				{items.map((item) => (
					<Grid container key={item.id} className={classes.productGrid}>
						<Grid item xs={12} sm={3} className={classes.imageGrid}>
							<img src={asset(item.image)} width={150}
									 height={200} layout="responsive"/>
						</Grid>
						<Grid item xs={12} sm={9} className={classes.descGrid}>
							<Typography className={classes.productName}>{item.name}</Typography>
							<Typography className={classes.productInfo}>{item.author}</Typography>
							<Typography className={classes.productInfo}>{localeDateLong(item.date)}</Typography>
							<Divider className={classes.divider}/>
							<div dangerouslySetInnerHTML={{ __html: item.shortDescription }}/>

						</Grid>
					</Grid>
				))}
			</CardContent>
		</Card>
	);
};

export default observer(OrderCard);
