export const breakingChangesVersion = 0;
import env from "../config/env";

export const APP_NAME = env.APP_NAME;
export const APP_URL = env.APP_URL;
export const API_URL = env.API_URL;

export function asset (path) {
	let handledPath = path ?? "";
	handledPath = handledPath.trimLeftEnhanced("/");
	return APP_URL.trimEnhanced("/") + "/storage/" + handledPath;
}
