import {ApisauceInstance, create} from "apisauce"
import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config"
import jwtTokenHandler from "../../helpers/jwtTokenHandler";
import {UserAddressApi} from "./collections/userAddress-api";
import {AuthApi} from "./collections/auth-api";
import {UsersApi} from "./collections/users-api";
import {CouponsApi} from "./collections/coupons-api";
import {ProductPhotosApi} from "./collections/productPhotos-api";
import {ProductsApi} from "./collections/products-api";
import {CategoriesApi} from "./collections/categories-api";
import {UserProductsApi} from "./collections/userProducts-api";
import {CartApi} from "./collections/cart-api";
import {OrderApi} from "./collections/order-api";
import {BranchesApi} from "./collections/branches-api";
import {OrganizationsApi} from "./collections/organizations-api";
import {CustomizationsApi} from "./collections/customizations-api";
import {AuthorRegisterApi} from "./collections/authorRegister-api";
import {OpportunitiesApi} from "./collections/opportunities-api";
import {HomePageApi} from "./collections/homePage-api";
import {RootStore} from "../../models";

export class ApiBase {
    /**
     * The underlying apisauce instance which performs the requests.
     */
        // @ts-ignore
    apisauce: ApisauceInstance

    /**
     * Configurable options.
     */
    config: ApiConfig

    store: RootStore | undefined

    /**
     * Creates the api.
     *
     * @param config The configuration to use.
     */
    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        this.config = config
    }


    /**
     * Sets up the API.  This will be called during the bootup
     * sequence and will happen before the first React component
     * is mounted.
     *
     * Be as quick as possible in here.
     */
    setup() {

        // construct the apisauce instance
        const apisauce = create({
            baseURL: this.config.url,
            timeout: this.config.timeout,
            headers: {
                Accept: "application/json",
            },
        })


        this.apisauce = apisauce
    }

    setStore(store: RootStore) {
        this.store = store

        const jwtTokenHandlerService = jwtTokenHandler.getService();
        const apisauce = this.apisauce
        const rootStore = this.store
// Add a request interceptor
        apisauce.axiosInstance.interceptors.request.use(
            config => {
                config.baseURL = this.config.url;
                config.headers["Content-Type"] = "application/json";
                config.headers.common["X-Requested-With"] = "XMLHttpRequest";
                const token = jwtTokenHandlerService.getToken();
                if (token) {
                    config.headers.common["Authorization"] = "Bearer " + token;
                }

                return config;
            },
            error => {
                Promise.reject(error);
            }
        );

        apisauce.axiosInstance.interceptors.response.use((response) => {
                return response;
            },
            function (error) {
                if (error.response !== undefined) {
                    if (error?.response?.status === 401) {
                        if (error?.response?.statusText === "Unauthorized") {
                            rootStore.logout()
                        }
                    }
                }

                return Promise.reject(error);
            });
        this.apisauce = apisauce
    }
}

/**
 * Manages all requests to the API.
 */
export class Api {
    base: ApiBase
    apisauce: ApisauceInstance | undefined
    config: ApiConfig
    store: RootStore | undefined

    /**
     * Creates the api.
     *
     * @param config The configuration to use.
     */
    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        const base = new ApiBase(config)

        this.base = base
        this.config = base.config
    }

    get auth() {
        return new AuthApi(this.base)
    }

    get userAddress() {
        return new UserAddressApi(this.base)
    }

    get users() {
        return new UsersApi(this.base)
    }

    get coupons() {
        return new CouponsApi(this.base)
    }

    get productPhotos() {
        return new ProductPhotosApi(this.base)
    }

    get products() {
        return new ProductsApi(this.base)
    }

    get categories() {
        return new CategoriesApi(this.base)
    }

    get userProducts() {
        return new UserProductsApi(this.base)
    }

    get cart() {
        return new CartApi(this.base)
    }

    get order() {
        return new OrderApi(this.base)
    }

    get branches() {
        return new BranchesApi(this.base)
    }

    get organizations() {
        return new OrganizationsApi(this.base)
    }

    get customizations() {
        return new CustomizationsApi(this.base)
    }

    get authorRegister() {
        return new AuthorRegisterApi(this.base)
    }

    get opportunities() {
        return new OpportunitiesApi(this.base)
    }

    get homePage() {
        return new HomePageApi(this.base)
    }

    setStore(store: RootStore) {
        this.base.setStore(store)
        this.store = this.base.store
    }

    setup() {
        const base = this.base
        base.setup()

        this.apisauce = base.apisauce
    }
}
