import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";
import {isNull} from "lodash-es";

/**
 * Content model
 */
export const ContentModel = types.model({
    key: types.string,
    value: types.maybeNull(types.string),
    valueLang: types.maybeNull(types.frozen())
}).extend(withEnvironment)


type ContentType = Instance<typeof ContentModel>

export interface Content extends ContentType {
}

type ContentSnapshotType = SnapshotOut<typeof ContentModel>

export interface ContentSnapshot extends ContentSnapshotType {
}

// @ts-ignore
export const createContentDefaultModel = () => types.optional(ContentModel, {})
