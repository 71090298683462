import React, { useState } from "react";
import TextField from "./UserInfoTextField";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { FormLabel, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import PhoneInput from "./PhoneInput";
import { useYupValidationResolver } from "../helpers/hooks";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const validationSchema = Yup.object({
	firstName: Yup.string()
		.required("Lütfen isminizi giriniz"),
	lastName: Yup.string()
		.required("Lütfen soyisminizi giriniz"),
	phoneNumber: Yup.string("")
		.matches(/\d{2}\s\d{3}\s\d{3}\s\d{2}\s\d{2}/g, "Lütfen geçerli bir telefon numarası giriniz")
		.required("Lütfen geçerli bir telefon numarası giriniz"),
	email: Yup.string()
		.email("Lütfen geçerli bir e-posta adresi giriniz")
		.required("Lütfen bir e-posta adresi giriniz")
});

const useStyles = makeStyles({
	root: {
		border: "1px solid #B8B8B8",
		borderRadius: "7px",
		boxShadow: "3px 3px 6px #00000029",
		width: "50%",
		padding: "5%",
		"@media (max-width:959px)": {
			width: "100%"
		}

	},
	gridHeading: {
		marginBottom: "30px",
		font: "normal normal normal 30px/24px Calibri"
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		gap: "8px"
	},
	inputLabel: {
		color: "#1E2436",
		font: "normal normal normal 20px/24px Calibri"
	},
	button: {
		height: "60px",
		textTransform: "none",
		border: "1px solid #1E2436",
		margin: "8px 0",
		font: "normal normal normal 24px/24px Calibri",
		background: "#22263B",
		color: "white",
		"&:hover": {
			background: "#22263B"
		}

	}


});

const UserCard = () => {
	const classes = useStyles();

	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	const api = rootStore.environment.api;
	const user = rootStore.auth;
	const userId = user.userId;

	const defaultValues = {
		firstName: user.firstName,
		lastName: user.lastName,
		phoneNumber: user.phoneNumber,
		email: user.email
	};
	const resolver = useYupValidationResolver(validationSchema);
	const { register, handleSubmit, control } = useForm({ resolver, defaultValues });
	const [errors, setErrors] = useState({});

	async function onSubmit (data) {

		const userUpdateData = {
			"firstName": data.firstName,
			"lastName": data.lastName,
			"phoneNumber": data.phoneNumber,
			"email": data.email
		};
		user.update(userUpdateData);

	}

	function onError (errors) {
		setErrors(errors);
	}

	return (

		<Card className={classes.root}>
			<form onSubmit={handleSubmit(onSubmit, onError)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography
							className={classes.gridHeading}>{contentStore.getContentValueLang("pages.userInformations.membershipInformationsTitle")}</Typography>
					</Grid>

					<Grid item className={classes.gridItem} xs={12} sm={6}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.firstName")}</FormLabel>
						<TextField variant="outlined" name="firstName" error={!!errors?.firstName}
											 helperText={errors?.firstName?.message ?? ""} {...register("firstName")}/>
					</Grid>


					<Grid item className={classes.gridItem} xs={12} sm={6}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.lastName")}</FormLabel>
						<TextField variant="outlined" name="lastname" error={!!errors?.lastName}
											 helperText={errors?.lastName?.message ?? ""} {...register("lastName")}/>
					</Grid>


					<Grid item className={classes.gridItem} xs={12}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.phoneNumber")}</FormLabel>
						<PhoneInput control={control} error={!!errors?.phoneNumber}
												helperText={errors?.phoneNumber?.message ?? ""}/>
					</Grid>


					<Grid item className={classes.gridItem} xs={12}>
						<FormLabel
							className={classes.inputLabel}>{contentStore.getContentValueLang("pages.userInformations.email")}</FormLabel>
						<TextField
							variant="outlined"
							name="email"
							error={!!errors?.email} helperText={errors?.email?.message ?? ""}
							{...register("email")}/>
					</Grid>
					<Grid item xs={12}>
						<Button className={classes.button} type="submit" size="large" variant="outlined"
										fullWidth>{contentStore.getContentValueLang("pages.userInformations.button")}</Button>
					</Grid>

				</Grid>
			</form>
		</Card>
	);
};

export default observer(UserCard);
