import React from "react";
import { Dialog, List, ListItem, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { _ } from "../App";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: {},
	listItem: {
		padding: 10,
		display: "flex",
		flexDirection: "column",
		border: "1px #efefef solid",
		alignItems: "flex-start",
		gap: 8
	},
	title: {
		font: "normal normal bold 24px/24px Calibri"
	},
	paper: {
		padding: 24
	}
});

function ChangeBillingAddressModal (props) {
	const { open, onClose, addresses, setBillingAddress, currentBillingAddress } = props;
	const classes = useStyles();
	const rootStore = useStores();
	const api = rootStore.environment.api;

	function isCurrentBillingAddress (addressId) {
		const isEqual = _.isEqual(addressId, currentBillingAddress.id);
		return isEqual;
	}

	function handleSetBillingAddress (address) {
		setBillingAddress(address);
		onClose();
	}

	return (
		<Dialog maxWidth={"md"} fullWidth open={open} onClose={onClose}>
			<Paper className={classes.paper}>
				<List>
					{addresses.map((address) => (
						<ListItem button className={classes.listItem} selected={isCurrentBillingAddress(address.id)}
											key={address.id} onClick={() => handleSetBillingAddress(address)}>
							<Typography className={classes.title}>{address.title}</Typography>
							<Typography>{address.fullName}</Typography>
							<Typography>{address.address}</Typography>
							<Typography>{address.phoneNumber}</Typography>
						</ListItem>
					))}
				</List>
			</Paper>
		</Dialog>
	);
}

export default observer(ChangeBillingAddressModal);
