import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Dialog, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	iframe: {
		height: 500,
		padding: 20,
		border: "none"
	}
}));

function PaymentModal ({ order, data, open, onClose, setOrder }) {
	const classes = useStyles();
	const iframeRef = useRef(null);
	/*const rootStore = useStores();
	const api = rootStore.environment.api;*/
	const [_3dResult, set3dResult] = useState(null);
	console.log(_3dResult);
	const [isLoading, setIsLoading] = useState(false);

	if (!data) return null;

	const { html, message, result } = data;


	async function onIframeLoad (event) {
		const url = event.target.contentWindow.location.href;
		const splittedUrl = url.split("/");
		const lastPartOfUrl = splittedUrl[splittedUrl.length - 1];
		const isSuccessUrl = lastPartOfUrl ? lastPartOfUrl.includes("success") : false;
		const isFailUrl = lastPartOfUrl ? lastPartOfUrl.includes("fail") : false;
		const isResultUrl = isSuccessUrl || isFailUrl;

		/*async function refetchOrder (order) {
			setIsLoading(true);
			await delay(2000);
			const orderResponse = await api.order.show(order.id);
			const orderResponseData = orderResponse.data;
			if (orderResponseData && orderResponseData.paymentDetail !== null) {
				setOrder(orderResponseData);

				setIsLoading(false);
			} else {
				await refetchOrder(order);
			}
		}
*/
		if (isResultUrl) {
			const currentIframe = iframeRef.current;
			currentIframe.style.display = "none";

			const innerHTML = event.target.contentWindow.document.body.firstChild.innerHTML;
			const new3dResult = innerHTML ? JSON.parse(innerHTML) : null;
			const order = new3dResult?.order;
			set3dResult(new3dResult);
			setOrder(order);
			/*if (order.paymentDetail === null) {
				await refetchOrder(order);
			}*/
		}
	}

	return (
		<Dialog fullWidth maxWidth="sm" open={open} aria-labelledby="customized-dialog-title">
			<MuiDialogTitle disableTypography id="customized-dialog-title"
											onClose={onClose}>
				<Typography variant="h5">Ödeme</Typography>
				<IconButton onClick={onClose} className={classes.closeButton}>
					<CloseIcon/>
				</IconButton>
			</MuiDialogTitle>
			<DialogContent dividers style={{ minHeight: 570, display: "flex", flexDirection: "column" }}>
				{
					isLoading ?
						<Grid container justifyContent={"center"} alignItems={"center"} style={{ margin: "auto", gap: 12 }}>
							<CircularProgress/>
							<Typography> Ödeme işlemi tamamlanıyor...</Typography>
						</Grid>
						:
						<>
							{result === 0 ?
								<Box p={3}>Hata: {message}</Box> :
								(<iframe ref={iframeRef} className={classes.iframe} srcDoc={html} onLoad={onIframeLoad}/>)
							}
							{_3dResult?.result === 0 &&
							<Grid container justifyContent={"center"} alignItems={"center"} style={{ margin: "auto", gap: 12 }}>
								<Typography>Ödeme başarısız oldu</Typography>
								<Typography>Detay: {_3dResult.message}</Typography>
							</Grid>
							}
						</>
				}
			</DialogContent>
		</Dialog>
	);
}
export default observer(PaymentModal)
