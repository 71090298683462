import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core";
import SecretLink from "./SecretLink";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";



const useStyles = makeStyles({
	root: {
		marginTop: "100px",
		padding: 30
	},
	active: {
		background: "#23273D",
		boxShadow: "3px 3px 6px #00000029",
		borderRadius: "7px",
		color: "white",
		width: "87%",
		height: "60px",
		font: "normal normal bold 24px/16px Calibri",
		textDecoration: "none",
		"&:hover": {
			background: "#23273D"
		}
	},
	normal: {
		width: "85%",
		background: "white",
		color: "#1E2436",
		font: "normal normal normal 20px/16px Calibri",
		height: "60px",
		border: "1px solid #E3E3E3",
		boxShadow: "3px 3px 6px #00000029",
		borderRadius: "7px"
	},
	subActive: {
		background: "linear-gradient(180deg, #1E2436 0%, #554686 100%)",
		color: "white",
		width: "75%",
		marginLeft: "5%",
		font: "normal normal normal 16px/24px Calibri"
	},
	subNormal: {
		font: "normal normal normal 16px/24px Calibri",
		background: "#00000029",
		color: "#1E2436",
		width: "75%",
		marginLeft: "5%"
	},
	list: {
		position: "sticky",
		top: "100px",
		marginBottom: "300px"
	},
	layoutGrid: {
		"@media (max-width:959px)": {
			display: "none"
		}

	}
});
const UserInformation = ({ subActive, active, children }) => {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;

	const menu = [
		{
			title: contentStore.getContentValueLang("pages.header.account.orderInformations"),
			path: "/orders",
			sub: []
		},
		{
			title: contentStore.getContentValueLang("pages.header.account.couponInformations"),
			path: "/coupon",
			sub: []
		},
		{
			title: contentStore.getContentValueLang("pages.header.account.userInformations"),
			path: "/my-account",
			sub: [
				{
					title: contentStore.getContentValueLang("pages.userInformations.membershipInformations"),
					path: "/my-account"
				},
				{
					title: contentStore.getContentValueLang("pages.userInformations.changePassword"),
					path: "/my-account/password"
				}
			]
		},
		{
			title: contentStore.getContentValueLang("pages.header.account.addressInformations"),
			path: "/address",
			sub: []
		}
	];


	return (
		<Container className={classes.root} disableGutters>
			<Grid container>
				<Grid item xs={12} md={3} className={classes.layoutGrid}>
					<List className={classes.list}>
						{menu.map((opt) =>
							<React.Fragment key={opt.path}>

								<SecretLink to={opt.path}>
									<ListItem
										button
										className={active === opt.path ? classes.active : classes.normal}
									>
										{opt.title}
									</ListItem>
								</SecretLink>

								{
									active === opt.path ? opt.sub?.map((sub) =>
										<SecretLink to={sub.path} key={sub.path}>
											<ListItem
												button
												className={subActive === sub.path ? classes.subActive : classes.subNormal}
											>{sub.title}</ListItem>
										</SecretLink>
									) : null
								}
							</React.Fragment>)}
					</List>
				</Grid>
				<Grid item xs={12} md={9}>
					{children}
				</Grid>
			</Grid>
		</Container>
	);
};

export default observer(UserInformation);
