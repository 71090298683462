import React, { useState } from "react";
import { Card, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Onboarding3 from "./Onboarding3";
import Onboarding2 from "./Onboarding2";
import Onboarding1 from "./OnBoarding1";
import { useStores } from "../../models";
import { Redirect } from "react-router-dom";


const useStyles = makeStyles({
	root: {
		background: "linear-gradient(121deg, rgba(200, 249, 255, .4) 0%, rgba(219, 203, 216, .4) 100%)",
		minHeight: "100vh",
		padding: "6% 10%"
	},
	card: {
		borderRadius: 32,
		padding: "10%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	}
});

function Onboarding () {
	const rootStore = useStores();
	const isOnboarded = rootStore.auth.isOnboarded;



	const [cardIndex, setCardIndex] = useState(0);
	const classes = useStyles();
	const cardContents = [
		<Onboarding1 indexHandler={cardContentHandler}/>,
		<Onboarding2 indexHandler={cardContentHandler}/>,
		<Onboarding3 indexHandler={cardContentHandler}/>
	];

	function cardContentHandler () {
		if (cardIndex === cardContents.length - 1) return;

		setCardIndex(prev => prev + 1);
	}

	if (isOnboarded) return <Redirect to={"/"}/>;
	return (
		<Container className={classes.root} maxWidth={false}>
			<Card className={classes.card}>
				{cardContents[cardIndex]}
			</Card>
		</Container>
	);
}

export default Onboarding;
