import {_} from "../App"
export function getContent (products) {
	if(_.isNil(products)) return
	let articleCount = 0;
	let bookCount = 0;
	products.map((product) => {
		if (product.type === "book") bookCount++;
		if (product.type === "article") articleCount++;
	});
	return `${articleCount > 0 ? articleCount + " Makale ":""} ${bookCount > 0 ? bookCount + " Kitap":""}`;
}
