import {flow, Instance, isAlive, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";

/**
 * Category model
 */
export const CategoryModel = types.model({
    id: types.string,
    parent: types.union(types.string, types.null),
    name: types.string,
    count: types.maybe(types.number)
}).extend(withEnvironment)
    .actions(self => ({
        getCount: flow(function* () {
            const response = yield self.environment.api.categories.count(self.id)
            const count = response.data
            if (isAlive(self)) {
                self.count = count ?? 0
            }
        })
    }))

type CategoryType = Instance<typeof CategoryModel>

export interface Category extends CategoryType {
}

type CategorySnapshotType = SnapshotOut<typeof CategoryModel>

export interface CategorySnapshot extends CategorySnapshotType {
}

// @ts-ignore
export const createCategoryDefaultModel = () => types.optional(CategoryModel, {})
