import {ApiBase} from "../api"

export class CategoriesApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index() {
        let response = await this.api.apisauce.get("/categories")
        return response

    }

    async count(categoryId: string) {
        const response = await this.api.apisauce.get(
            "/categories/" + categoryId + "/count"
        )
        return response
    }
}
