import React from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import { localeDateLong } from "../helpers/date";
import { asset } from "../config/initialize";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: {
		padding: "5%",
		boxShadow: "0px 3px 6px #00000029",
		border: "1px solid #DBDBDB",
		borderRadius: 7,
		margin: 20
	},
	img: {
		width: 150,
		height: 200,
		padding: 10
	},
	label: {
		font: "normal normal normal 22px/36px Calibri"

	},
	body: {
		font: "normal normal 300 14px/18px Montserrat",
		marginBottom: 20,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "initial !important",
		display: "-webkit-box",
		lineClamp: 4,
		boxOrient: "vertical"
	}
});

function OrderDetailOrderInfo (props) {
const rootStore = useStores()
	const contentStore = rootStore.contentStore
	const classes = useStyles();
	return (
		<Card className={classes.root}>
			<Typography className={classes.label}>{contentStore.getContentValueLang("pages.order.productNo")}:</Typography>
			<Typography className={classes.body}>{props.id}</Typography>
			<Typography className={classes.label}>{contentStore.getContentValueLang("pages.productPage.author")}:</Typography>
			<Typography className={classes.body}>{props.author}</Typography>
			<Typography className={classes.label}>{contentStore.getContentValueLang("pages.productPage.date")}</Typography>
			<Typography className={classes.body}>{localeDateLong(props.date)}</Typography>
			<Box display={"flex"}>
				<img className={classes.img} src={asset(props.image ?? "")}/>
				<Box>
					<Typography className={classes.label}>{props.name}</Typography>
					<div dangerouslySetInnerHTML={{ __html: props.shortDescription }}/>

				</Box>
			</Box>
		</Card>
	);
}

export default observer(OrderDetailOrderInfo)
