import React from "react";
import MainPageToolbar from "../components/MainPageToolbar";
import MainPage from "../components/MainPage";
import MainPageVideoSection from "../components/MainPageVideoSection";
import MainPageSection from "../components/MainPageSection";
import MainPageSubscribe from "../components/MainPageSubscribe";
import Footer from "../components/layout/Footer";
import { Container } from "@material-ui/core";
import LoggedInHomePage from "./LoggedInHomePage";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";


function Home () {
	const rootStore = useStores();
	if (rootStore.auth.isLoggedIn) return <LoggedInHomePage/>;

	return (
		<Container maxWidth={false} disableGutters>
			<MainPageToolbar/>
			<MainPage/>
			<MainPageVideoSection/>
			<MainPageSection/>
			<MainPageSubscribe/>
			<Footer/>
		</Container>
	);
}

export default observer(Home);
