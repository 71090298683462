import React, {useEffect, useState} from 'react'
import {Route, Router as MainRouter} from "react-router-dom";
import Main from './Router'
import "./config/overrides";
import history from "./helpers/history";
import {createTheme, ThemeProvider} from "@material-ui/core";
import {breakingChangesVersion, localStorageVariables} from "./config/env";
import "./theme/style.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import lodash from "lodash-es";

import deepdash from "deepdash-es";
// @ts-ignore
import underscoreDeepExtend from "underscore-deep-extend";
import _fp from "lodash/fp"
import {RootStore, RootStoreProvider, setupRootStore} from "./models";

export const _ = deepdash(lodash);
_.mixin({deepExtend: underscoreDeepExtend(_)});
export {_fp}
window._ = _;
window._fp = _fp;


const theme = createTheme({
    palette: {
        secondary: {
            main: "#1E2436"
        }
    },
})

function App() {
    const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

    const oldbreakingChangesVersion = localStorage.getItem(localStorageVariables.breakingChangesVersion) ?
        parseInt(localStorage.getItem(localStorageVariables.breakingChangesVersion)) : 0;


    // Kick off initial async loading actions, like loading fonts and RootStore
    useEffect(() => {
        ;(async () => {
            setupRootStore().then(async (initializedStore) => {
                setRootStore(initializedStore)
            })
        })()
    }, [])

    if (!rootStore) return null

    if (oldbreakingChangesVersion !== breakingChangesVersion) {
        rootStore.logout()
    }

    return (
        <>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>

            <link
                rel="stylesheet"
                href="https://cdn.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css"/>
            <link href="//db.onlinewebfonts.com/c/1dc8ecd8056a5ea7aa7de1db42b5b639?family=Gilroy" rel="stylesheet"
                  type="text/css"/>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic"/>

            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
                rel="stylesheet"/>
            <RootStoreProvider value={rootStore as RootStore}>
                <ThemeProvider theme={theme}>
                    <MainRouter history={history}>
                        <Route component={Main}/>
                    </MainRouter>
                </ThemeProvider>
            </RootStoreProvider>
        </>
    )
}

export default App

