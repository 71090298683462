import { Button, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { localeDateLong } from "../helpers/date";
import SecretLink from "./SecretLink";
import { asset } from "../config/initialize";
import { getContent } from "../helpers/productsContentHandler";
import { useStores } from "../models";

const lor = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a volutpat mi. Maecenas tempor lorem mauris, in vestibulum est convallis non. Sed a iaculis tortor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum nec dapibus est. Etiam scelerisque massa eget porttitor volutpat. Suspendisse sollicitudin purus nisi, in blandit";
const useStyles = makeStyles({
	root: {
		width: "90%",
		borderRadius: "7px",
		boxShadow: "3px 3px 6px #00000029",
		border: "1px solid #B8B8B8",
		"@media (max-width:1280px)": {
			width: "100%"
		}
	},
	cardHeader: {
		background: "#E3E3E3",
		display: "flex",
		borderBottom: "1px solid #B8B8B8",
		borderRadius: "7px 7px 0px 0px"
	},
	gridBordered: {
		borderLeft: "1px solid #B9B9B9",
		paddingLeft: "16px",
		"@media (max-width:599px)": {
			paddingLeft: "0",
			marginTop: "16px",
			paddingTop: "8px",
			borderLeft: "none",
			borderTop: "1px solid #B9B9B9"
		}
	},
	cardDescGrid: {
		display: "flex",
		flexDirection: "column",
		gap: "30px"
	},
	button: {
		textTransform: "none",
		font: "normal normal 300 16px/32px Calibri",
		background: "linear-gradient(105deg, #1E2436 0%, #554686 100%)",
		color: "white",
		borderRadius: 8
	},
	productGrid: {
		margin: "8px 0"
	},
	sectionName: {
		font: "normal normal bold 24px/24px Calibri"
	},
	sectionInfo: {
		font: "normal normal normal 19px/24px Calibri"
	},
	productName: {
		font: "normal normal normal 22px/27px Calibri"
	},
	productDesc: {
		font: "normal normal 300 14px/18px Montserrat",
		textOverflow: "ellipsis",
		overflow: "hidden",
		display: "-webkit-box",
		lineClamp: 6,
		boxOrient: "vertical"
	}

});


const CouponCard = (props) => {
	const classes = useStyles();
	const { products } = props;

	const rootStore = useStores();
	const contentStore = rootStore.contentStore;

	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardHeader}>
				<Grid container>
					<Grid item xs={12} sm={4}>
						<Typography
							className={classes.sectionName}>{contentStore.getContentValueLang("pages.coupons.couponActivationDate")}</Typography>
						<Typography className={classes.sectionInfo}>{localeDateLong(props.activationDate)}</Typography>
					</Grid>
					<Grid item xs={12} sm={3} className={classes.gridBordered}>
						<Typography
							className={classes.sectionName}>{contentStore.getContentValueLang("pages.coupons.couponCode")}</Typography>
						<Typography className={classes.sectionInfo}>{props.id}</Typography>
					</Grid>
					<Grid item xs={12} sm={3} className={classes.gridBordered}>
						<Typography
							className={classes.sectionName}>{contentStore.getContentValueLang("pages.coupons.couponContent")}</Typography>
						<Typography className={classes.sectionInfo}>{getContent(products)}</Typography>
					</Grid>
					<Grid item xs={12} sm={2}>
						<SecretLink to={"/coupon-detail/" + props.id}>
							<Button variant="outlined"
											className={classes.button}>{contentStore.getContentValueLang("pages.coupons.couponDetails")}</Button>
						</SecretLink>
					</Grid>
				</Grid>
			</CardContent>
			<CardContent>
				{products.map(product => (
					<Grid container key={product.id} className={classes.productGrid}>
						<Grid container item xs={12} sm={3} justifyContent={"center"} style={{ display: "flex" }}>
							<img src={asset(product.image)} width={100} height={150}/>
						</Grid>
						<Grid item xs={12} sm={9} className={classes.cardDescGrid}>
							<Typography className={classes.productName}>{product.name}</Typography>
							<div dangerouslySetInnerHTML={{ __html: product.shortDescription }}/>

						</Grid>
					</Grid>
				))}
			</CardContent>
		</Card>
	);
};

export default CouponCard;




