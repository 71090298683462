import React from "react";
import {Redirect, Route} from "react-router-dom";
import {paths} from "../Router";
import {observer} from "mobx-react";
import { useStores } from "../models";

const ProtectedRoute = ({component: Component, user, ...rest}) => {
	const store = useStores();
	const authenticated = store.auth.isLoggedIn;

	return (
		<Route
			{...rest}
			render={props => {
				if (authenticated) {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: paths.login,
								state: {
									from: props.location
								}
							}}
						/>
					);
				}
			}}
		/>
	);
};

export default observer(ProtectedRoute);
