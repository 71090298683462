import {ApiBase} from "../api"

export class HomePageApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index(branchId: string) {
        const response = await this.api.apisauce.get(
            "/homepage/" + branchId
        )
        return response
    }

}
