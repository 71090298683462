import {ApiBase} from "../api"

export class CartApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async modifyItem(productId: string, quantity: number = 1) {
        const response = await this.api.apisauce.post(
            "/cart",
            {
                "product": productId,
                "quantity": quantity
            }
        )
        return response
    }

    async destroyCart() {
        const response = await this.api.apisauce.delete(
            "/cart"
        )
        return response
    }

    async getCart() {
        const response = await this.api.apisauce.get(
            "/cart"
        )
        return response
    }
}
