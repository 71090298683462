//import {MainModel} from "../models/Main";
//import {useCreateStore} from "mobx-store-provider";
import {stringWrapper} from "../tools/stringWrapper";
import ENV_DEV from "./env.dev";
import ENV_PROD from "./env.prod";

export const localStorageVariables = stringWrapper(
    {
        mainStore: "mainStore",
        jwt: "jwt",
        user:"user",
        credentials: "credentials",
        unauthorizedCount: "jwt-unauthorized",
        breakingChangesVersion: "breakingChangesVersion"
    },
    {
        prefix: "dijitip",
        suffix: "",
        seperator: "-"
    }
);

export const breakingChangesVersion = 0;

export const API_URL = document.head.querySelector("meta[name=\"api-url\"]").getAttribute("content");
/*
export const rootStore = () => {
    let initialUser = {auth: {isLoggedIn: false}};

    if (localStorage.getItem(localStorageVariables.mainStore)) {
        const json = JSON.parse(localStorage.getItem(localStorageVariables.mainStore));
        if (MainModel.is(json)) {
            initialUser = json;
        }
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCreateStore(() => MainModel.create(initialUser));
};
*/


const env = true ? ENV_DEV : ENV_PROD;
export default env;
