import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStores } from "../../models";
import { observer } from "mobx-react-lite";
import { asset } from "../../config/initialize";

const useStyles = makeStyles({
	contentGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 32
	},
	text: {
		font: "normal normal 500 40px/50px Gilroy",
		textAlign: "center",
		maxWidth: 500

	},
	textfield: {
		borderRadius: 8
	},
	button: {
		color: "white",
		background: "linear-gradient(128deg, rgba(30, 36, 54, 1) 0%, rgba(85, 70, 134, 1) 100%)",
		textTransform: "none",
		font: "normal normal bold 24px/24px Calibri",
		padding: "8px 36px"
	},
	imageGrid: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding:20
	}
});

const Onboarding2 = ({ indexHandler }) => {
	const classes = useStyles();
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const [userType, setUserType] = useState("");

	const rootStore = useStores();
	const contentStore = rootStore.contentStore

	function handleContinue () {
		const firstName = firstNameRef.current.value;
		const lastName = lastNameRef.current.value;
		if (!firstName || !lastName || !userType) return;
		rootStore.register.setName(firstName, lastName, userType);
		indexHandler();
	}

	function handleSetUserType (event) {
		const currentUserType = event.target.value;
		setUserType(currentUserType);
	}

	return (
		<Grid container>
			<Grid xs={12} md={5} item className={classes.contentGrid}>

				<Typography className={classes.text}>{contentStore.getContentValueLang("pages.onboarding.step2.title")}</Typography>
				<TextField
					fullWidth
					name={"fullName"}
					placeholder={contentStore.getContentValueLang("pages.onboarding.placeholder.firstName")}
					variant={"outlined"}
					InputProps={{
						className: classes.textfield
					}}
					inputRef={firstNameRef}
				/>
				<TextField
					inputRef={lastNameRef}
					fullWidth
					name={"fullName"}
					placeholder={contentStore.getContentValueLang("pages.onboarding.placeholder.lastName")}
					variant={"outlined"}
					InputProps={{
						className: classes.textfield
					}}/>
				<RadioGroup row aria-label="type" name="type" value={userType}
										onChange={handleSetUserType}>
					<FormControlLabel value="doctor" control={<Radio/>} label={contentStore.getContentValueLang("pages.onboarding.step2.doctor")}/>
					<FormControlLabel value="student" control={<Radio/>} label={contentStore.getContentValueLang("pages.onboarding.step2.student")}/>
					<FormControlLabel value="other" control={<Radio/>} label={contentStore.getContentValueLang("pages.onboarding.step2.other")}/>
				</RadioGroup>

				<Button className={classes.button} onClick={handleContinue}>{contentStore.getContentValueLang("pages.onboarding.step2.button")}</Button>
			</Grid>
			<Grid xs={12} md={7} item className={classes.imageGrid}>
				<img src={asset(contentStore.getContentValue("pages.onboarding.step2.image"))}/>
			</Grid>
		</Grid>
	);
};

export default observer(Onboarding2);
