import React from "react";
import SignUpForm from "../components/SignUpCard";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import RegisterHeader from "../components/RegisterHeader";
import Footer from "../components/layout/Footer";
import SecretLink from "../components/SecretLink";
import doctorBG from "../resources/doctorBG.png";
import { Redirect } from "react-router-dom";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles((theme) => ({
	root: {
		background: `url(${doctorBG})`,
		backgroundPosition: "center",
		backgroundSize: "auto",
		height: "150%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	box: {
		display: "flex",
		gap: "20px",
		textAlign: "center",
		padding: "50px 0",
		alignItems: "center"
	},
	haveAccount: {
		color: "#757575",
		font: "normal normal 300 22px/28px Calibri",
		"@media (max-width:600px)": {
			fontSize: 16
		}
	},
	link: {
		cursor: "pointer",
		color: "#1E2436",
		font: "normal normal bold 22px/28px Calibri",
		"@media (max-width:600px)": {
			fontSize: 16
		}

	}
}));

const Register = () => {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	if (rootStore.auth.isLoggedIn) return <Redirect to={"/"}/>;
	return (
		<Container className={classes.root} maxWidth={false} disableGutters>
			<RegisterHeader/>
			<SignUpForm/>
			<Box className={classes.box}>
				<Typography className={classes.haveAccount}>{contentStore.getContentValueLang("pages.signUp.doYouHaveAnAccount.text")}</Typography>
				<SecretLink to="/login" className={classes.link}>{contentStore.getContentValueLang("pages.signUp.doYouHaveAnAccount.button")}</SecretLink>
			</Box>

			<Footer/>
		</Container>
	);
};

export default observer(Register);
