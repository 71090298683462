import React, { useEffect, useState } from "react";
import { Checkbox, Drawer, FormControlLabel, Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Rating } from "@material-ui/lab";
import { getQuery, setQuery } from "../helpers/routing";
import { _ } from "../App";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
	desktopRoot: {
		"@media (max-width:959px)": {
			display: "none"
		}
	},
	mobileRoot: {
		display: "none",
		"@media (max-width:959px)": {
			display: "block"
		}
	},
	mobileDrawer: {
		padding: 12
	},
	rootHeader: {
		font: "normal normal normal 22px/27px Calibri"
	},
	paper: {
		marginTop: "10px",
		boxShadow: "3px 3px 6px #00000029",
		padding: "20px",
		display: "flex",
		flexDirection: "column",
		gap: "30px"
	},
	filterHeader: {
		marginLeft: "10px",
		marginBottom: "10px",
		font: "normal normal normal 20px/24px Calibri"
	},
	filterSection: {
		paddingBottom: "30px"
	},
	filter: {
		display: "flex",
		alignItems: "center",
		font: "normal normal 300 16px/19px Calibri"
	},
	filterName: {
		font: "normal normal 300 16px/19px Calibri"
	},
	amount: {
		font: "normal normal 300 13px/15px Calibri",
		marginLeft: "auto"
	},
	formControl: {
		paddingLeft: "10px",
		width: "100%",
		"& .MuiFormControlLabel-label": {
			width: "100%"
		}
	},
	checkbox: {
		padding: "0",
		margin: "10px 0",
		marginRight: "10px"
	},
	formControlLabel: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	filterButton: {
		textTransform: "none",
		background: "linear-gradient(114deg, #1E2436 0%, #554686 100%)",
		color: "white",
		marginTop: "40px",
		font: "normal normal normal 17px/26px Gilroy",
		height: "50px"

	}
}));


function SearchFilters (props) {
	const { open, onClose } = props;
	const classes = useStyles();

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const categories = rootStore.categoryStore.categories;

	const ratings = ["5", "4", "3", "2", "1"];

	const [query, setquery] = useState(getQuery({ arrayFormat: "comma", comma: true }));
	const queryControl = getQuery({ arrayFormat: "comma", comma: true });
	if (!_.isEqual(query, queryControl)) setquery(queryControl);

	const queryCategories = Array.isArray(query?.filter?.category) ? query.filter.category : (query?.filter?.category ? [query.filter.category] : []);
	const queryMainCategories = Array.isArray(query?.filter?.mainCategory) ? query.filter.mainCategory : (query?.filter?.mainCategory ? [query.filter.mainCategory] : []);

	const [categoryFilter, setCategoryFilter] = useState(queryCategories);
	const [mainCategoryFilter, setMainCategoryFilter] = useState(queryMainCategories);
	const [ratingFilter, setRatingFilter] = useState(query?.filter?.rating_average || []);

	const { childCategories, parentCategories } = prepareCategories(categories);

	function handleToggleMainCategoryFilter (categoryId) {
		const isFiltered = isMainCategoryFiltered(categoryId);
		if (isFiltered) {
			setMainCategoryFilter(mainCategoryFilter.filter(catId => catId !== categoryId));
		} else {
			setMainCategoryFilter([...mainCategoryFilter, categoryId]);
		}
	}

	function isMainCategoryFiltered (categoryId) {
		const isFiltered = mainCategoryFilter.includes(categoryId);
		return isFiltered;
	}

	function handleToggleCategoryFilter (categoryId) {
		const isFiltered = isCategoryFiltered(categoryId);
		if (isFiltered) {
			setCategoryFilter(categoryFilter.filter(catId => catId !== categoryId));
		} else {
			setCategoryFilter([...categoryFilter, categoryId]);
		}
	}

	function isCategoryFiltered (categoryId) {
		const isFiltered = categoryFilter.includes(categoryId);
		return isFiltered;
	}

	function handleToggleRatingFilter (rating) {
		const isFiltered = isRatingFiltered(rating);
		if (isFiltered) {
			setRatingFilter(ratingFilter.filter(catId => catId !== rating));
		} else {
			setRatingFilter([...ratingFilter, rating]);
		}
	}

	function isRatingFiltered (rating) {
		const isFiltered = ratingFilter.includes(rating);
		return isFiltered;
	}

	function handleFilter () {
		const filterQuery = {
			category: categoryFilter,
			mainCategory: mainCategoryFilter,
			rating_average: ratingFilter
		};
		query.filter = filterQuery;
		setQuery(query, location.pathname, { arrayFormat: "comma" });

	}

	function prepareCategories (allCategories) {
		let parentCategories = [];
		let childCategories = [];
		allCategories.map(cat => {
			if (cat.parent === null) {
				parentCategories.push(cat);
			} else {
				childCategories.push(cat);
			}
		});

		return { childCategories, parentCategories };
	}

	useEffect(async () => {
		setCategoryFilter(queryCategories);
		setMainCategoryFilter(queryMainCategories);
	}, [query]);

	const filterComponent = (
		<Container disableGutters className={classes.root}>
			<Typography className={classes.rootHeader}>{contentStore.getContentValueLang("pages.searchPage.filters")}</Typography>
			<Paper className={classes.paper}>
				<Box className={classes.filterSection} style={{ borderBottom: "1px solid #BEBEBE" }}>
					<Typography className={classes.filterHeader}>{contentStore.getContentValueLang("pages.searchPage.category")}</Typography>
					{parentCategories.map((category, i) =>
						<Box key={i} className={classes.filter}>
							<FormControlLabel
								className={classes.formControl}
								control={<Checkbox checked={isMainCategoryFiltered(category.id)}
																	 onClick={() => handleToggleMainCategoryFilter(category.id)}
																	 className={classes.checkbox}/>}
								label={
									<Box className={classes.formControlLabel}>
										<Typography className={classes.filterName}>{category.name}</Typography>
										<Typography className={classes.amount}>{category.count}</Typography>
									</Box>
								}/>

						</Box>)}
				</Box>
				<Box className={classes.filterSection} style={{ borderBottom: "1px solid #BEBEBE" }}>
					<Typography className={classes.filterHeader}>{contentStore.getContentValueLang("pages.searchPage.topic")}</Typography>
					{childCategories.map((category, i) =>
						<Box key={i} className={classes.filter}>
							<FormControlLabel
								className={classes.formControl}
								control={<Checkbox
									checked={isCategoryFiltered(category.id)}
									onClick={() => handleToggleCategoryFilter(category.id)}
									className={classes.checkbox}/>}
								label={
									<Box className={classes.formControlLabel}>
										<Typography className={classes.filterName}>{category.name}</Typography>
										<Typography className={classes.amount}>{category.count}</Typography>
									</Box>
								}/>
						</Box>)}
				</Box>
				<Box className={classes.filterSection}>
					<Typography className={classes.filterHeader}>{contentStore.getContentValueLang("pages.searchPage.rating")}</Typography>
					{ratings.map((rating, i) =>
						<Box key={i} className={classes.filter}>
							<FormControlLabel
								className={classes.formControl}
								control={<Checkbox
									checked={isRatingFiltered(rating)}
									onClick={() => handleToggleRatingFilter(rating)}
									className={classes.checkbox}/>}
								label={
									<Rating value={+rating} readOnly/>
								}/>
						</Box>
					)}

				</Box>
			</Paper>
			<Button fullWidth className={classes.filterButton} onClick={handleFilter}>{contentStore.getContentValueLang("pages.searchPage.useFilter")}</Button>
		</Container>
	);
	return (
		<div>
			<div className={classes.desktopRoot}>
				{filterComponent}
			</div>
			<div className={classes.mobileRoot}>
				<Drawer open={open} onClose={onClose} PaperProps={{ className: classes.mobileDrawer }}>
					{filterComponent}
				</Drawer>
			</div>
		</div>
	);
}

export default observer(SearchFilters);


