import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Badge, ClickAwayListener, MenuList, Paper, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SecretLink from "../SecretLink";
import { useHistory } from "react-router-dom";
import { setQuery } from "../../helpers/routing";
import { observer } from "mobx-react-lite";
import libraryIcon from "../../resources/temp/library.png";
import searchIcon from "../../resources/temp/search.png";
import cartIcon from "../../resources/temp/cart.png";
import { useStores } from "../../models";
import logo from "../../resources/logo.png";


const useStyles = makeStyles((theme) => ({
	appbar: {
		background: "white"
	},
	toolbar: {},
	root: {},
	gridContainer: {
		display: "flex",
		alignItems: "center"
	},
	gridItem: {
		height: "80px",
		display: "flex",
		alignItems: "center",
		padding: "8px",
		justifyContent: "space-between"
	},
	logo: {
		maxHeight: 100,
		width: "60%",
		margin: "10px auto"
	},
	searchBarO: {
		borderRadius: 8
	},
	searchBar: {
		"&:hover": {
			"& .MuiOutlinedInput-root fieldset": {
				borderColor: "#333155"
			}
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#333155"
			},
			"&.Mui-focused fieldset": {
				borderColor: "#333155"
			}

		}
	},
	iconButton: {
		background: "linear-gradient(235deg, #1E2436 10%, #554686 100%)",
		borderRadius: "0px 7px 7px 0px",
		height: "54px",
		width: "68px",
		marginRight: "-14px"
	},
	searchIcon: {
		color: "white"
	},
	library: {
		color: "black",
		display: "flex",
		alignItems: "center",
		gap: "5px"
	},
	libraryText: {
		font: "normal normal normal 17px/26px Gilroy",
		"@media (max-width:400px)": {
			display: "none"
		}
	},
	myAccountButton: {
		background: "#1E2436",
		borderRadius: "10px",
		padding: "10px 35px",
		color: "white",
		textTransform: "none",
		display: "flex",
		justifyContent: "space-between",
		font: "normal normal normal 17px/26px Gilroy",
		"&:hover": {
			background: "#1E2436"
		}
	},
	menu: {
		position: "absolute",
		zIndex: "20",
		padding: "20px 0 0",
		margin: "10px 0"

	},
	menuHeading: {
		font: "normal normal bold 24px/20px Calibri",
		padding: "0 30px"
	},

	menuLink: {
		textDecoration: "none",
		color: "#616161",
		font: "normal normal bold 14px/20px Calibri",
		padding: "5px 30px"
	},
	myAccountButtonDesktop: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		"@media (max-width:400px)": {
			display: "none"
		}
	},
	myAccountButtonMobile: {
		display: "none",
		"@media (max-width:400px)": {
			display: "block"
		}
	}

}));

const Header = observer(() => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const userCart = rootStore.cart;

	const classes = useStyles();
	const history = useHistory();
	const searchRef = useRef(null);

	const itemCountInCart = userCart.itemIds.length;

	const handleSearch = () => {
		const search = searchRef.current.value;
		const query = search === "" ? {} : { search };
		setQuery(query, "/search", { arrayFormat: "comma" });
	};

	function handleLogout () {
		rootStore.auth.logout();
	}

	return (
		<AppBar position="static" className={classes.appbar}>
			<Toolbar className={classes.toolbar}>
				<Container className={classes.root}>
					<Grid container className={classes.gridContainer}>
						<Grid item xs={12} md={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
							<SecretLink to="/" showPointer>
								<img src={logo} alt="LogoLogo" className={classes.logo}/>
							</SecretLink>
						</Grid>
						<Grid item xs={12} md={6} className={classes.gridItem}>
							<TextField variant="outlined"
												 fullWidth
												 className={classes.searchBar}
												 inputRef={searchRef}
												 InputProps={{
													 className: classes.searchBarO,
													 endAdornment: (
														 <InputAdornment position="end">
															 <IconButton className={classes.iconButton} onClick={handleSearch}>
																 <img src={searchIcon}/>
															 </IconButton>
														 </InputAdornment>
													 )
												 }}/>
						</Grid>
						<Grid item xs={12} md={4} className={classes.gridItem}>

							<SecretLink to="/library" showPointer className={classes.library}>
								<img src={libraryIcon}/>
								<Typography className={classes.libraryText}>{contentStore.getContentValueLang("pages.header.library")}</Typography>
							</SecretLink>


							<SecretLink showPointer to="/basket">
								<IconButton>
									<Badge badgeContent={itemCountInCart} color="primary">
										<img src={cartIcon}/>
									</Badge>
								</IconButton>
							</SecretLink>

							<Box>
								<Button fullWidth onClick={() => setIsMenuOpen(p => !p)}
												className={classes.myAccountButton}>
									<Box className={classes.myAccountButtonDesktop}>{contentStore.getContentValueLang("pages.header.accountTitle")}<KeyboardArrowDownIcon/>
									</Box>
									<Box className={classes.myAccountButtonMobile}>
										<KeyboardArrowDownIcon/>
									</Box>
								</Button>
								{isMenuOpen ? <>
										<ClickAwayListener onClickAway={() => {
											setIsMenuOpen(false);
										}}>
											<Paper className={classes.menu}>

												<Typography className={classes.menuHeading}>{contentStore.getContentValueLang("pages.header.accountTitle")}</Typography>
												<MenuList>
													<SecretLink to="/orders"><MenuItem className={classes.menuLink}>{contentStore.getContentValueLang("pages.header.account.orderInformations")}</MenuItem></SecretLink>
													<SecretLink to="/coupon"><MenuItem className={classes.menuLink}>{contentStore.getContentValueLang("pages.header.account.couponInformations")}</MenuItem></SecretLink>
													<SecretLink to="/my-account"><MenuItem className={classes.menuLink}>{contentStore.getContentValueLang("pages.header.account.userInformations")}</MenuItem></SecretLink>
													<SecretLink to="/address"><MenuItem className={classes.menuLink}>{contentStore.getContentValueLang("pages.header.account.addressInformations")}</MenuItem></SecretLink>
													<SecretLink to="/"><MenuItem onClick={handleLogout} className={classes.menuLink}>{contentStore.getContentValueLang("pages.header.account.logout")}</MenuItem></SecretLink>
												</MenuList>
											</Paper>
										</ClickAwayListener>
									</>
									: null}
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Toolbar>
		</AppBar>
	);

});

export default Header;
