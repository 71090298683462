import {ApiBase} from "../api"


export class AuthorRegisterApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async authorRegister(authorData: any) {


        const response = await this.api.apisauce.post(
            "/author/register",
            authorData,
        )

        return response;
    }
}
