import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { useStores } from "../../models";
import { observer } from "mobx-react-lite";
import { asset } from "../../config/initialize";

const useStyles = makeStyles({
	contentGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 32
	},
	text: {
		font: "normal normal 500 40px/50px Gilroy",
		textAlign: "center",
		maxWidth: 500
	},

	button: {
		color: "white",
		background: "linear-gradient(128deg, rgba(30, 36, 54, 1) 0%, rgba(85, 70, 134, 1) 100%)",
		textTransform: "none",
		font: "normal normal bold 24px/24px Calibri",
		padding: "8px 36px"
	},
	imageGrid: {
		padding: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}

});


const Onboarding3 = ({ indexHandler }) => {
	const classes = useStyles();

	const rootStore = useStores();
	const api = rootStore.environment.api;
	const contentStore = rootStore.contentStore;

	const [branches, setBranches] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userType = rootStore.register.type;

	const [userBranch, setUserBranch] = useState();
	const [userOrganization, setUserOrganization] = useState();


	function handleBranchChange (event, value) {
		setUserBranch(value);
	}

	function handleOrganizationChange (event, value) {
		setUserOrganization(value);
	}

	function handleContinue () {
		if (!userBranch || !userOrganization) return;
		rootStore.register.setBranch(userBranch.id, userOrganization.id);
	}

	function calculateOrganizations (organizations, type) {
		const userOrganizationType = type === "doctor" ? "hospital" : "school";
		const organizationsData = organizations
			.filter(organization => organization.type === userOrganizationType || type === "other")
			.map(organization => ({ label: organization.name, id: organization.id }));
		return organizationsData;
	}

	function calculateBranches (branches, type) {
		const branchesData = branches
			.filter(branch => branch.type === type || type === "other")
			.map(branch => ({ label: branch.name, id: branch.id }));
		return branchesData;
	}

	useEffect(async () => {
		const branchesResponse = await api.branches.index();
		const organizationsResponse = await api.organizations.index();
		const branchesData = calculateBranches(branchesResponse.data, userType);
		const organizationsData = calculateOrganizations(organizationsResponse.data, userType);
		setBranches(branchesData);
		setOrganizations(organizationsData);
		setIsLoading(false);

	}, []);


	return (
		<Grid container>
			<Grid xs={12} md={5} item className={classes.contentGrid}>
				<Typography
					className={classes.text}>{contentStore.getContentValueLang("pages.onboarding.step3.title")}</Typography>
				<Autocomplete
					fullWidth
					loading={isLoading}
					onChange={handleBranchChange}
					getOptionLabel={(option) => option.label ?? option}
					renderInput={props => <TextField {...props}
																					 placeholder={contentStore.getContentValueLang("pages.onboarding.placeholder.branch")}/>}
					options={branches}/>
				<Autocomplete
					fullWidth
					loading={isLoading}
					onChange={handleOrganizationChange}
					getOptionLabel={(option) => option.label ?? option}
					renderInput={props => <TextField {...props}
																					 placeholder={contentStore.getContentValueLang("pages.onboarding.placeholder.organization")}/>}
					options={organizations}/>
				<Button className={classes.button}
								onClick={handleContinue}>{contentStore.getContentValueLang("pages.onboarding.step3.button")}</Button>
			</Grid>
			<Grid xs={12} md={7} item className={classes.imageGrid}>
				<img src={asset(contentStore.getContentValue("pages.onboarding.step3.image"))}/>
			</Grid>

		</Grid>
	);
};

export default observer(Onboarding3);
