import {withStyles,TextField} from "@material-ui/core";

const textField = withStyles({
    root: {

        '& label.Mui-focused': {
            color: '#1E2436',
        },
        '& label': {
            color: '#1E2436',
        },
        "&:hover":{
            "& label.MuiFormLabel-root.Mui-error":{
                color:"#1E2436"
            },
            "& .MuiOutlinedInput-root fieldset":{
                borderColor: '#1f1e1e',
            }

        },
        '& .MuiOutlinedInput-root': {
            borderRadius:"7px",
            '& fieldset': {
                borderColor: '#1f1e1e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1E2436',
            },

        },
    },
})(TextField)

export default textField