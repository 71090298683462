import {ApiBase} from "../api"

export class UsersApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }
    async show  (userId:string)  {
        const response = await this.api.apisauce.get(
            "/users/" + userId,
        )
        return response
    }

    async update  (userId:string, data:any)  {
        const response = await this.api.apisauce.put(
            "/users/" + userId,
            data
        )
        return response
    }

    async sms (userId:string, phoneNumber:string)  {
        const response = await this.api.apisauce.post(
            "/" + userId + "/sms",
            {phoneNumber},
        )
        return response
    }

    async smsVerify (userId:string, code:string)  {
        const response = await this.api.apisauce.post(
            "/" + userId + "/sms/verify",
            {code}
        )
        return response
    }
}
