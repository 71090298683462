import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {setQuery} from "../helpers/routing";

const useStyles = makeStyles({
    link: {
        textDecoration: "none",
        color: "inherit",
        "&:focus, &:hover, &:visited, &:link, &:active": {
            textDecoration: "none"
        }
    },
    defaultCursor: {
        cursor: "inherit",
    }
});

function SecretLink({children, className, showPointer = false, ...props}) {
    const classes = useStyles();

    function handleClearQuery() {
        setQuery({})
    }

    return (
        <Link onClick={handleClearQuery}
              className={clsx(classes.link, !showPointer && classes.defaultCursor, className)} {...props}>{children}</Link>
    );
}

export default SecretLink;
