import {ApiBase} from "../api"
import {makeQuery} from "../../../helpers/routing";

export class UserProductsApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index (userId:string, query:any) {
        const params = makeQuery(query, {arrayFormat: "comma"})
        const response = await this.api.apisauce.get(
            "/users/" + userId + "/products?" + params
        )
        return response
    }


}
