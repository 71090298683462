import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { asset } from "../config/initialize";
import ResponsiveCarousel from "./ResponsiveCarousel";

const useStyles = makeStyles({
	root: {
		width: "80%",
		margin: "0 auto"
	},
	featureBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "40px 5%"
	},
	featureText: {
		font: "normal normal normal 22px/24px Calibri",
		textAlign: "center",
		width: "50%",
		margin: "20px 0"
	},
	image:{
		height:100,
		width:100
	}
});

function LoggedInFeatures (props) {
	const classes = useStyles();
	const { homePageFeatures } = props;
	return (
		<ResponsiveCarousel
			responsivePattern={responsive}
			infinite
		>
			{
				homePageFeatures.map((object) => <Feature text={object.text} image={asset(object.photo)} key={object.id}/>)
			}
		</ResponsiveCarousel>
	);
}

function Feature (props) {
	const classes = useStyles();
	return (
		<Box className={classes.featureBox}>
			<img src={props.image} className={classes.image}/>
			<Typography className={classes.featureText}>{props.text}</Typography>
		</Box>
	);
}


export default LoggedInFeatures;
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 4
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};
