import React, { useState } from "react";
import Product from "./Product";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Portal } from "@material-ui/core";
import PDFViewer from "./PdfReader/PdfReader";
import { asset } from "../config/initialize";

const useStyle = makeStyles(theme => ({
	root: {
		padding: "40px 20px"
	}
}));

const Products = ({ products }) => {
	const classes = useStyle();
	const [fileState, setFileState] = useState({});
	const [isPdfOpen, setIsPdfOpen] = useState(false);

	function handlePdfClose () {
		setIsPdfOpen(false);
	}
	function handlePdfOpen () {
		setIsPdfOpen(true);
	}
	return (
		<Grid container spacing={3} className={classes.root}>
			<Portal>
				<PDFViewer open={isPdfOpen} file={asset(fileState?.file ?? "")}
									 filename={fileState?.filename ?? "filename"} onClose={handlePdfClose}/>
			</Portal>
			{products.map((product, i) =>
				<Grid key={product.id} item xs={6} md={4}>
					<Product product={product} setPdf={setFileState} openPdf={handlePdfOpen}/>
				</Grid>
			)}
		</Grid>
	);
};

export default Products;
