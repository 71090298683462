import React, { useEffect, useState } from "react";
import Layout from "../components/UserInfoLayout";
import OrderCard from "../components/OrderCard";
import { List, ListItem, makeStyles, Portal } from "@material-ui/core";
import MainLayout from "../components/MainLayout";
import { useStores } from "../models";
import InformingDialog from "../components/InformingDialog";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles({
	list: {
		gap: "50px"
	}
});

const Orders = () => {
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();
	const [orders, setOrders] = useState([]);
	const rootStore = useStores();
	const api = rootStore.environment.api;
	useEffect(async () => {
		setIsLoading(true);
		const response = await api.order.index();
		setOrders(response.data);
		setIsLoading(false);
	}, []);

	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Layout active="/orders">
				<List className={classes.list}>
					{orders.map((order, index) => <ListItem key={index}><OrderCard {...order}/></ListItem>)}
				</List>
			</Layout>
		</MainLayout>
	);
};

export default observer(Orders);
