import React, { forwardRef } from "react";
import TextField from "../components/CustomTextField";
import NumberFormat from "react-number-format";
import { Controller } from "react-hook-form";

const PhoneInput = forwardRef(({
	control,
	error,
	variant = "outlined",
	size = "medium",
	helperText,
	format = "+90 ### ### ## ##",
	placeholder = "+90 5__ ___ __ __",
	popoverError = false,
	...props
}, ref) => {
	const helperMessage = popoverError ? null : error?.message ?? helperText;


	return (
		<Controller
			control={control}
			render={
				({ field: { onChange, name, value } }) =>
					<NumberFormat
						placeholder={placeholder}
						value={value}
						ref={ref}
						name={name}
						onValueChange={(values) => onChange(values.formattedValue)}
						customInput={TextField}
						variant={variant}
						format={format}
						size={size}
						error={Boolean(error)}
						helperText={helperMessage}
						inputMode="tel"
						{...props}
					/>
			} name={"phoneNumber"}/>
	);
});

export default PhoneInput;
