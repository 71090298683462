import React from "react";
import { Card, Container, makeStyles, Typography } from "@material-ui/core";
import { asset } from "../config/initialize";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles(theme => ({
	root: {
		position: "relative"
	},
	card: {
		position: "absolute",
		right: 0,
		left: 0,
		top: -80,
		margin: "0 auto",
		maxWidth: "350px",
		padding: "40px",
		textAlign: "center",
		"& > :nth-child(1)": {
			font: "normal normal 800 36px/60px Gilroy"
		},
		"& > :nth-child(2)": {
			font: "normal normal bold 20px/60px Gilroy"
		},
		"& > :nth-child(3)": {
			font: "normal normal normal 12px/20px Gilroy",
			marginBottom: "20px"
		}
	},
	image: {
		width: "100%"
	}
}));


function MainPageVideoSection () {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	return (
		<Container className={classes.root} disableGutters maxWidth={false}>
			<Card className={classes.card}>
				<Typography>{contentStore.getContentValueLang("pages.mainPage.videoSection.title")}</Typography>
				<Typography>{contentStore.getContentValueLang("pages.mainPage.videoSection.header")}</Typography>
				<Typography>{contentStore.getContentValueLang("pages.mainPage.videoSection.description")}</Typography>
			</Card>
			<img className={classes.image} src={asset(contentStore.getContentValue("pages.mainPage.videoSection.image"))}/>
		</Container>
	);
}

export default observer(MainPageVideoSection)
