import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LibraryFilters from "../components/LibraryFilters";
import LibraryItem from "../components/LibraryItem";
import { Button, Drawer, List, ListItem, Portal, Typography } from "@material-ui/core";
import MainLayout from "../components/MainLayout";
import { getQuery, setQuery } from "../helpers/routing";
import { _ } from "../App";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";
import InformingDialog from "../components/InformingDialog";
import PageController from "../components/PageController";


const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: "5%"
	},
	rootHeader: {
		paddingTop: 20,
		font: "normal normal bold 32px/39px Calibri",
		"@media (max-width:599px)": {
			fontSize: 18,
			textAlign: "center"
		},
		"@media (max-width:959px)": {
			fontSize: 28
		}
	},
	filterDrawer: {
		padding: 20,
		width: "60%"
	},

	filterGrid: {
		"@media (max-width:959px)": {
			display: "none"
		}
	},
	openDrawer: {
		padding: "8px 0",
		display: "none",
		borderRadius: 8,
		textTransform: "none",
		color: "white",
		marginTop: "40px",
		font: "normal normal normal 17px/26px Gilroy",
		background: "linear-gradient(123deg, #1E2436 0%, #554686 100%)",
		"&:hover": {
			background: "linear-gradient(123deg, #1E2436 0%, #554686 100%)"
		},
		"@media (max-width:959px)": {
			display: "block"
		}
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column"
	},
	fixedHeight: {
		height: 700
	},
	basicItem: {
		paddingBottom: theme.spacing(2)
	},
	basicButton: {
		marginTop: theme.spacing(1)
	}


}));


function Library () {
	const classes = useStyles();

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;

	const [filterDrawer, setFilterDrawer] = useState(false);
	const [userProducts, setUserProducts] = useState([]);
	const [categories, setCategories] = useState([]);

	const [query, setquery] = useState(getQuery({ arrayFormat: "comma", comma: true }));
	const queryControl = getQuery({ arrayFormat: "comma", comma: true });
	if (!_.isEqual(query, queryControl)) setquery(queryControl);

	const queryMainCategories = Array.isArray(query?.filter?.mainCategory) ? query.filter.mainCategory : (query?.filter?.mainCategory ? [query.filter.mainCategory] : []);
	const [mainCategoryFilter, setMainCategoryFilter] = useState(queryMainCategories);
	const [meta, setMeta] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	function handleToggleCategoryFilter (categoryId) {
		const isFiltered = isCategoryFiltered(categoryId);
		if (isFiltered) {
			setMainCategoryFilter(mainCategoryFilter.filter(catId => catId !== categoryId));
		} else {
			setMainCategoryFilter([...mainCategoryFilter, categoryId]);
		}
	}

	function isCategoryFiltered (categoryId) {
		const isFiltered = mainCategoryFilter.includes(categoryId);
		return isFiltered;
	}

	function handleFilter () {
		const filterQuery = {
			mainCategory: mainCategoryFilter
		};
		query.filter = filterQuery;
		setQuery(query, location.pathname, { arrayFormat: "comma" });
		setquery(getQuery({ arrayFormat: "comma", comma: true }));
	}

	function handleFilterDrawerOpen () {
		setFilterDrawer(!filterDrawer);
	}

	async function getUserProducts () {
		const response = await api.userProducts.index(rootStore.auth.userId, query);
		const userProds = response.data.data;
		const responseMeta = response.data.meta;
		setUserProducts(userProds);
		setMeta(responseMeta);
	}

	async function getCategoryData () {
		const catResponse = await api.categories.index();
		setCategories(catResponse.data.filter(item => item.parent === null));
	}

	useEffect(async () => {
		setIsLoading(true);
		await Promise.all([getUserProducts(), getCategoryData()]);
		setIsLoading(false);
	}, [query]);


	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Container maxWidth="lg" className={classes.root}>
				<Grid container spacing={3}>
					<Grid item md={3}/>
					<Grid item>
						<Typography className={classes.rootHeader}>{contentStore.getContentValueLang("pages.library.title")}</Typography>

					</Grid>
				</Grid>
				<Button fullWidth className={classes.openDrawer} onClick={handleFilterDrawerOpen}>{contentStore.getContentValueLang("pages.searchPage.useFilter")}</Button>
				<Drawer
					anchor={"left"}
					open={filterDrawer}
					onClose={handleFilterDrawerOpen}
					PaperProps={{ className: classes.filterDrawer }}
				>
					<LibraryFilters categories={categories} toggleFilter={handleToggleCategoryFilter}
													applyFilter={handleFilter} isCategoryFiltered={isCategoryFiltered}/>
					<Button className={classes.openDrawer} onClick={handleFilterDrawerOpen}>İptal</Button>
				</Drawer>

				<Grid container spacing={3}>
					<Grid item xs={12} md={3} className={classes.filterGrid}>
						<LibraryFilters categories={categories} toggleFilter={handleToggleCategoryFilter}
														applyFilter={handleFilter} isCategoryFiltered={isCategoryFiltered}/>
					</Grid>
					<Grid item xs={12} md={9}>
						<List disablePadding>
							{userProducts.map((item) =>
								<ListItem disableGutters key={item.id}>
									<LibraryItem product={item}/>
								</ListItem>)}
						</List>
					</Grid>
				</Grid>
				<PageController meta={meta}/>
			</Container>
		</MainLayout>
	);
};

export default observer(Library);
