import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import Grid from "@material-ui/core/Grid";
import SearchFilters from "../components/SearchFilters";
import Button from "@material-ui/core/Button";
import { Menu, MenuItem, Portal } from "@material-ui/core";
import Products from "../components/Products";
import PageController from "../components/PageController";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getQuery, modifyQuery } from "../helpers/routing";
import { _ } from "../App";
import { useStores } from "../models";
import MenuIcon from "@material-ui/icons/Menu";
import InformingDialog from "../components/InformingDialog";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4)
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column"
	},
	fixedHeight: {
		height: 700
	},
	basicItem: {
		paddingBottom: theme.spacing(2)
	},
	basicButton: {
		marginTop: theme.spacing(1)
	},
	sortButton: {
		background: "white",
		borderRadius: "8px",
		boxShadow: "0px 2px 8px #00000022",
		textTransform: "none",
		color: "#1E2436",
		font: "normal normal normal 16px/19px Calibri"
	},
	sortMenu: {
		marginTop: 50
	},
	buttonGrid: {
		zIndex: "10",
		display: "flex",
		padding: 8
	},
	filterButton: {
		display: "none",
		"@media(max-width:959px)": {
			display: "flex"
		},
		background: "white",
		borderRadius: "8px",
		boxShadow: "0px 2px 8px #00000022",
		textTransform: "none",
		color: "#1E2436",
		font: "normal normal normal 16px/16px Calibri"
	},
	sortMenuButton: {
		marginLeft: "auto"
	},
	productsGrid: {
		display: "flex",
		flexDirection: "column"
	}

}));

function Search () {
	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;

	const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [products, setProducts] = useState([]);
	const sortMenuRef = useRef(null);

	const [query, setquery] = useState(getQuery({ arrayFormat: "comma", comma: true }));
	const queryControl = getQuery({ arrayFormat: "comma", comma: true });
	if (!_.isEqual(query, queryControl)) setquery(queryControl);

	const [meta, setMeta] = useState({});

	const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();

	function handleToggleSortMenu () {
		setIsSortMenuOpen(!isSortMenuOpen);
	}

	const sortTypes = [
		{ label: "İlk gelenler", sortText: "created_at" },
		{ label: "Son gelenler", sortText: "-created_at" },
		{ label: "Fiyat artan", sortText: "price" },
		{ label: "Fiyat azalan", sortText: "-price" },
		{ label: "En yüksek oyu alanlar", sortText: "rating_average" },
		{ label: "En düşük oyu alanlar", sortText: "-rating_average" }
	];
	const initialSort = query.sort !== undefined ? sortTypes.filter(sort => sort.sortText === query.sort)[0] : null;
	const [sortType, setSortType] = useState(initialSort);


	function handleToggleFilterDrawer () {
		setFilterDrawerOpen(!filterDrawerOpen);
	}

	function handleSort (sort) {
		setSortType(sort);
		const sortQuery = {
			sort: sort.sortText
		};
		modifyQuery(sortQuery);
		handleToggleSortMenu();
	}

	function isSortTypeSelected (sort) {
		return _.isEqual(sort, sortType);
	}


	useEffect(async () => {
		setIsLoading(true);
		const pageNumber = query.page;
		if (pageNumber === undefined) modifyQuery({ page: 1 });
		if (query.sort === undefined) setSortType(undefined);
		const response = await api.products.index(query);
		setProducts(response.data.data);
		setMeta(response.data.meta);
		setIsLoading(false);
	}, [query]);


	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Container disableGutters className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={3}>
						<SearchFilters open={filterDrawerOpen} onClose={handleToggleFilterDrawer}/>
					</Grid>
					<Grid item xs={12} md={9} className={classes.productsGrid}>
						<Grid container className={classes.buttonGrid} spacing={3}>
							<Grid item xs={12} sm={6} md={3}>

								<Button fullWidth variant="contained" className={classes.filterButton}
												onClick={handleToggleFilterDrawer}>{contentStore.getContentValueLang("pages.searchPage.useFilter")}<MenuIcon/></Button>
							</Grid>
							<Grid item xs={12} sm={6} md={3} className={classes.sortMenuButton}>
								<Button ref={sortMenuRef} variant="contained" className={classes.sortButton} fullWidth
												onClick={handleToggleSortMenu}>{sortType?.label || contentStore.getContentValueLang("pages.searchPage.sorting")}<KeyboardArrowDownIcon/></Button>
							</Grid>
							<Menu className={classes.sortMenu} open={isSortMenuOpen} onClose={handleToggleSortMenu} variant={"menu"}
										anchorEl={sortMenuRef.current}>
								{sortTypes.map((sort) => (
									<MenuItem key={sort.sortText} selected={isSortTypeSelected(sort)}
														onClick={() => handleSort(sort)}>{sort.label}</MenuItem>
								))}
							</Menu>
						</Grid>

						<Products products={products}/>
					</Grid>
				</Grid>
				<PageController meta={meta}/>
			</Container>

		</MainLayout>
	);
}

export default observer(Search);
