import { _ } from "../App";

export function preparePhoneNumber (phoneNumber) {
	if (_.isNil(phoneNumber)) return;
	let tempPhoneNumber = phoneNumber;
	if (tempPhoneNumber.includes("+90")) {
		tempPhoneNumber = tempPhoneNumber.trimLeftEnhanced("+90");
	}
	return tempPhoneNumber;
}
