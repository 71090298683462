import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardActionArea } from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";
import { asset } from "../config/initialize";

const useStyles = makeStyles({
	root: {
		height: "100%",
		padding: "0",
		display: "flex",
		flexDirection: "column"
	},
	imageBox: {
		width: "100%",
		display: "flex"
	},
	image: {
		width: 150,
		height: 200,
		margin: "30px auto"
	},
	price: {
		marginRight: "16px",
		font: "normal normal normal 19px/23px Calibri",
		color: "#2C2C2C"
	},
	infoAction: {
		flexGrow: "1"
	},
	productName: {
		font: "normal normal normal 22px/27px Calibri",
		color: "#2C2C2C",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	productDesc: {
		margin: "10px 0",
		font: "normal normal 300 14px/18px Montserrat",
		color: "#1E2436",
		textOverflow: "ellipsis",
		overflow: "hidden",
		display: "-webkit-box",
		lineClamp: 3,
		boxOrient: "vertical"
	},
	cardButton: {
		background: "linear-gradient(116deg, #1E2436 0%, #554686 100%)",
		color: "white",
		display: "flex",
		height: "56px",
		"&.Mui-disabled": {
			color: "white",
			opacity: .4
		}
	},
	cardButtonText: {
		font: "normal normal normal 17px/21px Calibri"
	}

});

const Product = observer((props) => {
	const { product, setPdf, openPdf } = props;
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const userCart = rootStore.cart;
	const ownedProducts = rootStore.auth.ownedProducts;
	const isInCart = userCart.hasItem(product.id);
	const isOwned = ownedProducts.includes(product.id);
	const cartLength = userCart.itemIds.length;

	async function handleCartActions () {
		if (isOwned) {
			const pdfState = {
				file: product.file_src,
				filename: product.name
			};
			setPdf(pdfState);
			openPdf();
		}
		if (isInCart) {
			await userCart.deleteItem(product.id);
		} else {
			await userCart.addItem(product.id);
		}
	}


	return (
		<Card className={classes.root}>
			<CardActionArea component="a" href={"/product-detail/" + product.id} className={classes.infoAction}>
				<div className={classes.imageBox}>
					<img
						className={classes.image}
						src={asset(product.image)}
					/>
				</div>
				<Typography className={classes.price} align="right" color="textSecondary">${product.price}</Typography>
				<CardContent>
					<Typography className={classes.productName}>{product.name}</Typography>
					<div dangerouslySetInnerHTML={{ __html: product.shortDescription }}/>

				</CardContent>
			</CardActionArea>
			<CardActionArea className={classes.cardButton} onClick={handleCartActions}>
				{isOwned ?
					<Typography className={classes.cardButtonText}>{contentStore.getContentValueLang("pages.searchPage.read")}</Typography>
					:
					(isInCart ?
							<>
								<Typography className={classes.cardButtonText}>{contentStore.getContentValueLang("pages.searchPage.inYourCart")}</Typography>
							</>
							:
							<>
								<ShoppingBasketIcon htmlColor="#FFFFFF" fontSize="small"/>
								<Typography className={classes.cardButtonText}>{contentStore.getContentValueLang("pages.searchPage.addToCart")}</Typography>
							</>
					)
				}
			</CardActionArea>
		</Card>
	);
});

export default Product;
