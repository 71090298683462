import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from "../components/MainLayout"
import {Box, Button, Container, Typography} from "@material-ui/core";
import checkMark from '../resources/check-mark.png'
import SecretLink from "../components/SecretLink";

const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex"
    },
    middleBox:{
        width:"80%",
        margin:"120px 10%",
        background:"rgba(66, 113, 119, .4)",
        borderRadius:6,
        padding:10
    },
    contentBox:{
        padding:36,
        background:"white",
        borderRadius:6,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        textAlign:"center"
    },
    success:{
        color:"rgba(7, 126, 99, 1)",
        font:"normal normal bold 36px/40px Calibri",
        margin:"30px 0"
    },
    desc:{
        font:"normal normal normal 20px/24px Calibri",
        marginBottom:"40px"
    },
    mainPageButton:{
        background:"#1E2436",
        color:"white",
        padding:"12px 40px",
        textTransform: "none",
        margin:"0 4px",
        borderRadius:6,
        font:"normal normal normal 20px/26px Calibri",
        "&:hover":{
            background:"#1E2436",
        }
    },
    check:{
        width:100,
        height:100
    }
}));

const PaymentSuccess = () => {
    const classes = useStyles();
    return (
        <MainLayout>
           <Container disableGutters maxWidth="xl" className={classes.root}>
                <Box className={classes.middleBox}>
                    <Box className={classes.contentBox}>
                        <img className={classes.check} src={checkMark}/>
                        <Typography className={classes.success}>Siparişiniz Onaylandı.</Typography>
                        <Typography className={classes.desc}>Lorem ıpsum dolor sit amet to vectum haktus penisulun peri sattis auctor. olor Sit Ametm Dolum Dolor Sit Ameeger eleifend odio vitae ligula mattis auyu maktiko</Typography>
                        <SecretLink to={"/home"}>
                            <Button className={classes.mainPageButton}>Anasayfa</Button>
                        </SecretLink>
                    </Box>
                </Box>
           </Container>
        </MainLayout>
    );
};

export default PaymentSuccess;
