import {withStyles,TextField} from "@material-ui/core";

const textField = withStyles({
    root: {
        "&:hover":{
            "& label.MuiFormLabel-root.Mui-error":{
                color:"#1E2436"
            },
            "& .MuiOutlinedInput-root fieldset":{
                borderColor: '#1f1e1e',
            }

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#707070',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#707070',
            },

        },
    },
})(TextField)

export default textField
