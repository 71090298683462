import React from "react";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import SecretLink from "./SecretLink";
import { useStores } from "../models";


const useStyles = makeStyles(theme => ({
	root: {
		background: "linear-gradient(109deg, #554686 0%, #9AD4D6 100%)",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "60px",
		gap: "30px"
	},
	body: {
		font: "normal normal 600 40px/60px Gilroy",
		color: "white",
		maxWidth: "500px",
		textAlign: "center"
	},
	button: {
		font: "normal normal 600 20px/26px Gilroy",
		background: "white",
		textTransform: "none",
		color: "#1E2436",
		padding: "20px 80px",
		borderRadius: "10px",
		"&:hover": {
			background: "#ececec"
		}
	},
	link: {
		textDecoration: "none"
	}

}));

export default function MainPageSubscribe () {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	return (
		<Container className={classes.root} maxWidth={false}>
			<Typography className={classes.body}>{contentStore.getContentValueLang("pages.mainPage.signupNow.title")}</Typography>
			<SecretLink to="/signup" className={classes.link}><Button
				className={classes.button}>{contentStore.getContentValueLang("pages.mainPage.signupNowButton")}</Button></SecretLink>
		</Container>
	);
}
