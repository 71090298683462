import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container, Typography } from "@material-ui/core";
import SecretLink from "./SecretLink";
import image from "../resources/home1/image13.jpg";

const useStyles = makeStyles({
	root: {
		position: "relative",
		height: 500
	},
	blurredBack: {
		background: `linear-gradient(180deg, transparent 50%, #fff 100%), url("${image}")`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		filter: "blur(5px)",
		height: "100%",
		width: "100%"
	},
	blurredContent: {
		position: "absolute",
		bottom: 0,
		left: 0,
		top: 0,
		right: 0,
		color: "white",
		padding: "5% 15%",
		display: "flex",
		flexDirection: "column"
	},
	blurredHeading: {
		font: "normal normal 400 60px/64px Gilroy",
		"@media (max-width:959px)": {
			fontSize: 40,
			lineHeight: "50px"
		},
		"@media (max-width:599px)": {
			textAlign: "center"
		}
	},
	blurredBody: {
		font: "normal normal 600 24px/32px Gilroy",
		margin: "16px 0",
		"@media (max-width:959px)": {
			fontSize: 20
		},
		"@media (max-width:599px)": {
			textAlign: "center"
		}
	},
	blurredButtonLink: {
		"@media (max-width:599px)": {
			margin: "0 auto"
		}
	},
	blurredButton: {
		background: "#1E2436",
		color: "white",
		textTransform: "none",
		font: "normal normal 600 17px/26px Gilroy",
		padding: "10px 28px",
		borderRadius: 10,
		"&:hover": {
			background: "#1E2436"

		}

	}
});

export default function FranchiseBlurredSection () {
	const classes = useStyles();
	return (
		<Container disableGutters maxWidth="xl" className={classes.root}>
			<Box className={classes.blurredBack}/>
			<Box className={classes.blurredContent}>
				<Typography className={classes.blurredHeading}>Dijitıp ile yazar olmak <br/> çok kolay!</Typography>
				<Typography className={classes.blurredBody}>Yazılarınızı alanında uzman kişilere sunmak <br/> için yazarlık
					başvurusu yapma seçeneği.</Typography>
				<SecretLink to={"/author-register"} className={classes.blurredButtonLink}>
					<Button className={classes.blurredButton}>Hemen Başvur</Button>
				</SecretLink>
			</Box>
		</Container>
	);
}
