import {flow, getParent, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";

/**
 * User model
 */


export const UserModel = types.model({
    isLoggedIn: types.optional(types.boolean, false),
    phoneNumber: types.maybe(types.string),
    password: types.maybe(types.string),
    userId: types.maybe(types.string),
    savePassword: types.maybe(types.boolean),
    firstName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    branchId: types.maybeNull(types.string),
    organizationId: types.maybeNull(types.string),
    ownedProducts: types.optional(types.array(types.string), []),
}).extend(withEnvironment)
    .actions(self => ({
        getOwnedProducts: flow(function* () {
            const response = yield self.environment.api.userProducts.index(self.userId as string, {})
            const userProducts = response?.data?.data ?? []
            self.ownedProducts = userProducts.map((product: any) => product.id)
        }),
    }))
    .actions(self => ({
        setUser(data: any, getProducts: boolean = false) {
            self.isLoggedIn = true;
            self.phoneNumber = data.phoneNumber;
            self.userId = data.id;
            self.branchId = data.branch?.id ?? null
            self.organizationId = data.organization?.id ?? null
            self.type = data.type
            self.email = data.email
            self.firstName = data.firstName
            self.lastName = data.lastName
            if (getProducts) self.getOwnedProducts()
        }
    }))
    .actions(self => ({
        login: flow(function* (phoneNumber, password, savePassword) {
            try {
                self.savePassword = savePassword
                const apiResponse = yield self.environment.api.auth.login(phoneNumber, password);
                if (apiResponse.status === 201) {
                    const user = apiResponse.data.user
                    self.setUser(user, true)
                    if (self.savePassword) {
                        self.password = password;
                    } else {
                        self.password = ""
                    }
                }
                return apiResponse
            } catch (error) {
                console.error(error)
                return false;
            }
        }),

        logout: flow(function* () {
            const apiResponse = yield self.environment.api.auth.logout()
            if (apiResponse.status === 200) {
                self.isLoggedIn = false;
                self.userId = ""


                const rootStore = getParent(self)
                rootStore.logout()

                return true;
            }
            return false
        }),
        updatePassword: flow(function* (newPassword) {
            const apiResponse = yield self.environment.api.users.update(
                self.userId as string,
                {"password": newPassword}
            )
            if ([200, 201].includes(apiResponse.status)) {
                self.password = newPassword
            }
            return apiResponse

        }),
        update: flow(function* (data: any) {
            const response = yield self.environment.api.users.update(self.userId as string, data)
            const userData = response.data
            self.setUser(userData)
        }),
        afterCreate: flow(function* () {
            if (self.isLoggedIn) {
                yield self.getOwnedProducts()
            }

        })
    }))
    .actions(self => ({
        register: flow(function* (phoneNumber, password) {
            try {
                const apiResponse = yield self.environment.api.auth.register(phoneNumber, password);
                if (apiResponse.status === 201) {
                    return yield self.login(phoneNumber, password, false)
                } else {
                    return apiResponse
                }
            } catch (error) {
                console.error(error)
                return false;
            }
        }),
    }))
    .views(self => ({
        get isOnboarded(): Boolean {
            let isOnboarded = false
            if (!!self.firstName &&
                !!self.lastName &&
                !!self.type &&
                !!self.branchId &&
                !!self.organizationId) {
                isOnboarded = true
            }
            return isOnboarded
        }
    }));

type UserType = Instance<typeof UserModel>

export interface User extends UserType {
}

type UserSnapshotType = SnapshotOut<typeof UserModel>

export interface UserSnapshot extends UserSnapshotType {
}

export const createUserDefaultModel = () => types.optional(UserModel, {})
