import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SecretLink from "./SecretLink";
import { makeQueryRoute } from "../helpers/routing";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";
import ResponsiveCarousel from "./ResponsiveCarousel";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};


const useStyles = makeStyles((theme) => ({
	toolbar: {
		background: "linear-gradient(96deg, #1E2436 0%, #554686 100%)"
	},
	root: {},
	sectionTitles: {
		font: "normal normal normal 14px/20px Gilroy",
		color: "#FFFFFF",
		textAlign: "center",
		borderLeft: "1px #fff solid",
		borderRight: "1px #fff solid"

	}

}));

const Filters = observer((props) => {
	const rootStore = useStores();
	const categories = rootStore.categoryStore.categories ?? [];
	const sections = categories.filter(cat => cat.parent === null);
	const classes = useStyles();

	return (
		<Toolbar className={classes.toolbar}>
			<Container className={classes.root}>
				<ResponsiveCarousel responsivePattern={responsive} hideArrows
				>
					{sections.map((section) => (

						<SecretLink
							showPointer
							to={makeQueryRoute("/search", { filter: { mainCategory: section.id } })}
							key={section.id}

						>
							<Typography className={classes.sectionTitles}>{section.name}</Typography>

						</SecretLink>


					))}
				</ResponsiveCarousel>
			</Container>
		</Toolbar>
	);
});

export default Filters;

