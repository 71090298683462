import {ApiBase} from "../api"

export class CouponsApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async userCoupons(userId: string) {
        const response = await this.api.apisauce.get(
            "/users/" + userId + "/coupons",
            {},
            {
                headers: {
                    "withProducts": true
                }
            }
        )

        return response
    }

    async consumeCoupon(userId: string, couponId: string) {
        const response = await this.api.apisauce.post(
            `/users/${userId}/${couponId}`,
        )
        return response
    }

    async show(userId: string, couponId: string) {
        const response = await this.api.apisauce.get(
            "/users/" + userId + "/coupons/" + couponId,
            {},
            {
                headers:{
                    withProducts:true
                }
            }
        )
        return response
    }
}
