import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BasketItem from "../components/BasketItem";
import Button from "@material-ui/core/Button";
import OrderSummary from "../components/OrderSummary";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import SecretLink from "../components/SecretLink";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";
import InformingDialog from "../components/InformingDialog";
import { Portal } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "100px 30px"
	},
	pageHeading: {
		font: "normal normal bold 32px/20px Calibri",
		margin: "50px 0"
	},
	itemList: {
		display: "flex",
		gap: "40px",
		flexDirection: "column"
	},
	continueShoppingButton: {
		background: "#EEEEEE",
		border: "2px #1E243673 solid",
		borderRadius: "8px",
		color: "#1E243673",
		textTransform: "none",
		font: "normal normal bold 18px/20px Calibri",
		padding: "8px 10px"
	},
	acceptBasketLink: {
		display: "flex",
		margin: "40px"
	},
	acceptBasketButton: {
		background: "linear-gradient(114deg, #1E2436 0%, #554686 100%)",
		color: "white",
		textTransform: "none",
		font: "normal normal bold 24px/48px Calibri",
		padding: "0 20px",
		borderRadius: 8,
		"&.Mui-disabled": {
			color: "white",
			opacity: .4
		}
	}

}));

const Basket = observer(() => {
	const classes = useStyles();


	const [basket, setBasket] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;
	const userCart = rootStore.cart;
	const itemCountInCart = userCart.itemIds.length;

	function calculateBasketCost () {
		if (basket.length === 0) return 0;

		function priceReducer (prevValue, currentItem) {
			return prevValue + parseFloat(currentItem.price);
		}

		return basket.reduce(priceReducer, 0);
	}


	useEffect(async () => {
		setIsLoading(true);
		const response = await api.cart.getCart();
		const itemsInBasket = response.data?.items ?? [];
		setBasket(itemsInBasket);
		setIsLoading(false);
	}, [itemCountInCart]);

	return (
		<MainLayout>
			<Portal>
				<InformingDialog open={isLoading}/>
			</Portal>
			<Container className={classes.root}>
				<Typography className={classes.pageHeading}>{contentStore.getContentValueLang("pages.cart.myCart")} ({basket.length} {contentStore.getContentValueLang("pages.cart.myCart-product")})</Typography>
				<Grid container spacing={3}>
					{/* Chart */}
					<Grid item xs={12} md={8} className={classes.itemList}>
						{basket.map((item) => <BasketItem key={item.id} {...item}/>)}

					</Grid>
					{/* sipariş toplamı */}
					<Grid item xs={12} md={4}>
						<OrderSummary basketCost={calculateBasketCost()}/>
						<SecretLink to={basket.length === 0 ? "#" : "/payment"} className={classes.acceptBasketLink}>
							<Button className={classes.acceptBasketButton} disabled={basket.length === 0} fullWidth>{contentStore.getContentValueLang("pages.cart.confirmCart")}</Button>
						</SecretLink>
					</Grid>
					<Grid item xs={12} md={3}>
						<SecretLink to="/search" style={{ display: "flex" }}>
							<Button variant="outlined" fullWidth className={classes.continueShoppingButton}>{contentStore.getContentValueLang("pages.cart.continue")}</Button>
						</SecretLink>
					</Grid>

				</Grid>
			</Container>
		</MainLayout>
	);
});

export default Basket;
