import React from "react";
import { Box, Button, Card, Container, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SecretLink from "./SecretLink";
import { useStores } from "../models";
import { asset } from "../config/initialize";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
	root: {
		background: "linear-gradient(191deg, #FFFFFF 50%, #9A7F9599 100%)",
		paddingBottom: 200
	},
	rootTop: {
		background: "linear-gradient(180deg, #9AD4D699 0%, #F2FDFF33 100%)",
		padding: "100px 80px"
	},
	rootTopHeading: {
		font: "normal normal bold 60px/70px Gilroy",
		"@media (max-width:959px)": {
			fontSize: 45,
			lineHeight: "50px"
		},
		"@media (max-width:599px)": {
			fontSize: 30,
			lineHeight: "40px"
		}
	},
	rootTopBody: {
		font: "normal normal normal 24px/36px Gilroy",
		marginBottom: "40px",
		"@media (max-width:959px)": {
			fontSize: 20,
			lineHeight: "26px"
		}
	},
	rootTopLink: {
		background: "#554686",
		color: "white",
		textTransform: "none",
		font: "normal normal medium 17px/26px Gilroy",
		padding: "15px 30px",
		boxShadow: "1px 1px 10px #00000033",
		borderRadius: "10px",
		"&:hover": {
			background: "#1E2436"
		}
	},
	rootTopImageGrid: {
		"@media (max-width:959px)": {
			display: "none"
		},
		"& > div": {
			display: "flex",
			flexWrap: "wrap",
			width: 400,
			"& > :nth-child(1)": {
				transform: "translateX(-20px) translateY(20px)"
			},
			"& > :nth-child(2)": {
				transform: "translateX(-20px) translateY(-20px)"
			},
			"& > :nth-child(3)": {
				transform: "translateX(20px) translateY(20px)"
			},
			"& > :nth-child(4)": {
				transform: "translateX(20px) translateY(-20px)"
			}
		},
		"& img": {
			borderRadius: "8px",
			margin: 10,
			transform: "translateX(70px)"
		}

	},
	rootImg: {
		width: 180,
		height: 210
	},

	bottomCard: {
		padding: "60px",
		width: "70%",
		margin: "100px auto",
		background: "#1E2436",
		color: "white",
		overflow: "visible"
	},
	bottomCardImageGrid: {
		transform: "translateX(-100px) matrix(0.26, 0.97, -0.97, 0.26, 0, 0)",
		"& > div": {
			display: "flex",
			flexWrap: "wrap",
			gap: "30px",
			width: 400,
			"& > img": {
				borderRadius: "8px"
			},
			transform: "scale(1.2)",
			"& > :nth-child(even)": {
				transform: "translateY(-30px)"
			},
			"& > :nth-child(odd)": {
				transform: "translateY(30px)"
			}
		},
		"@media (max-width:959px)": {
			display: "none"
		},
		zIndex: 0
	},
	bottomCardText: {
		zIndex: 1
	},
	bottomCardImg: {
		width: 180,
		height: 180
	},
	cardHeading: {
		font: "normal normal bold 38px/50px Gilroy",
		"@media (max-width:959px)": {
			fontSize: 32,
			lineHeight: "36px"
		},
		"@media (max-width:599px)": {
			fontSize: 20,
			lineHeight: "24px"
		}
	},
	cardBody: {
		font: "normal normal normal 16px/22px Gilroy",
		"@media (max-width:959px)": {
			fontSize: 14,
			lineHeight: "20px"
		}
	}
}));

function MainPage () {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;

	return (
		<Container className={classes.root} disableGutters maxWidth={false}>
			<Container className={classes.rootTop}>
				<Grid container>
					<Grid item md={8}>
						<Typography
							className={classes.rootTopHeading}>{contentStore.getContentValueLang("pages.mainPage.section1.title")}</Typography>
						<Typography
							className={classes.rootTopBody}>{contentStore.getContentValueLang("pages.mainPage.section1.description")}</Typography>
						<SecretLink to="/signup"><Button
							className={classes.rootTopLink}>{contentStore.getContentValueLang("pages.mainPage.tryNowButton")}</Button></SecretLink>
					</Grid>
					<Grid item md={4} className={classes.rootTopImageGrid}>
						<Box>
							<img loading="lazy" className={classes.rootImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section1.image1"))}/>
							<img loading="lazy" className={classes.rootImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section1.image2"))}/>
							<img loading="lazy" className={classes.rootImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section1.image3"))}/>
							<img loading="lazy" className={classes.rootImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section1.image4"))}/>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Card className={classes.bottomCard}>
				<Grid container>
					<Grid item md={5} className={classes.bottomCardImageGrid}>
						<Box>
							<img loading="lazy" className={classes.bottomCardImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section2.image1"))}/>
							<img loading="lazy" className={classes.bottomCardImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section2.image2"))}/>
							<img loading="lazy" className={classes.bottomCardImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section2.image3"))}/>
							<img loading="lazy" className={classes.bottomCardImg}
									 src={asset(contentStore.getContentValue("pages.mainPage.section2.image4"))}/>
						</Box>
					</Grid>
					<Grid item md={7} className={classes.bottomCardText}>
						<Typography className={classes.cardHeading}>
							{contentStore.getContentValueLang("pages.mainPage.section2.title")}
						</Typography>
						<Typography
							className={classes.cardBody}>{contentStore.getContentValueLang("pages.mainPage.section2.description")}</Typography>
					</Grid>
				</Grid>
			</Card>
		</Container>
	);
}

export default observer(MainPage);
