import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    verticalMargin: {
        paddingTop: "100px",
        paddingBottom: "100px",
        marginBottom: "100px"
    },
    vh100: {
        height: "100vh"
    }
}));


function MainLayout({className, dynamicHeight = true, fixed = true, maxWidth, children, setZIndex = true, ...props}) {
    const classes = useStyles();

    return (
        <Container className={
            clsx(
                dynamicHeight && classes.verticalMargin,
                className
            )} fixed={fixed} maxWidth={maxWidth} style={setZIndex ? {zIndex: -10} : null} {...props}>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                // className={dynamicHeight ? null : classes.vh100}
            >
                {children}
            </Grid>
        </Container>
    );
}

export default MainLayout;
