import { AppBar, Box, Button, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import SecretLink from "./SecretLink";
import { useStores } from "../models";
import logo from "../resources/logo.png"
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
	appBar: {
		background: "transparent",
		padding: "40px"
	},
	toolBar: {
		height: "100%",
		padding: "0 5%",
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap"
	},
	logo: {
		maxHeight:100,

	},
	buttonBox: {
		display: "flex",
		flexGrow: 1,
		justifyContent: "flex-end",
		margin: "10px 0",
		"@media (max-width:600px)": {
			display: "none"
		}
	},
	toolbarLogo: {
		font: "normal normal normal 43px/52px Verdana",
		cursor: "pointer",
		color: "#1E2436",
		textAlign: "center"
	},
	closeButton: {
		display: "none",
		"@media (max-width:600px)": {
			display: "block"
		}
	},
	moreInfoButton: {
		padding: "14px 40px",
		color: "#313131",
		textTransform: "none",
		margin: "0 4px",
		width: "fit-content"
	},
	registerButton: {
		background: "#1E2436",
		color: "white",
		padding: "12px 40px",
		textTransform: "none",
		margin: "0 4px",
		borderRadius: 6,
		font: "normal normal normal 20px/26px Calibri",
		"&:hover": {
			background: "#1E2436"
		}
	}

}));

const RegisterHeader = () => {
	const classes = useStyles();
	const rootStore = useStores();
	const contentStore = rootStore.contentStore;
	return (
		<AppBar elevation={0} position="relative" className={classes.appBar}>
			<Toolbar className={classes.toolBar}>
					<SecretLink showPointer to="/">
						<img className={classes.logo} src={logo} alt=""/>
					</SecretLink>
				<Box className={classes.buttonBox}>
					<SecretLink to="/">
						<Button fullWidth className={classes.registerButton}>{contentStore.getContentValueLang("pages.signIn.homePageText")}</Button>
					</SecretLink>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default observer(RegisterHeader);
