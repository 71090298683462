import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CardContent, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import trashIcon from "../resources/temp/trash.png";
import { useStores } from "../models";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
	root: {
		width: "100%",
		boxShadow: "3px 3px 6px #00000029",
		borderRadius: "7px",
		border: "#B8B8B8 1px solid"
	},
	cardHeader: {
		display: "flex",
		alignItems: "baseline",
		justifyContent: "space-between",
		background: "#E3E3E3",
		borderBottom: "#B8B8B8 1px solid",
		padding: "10px 40px"

	},
	cardBody: {
		color: "#1E2436",
		padding: "20px 40px"
	},
	gridCardContent: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		padding: "20px 30px 20px 0"
	},
	gridSec: {
		borderLeft: "#afafaf solid 1px",
		alignItems: "flex-end",
		display: "flex",
		paddingLeft: "40px",
		"@media (max-width:959px)": {
			borderLeft: "none",
			padding: "20px 0",
			borderTop: "#afafaf solid 1px"

		}
	},
	gridButton: {
		background: "linear-gradient(112deg, #1E2436 0%, #554686 100%)",
		color: "white",
		margin: "0 auto",
		textTransform: "none",
		marginBottom: "20px",
		borderRadius: "7px",
		font: "normal normal 300 20px/40px Calibri"
	},
	addressName: {
		font: "normal normal bold 24px/24px Calibri,Roboto"
	},
	reciever: {
		font: "normal normal normal 20px/24px Calibri"
	},
	address: {
		font: "normal normal 300 20px/24px Calibri",
		textOverflow: "ellipsis",
		overflow: "hidden",
		display: "-webkit-box",
		lineClamp: 4,
		boxOrient: "vertical"
	},
	phone: {
		font: "normal normal 300 20px/24px Calibri"
	}
});

const AddressCard = (props) => {

	const rootStore = useStores();
	const contentStore = rootStore.contentStore
	const api = rootStore.environment.api;


	const classes = useStyles();
	const { address, refreshAddresses, editAddress } = props;

	async function handleDeleteAddress () {
		await api.userAddress.destroy(address.user_id, address.id);
		refreshAddresses();
	}

	function handleEditAddress () {
		editAddress(address);
	}

	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardHeader}>
				<Typography className={classes.addressName}>{address.title}</Typography>
				<IconButton onClick={handleDeleteAddress}><img src={trashIcon}/></IconButton>
			</CardContent>
			<CardContent className={classes.cardBody}>
				<Grid container>
					<Grid item xs={12} md={8} className={classes.gridCardContent}>
						<Typography className={classes.reciever}>{address.fullName}</Typography>
						<Typography className={classes.address}>{address.address}</Typography>
						<Typography className={classes.phone}>{address.phoneNumber}</Typography>
					</Grid>
					<Grid item xs={12} md={4} className={classes.gridSec}>
						<Button className={classes.gridButton} onClick={handleEditAddress} fullWidth>{contentStore.getContentValueLang("pages.addresses.editAddress")}</Button>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default observer(AddressCard);
