import React from "react";
import { Box, CircularProgress, Dialog, Typography } from "@material-ui/core";


export default function InformingDialog (props) {
	const { open = false } = props;
	return (
		<Dialog open={open} maxWidth="xs" scroll="paper" PaperProps={{ style: { padding: 10 } }}>
			<CircularProgress/>
		</Dialog>
	);
}

export function AlertDialog (props) {
	const { open = false, text, afterTimeout = () => {}, timeout = 0 } = props;
	const textStyle = {};
	if (open) {
		setTimeout(afterTimeout, timeout);
	}
	return (
		<Dialog open={open} maxWidth="sm" fullWidth>
			<Box p={5}>
				<Typography style={textStyle}>{text}</Typography>
			</Box>
		</Dialog>
	);
}
