import React from "react";
import { matchPath, Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import ProtectedRoute from "./components/ProtectedRoute";
import { getPaths } from "./helpers/routing";
import routes, { redirects } from "./config/routes";
import Footer from "./components/layout/Footer";
import history from "./helpers/history";


export const paths = getPaths();

const headerDisabledPaths = [paths.login];
const sidebarDisabledPaths = [paths.login];
const footerDisabledPaths = [paths.login];

function Main (props) {
	const location = history.location;
	const currentLocation = location.pathname;
	const currentRoute = routes.find(
		route => matchPath(currentLocation, route)
	);


	window.scrollTo({ top: 0, behavior: "smooth" });
	const ContentComponent = (
		<Wrapper>
			<TransitionGroup>
				<CSSTransition
					key={currentRoute?.component?.name ?? Math.random()}
					classNames="fade"
					timeout={450}
				>
					<section className="route-section">
						<Switch location={location}>
							{
								routes.map((route, key) => (
									route.protected ?
										<ProtectedRoute
											key={key}
											exact={route.exact}
											path={route.path}
											component={route.component}
											{...route.routeProps}
										/> :
										<Route
											key={key}
											exact={route.exact}
											path={route.path}
											component={route.component}
											{...route.routeProps}
										/>
								))
							}
							{
								redirects.map((redirect, key) => (
									<Redirect key={key} from={redirect.from} to={redirect.to} {...redirect.advanced}/>
								))
							}
						</Switch>
					</section>
				</CSSTransition>
			</TransitionGroup>
		</Wrapper>
	);

	const FooterComponent = !footerDisabledPaths.includes(currentLocation) && (
		<Footer/>
	);

	return (
		<>
			{ContentComponent}
		</>
	);
}

const Wrapper = styled.div`
  div.transition-group {
    position: relative;
  }

  section.route-section {
    //position: absolute;
    width: 100%;
    height: 100%;
    //top: 0;
    //left: 0;
  }
`;

export default Main;
