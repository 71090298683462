import { AppBar, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from "@material-ui/core";
import React, { forwardRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import MainLayout from "./MainLayout";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import InformingDialog from "../../InformingDialog";

export const FullscreenModalStyles = makeStyles(theme => ({
	fullSize: {
		width: "100%",
		height: "100%"
	},
	appBar: {
		position: "fixed"
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	content: {
		marginTop: "50px",
		marginBottom: "50px"
	},
	lastContent: {
		marginTop: "50px"
	}
}));

const Transition = forwardRef(function Transition (props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


function FullscreenModalLayout (
	{
		open,
		onClose,
		onEnter,
		children,
		loading,
		title,
		paperWrap = false,
		color = "primary",
		MiddleComponent,
		appBarProps,
		CustomAppBar = null,
		...props
	}) {

	const classes = FullscreenModalStyles();

	if (!_.isEmpty(appBarProps)) {
		appBarProps.className = clsx(classes.appBar, appBarProps?.className);
	}

	function closeHandler () {
		if (!loading) {
			onClose();
		}
	}

	const Content = paperWrap ? (
		<MainLayout>
			<Paper className={classes.fullSize}>
				{children}
			</Paper>
		</MainLayout>
	) : children;

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={closeHandler}
			onEnter={onEnter}
			scroll="body"
			TransitionComponent={Transition}
			{...props}
		>
			{
				CustomAppBar ??
				<AppBar className={classes.appBar} color={color} {...appBarProps}>
					<Toolbar>
						<Grid container alignItems="center">
							<Grid container justifyContent="flex-start" item md={4} xs={12}>
								<Typography variant="h6" className={classes.title}>
									{loading ? "Yükleniyor" : title}
								</Typography>
							</Grid>
							<Grid container justifyContent="center" item md={4} xs={10}>
								{MiddleComponent}
							</Grid>
							<Grid container justifyContent="flex-end" item md={4} xs={2}>
								<IconButton
									edge="start"
									color="inherit"
									onClick={closeHandler}
									aria-label="close"
								>
									<CloseIcon/>
								</IconButton>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
			}
			{loading ? <InformingDialog open/> : Content}
		</Dialog>
	);
}

export default FullscreenModalLayout;
