import {ApiBase} from "../api"

export class UserAddressApi {
    private api: ApiBase

    constructor(api: ApiBase) {
        this.api = api
    }

    async index(userId: string) {
        let response = await this.api.apisauce.get(
            "/users/" + userId + "/addresses"
        )
        return response
    }

    async show(userId:string, addressId:string) {
        let response = await this.api.apisauce.get(
            "/users/" + userId + "/addresses/" + addressId
        )
        return response;
    }
    async store(userId:string, address:any) {
        const response = await this.api.apisauce.post(
            "/users/" + userId + "/addresses",
            address,
        )
        return response
    }
    async update(userId:string, addressId:string, address:any) {
        const response = await this.api.apisauce.put(
            "/users/" + userId + "/addresses/" + addressId,
            {...address},
        )
        return response
    }
    async destroy(userId:string, addressId:string) {
        const response = await this.api.apisauce.delete(
            "/users/" + userId + "/addresses/" + addressId,
        )
        return response
    }
}
