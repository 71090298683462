import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { modifyQuery } from "../helpers/routing";

const useStyles = makeStyles(theme => ({
	pagesSection: {
		display: "flex",
		gap: "20px",
		justifyContent: "center",
		alignItems: "center"
	},
	pages: {
		boxShadow: "1px 1px 6px #00000029",
		background: "white",
		borderRadius: "6px",
		padding: "8px",
		margin: "8px 10px",
		display: "flex"

	},
	pagesButtons: {

		padding: "8px",
		cursor: "pointer"
	},
	page: {
		textDecoration: "none",
		padding: "10px",
		font: "normal normal normal 18px/22px Montserrat",
		color: "#2C2C2C",
		cursor: "pointer"
	},
	activePage: {
		background: "#1E2436",
		color: "white",
		borderRadius: 3
	}
}));

function PageController (props) {
	const { meta } = props;
	const activePage = meta?.current_page ?? 1;
	const { last_page } = meta;
	const classes = useStyles();
	const [pages, setPages] = useState([]);
	useEffect(() => {

		const calculatedPages = calculatePages(meta);

		setPages(calculatedPages);
	}, [meta]);

	function calculatePages (meta) {
		if (!meta) return;
		if (activePage > last_page) modifyQuery({ page: last_page });
		if (last_page === null) return [0];

		let prevPages = [];
		let nextPages = [];

		for (let i = 1; i <= 3; i++) {

			if (activePage - i >= 1) prevPages.push(activePage - i);
			if (activePage + i <= last_page) nextPages.push(activePage + i);
		}


		prevPages.reverse();
		return [...prevPages, activePage, ...nextPages];
	}

	function handleGetPage (pageNumber) {
		modifyQuery({ page: pageNumber });
	}

	function handlePrevPage () {
		if (activePage > 1) handleGetPage(activePage - 1);
	}

	function handleNextPage () {
		if (activePage < last_page) handleGetPage(activePage + 1);
	}


	return (
		<Box className={classes.pagesSection}>
			<IconButton onClick={handlePrevPage} disabled={activePage < 2}>
				<ArrowBackIosIcon className={classes.pagesButtons} fontSize="small"/>
			</IconButton>
			<Box className={classes.pages}>
				{
					pages.map((page, index) =>
						<Typography key={index}
												className={clsx(classes.page, index + 1 === activePage && classes.activePage)}
												onClick={() => handleGetPage(page)}>{page}</Typography>
					)
				}
			</Box>
			<IconButton onClick={handleNextPage} disabled={activePage >= last_page}>
				<ArrowForwardIosIcon className={classes.pagesButtons} fontSize="small"/>
			</IconButton>

		</Box>
	);
}

export default PageController;
